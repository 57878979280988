import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from 'react-intl';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { useAuth } from '../hooks/useAuth';

import ManagerAppBar from '../components/manager/ManagerAppBar';
import ManagerDrawer from '../components/manager/ManagerDrawer';
import UserManager from '../components/manager/UserManager';
import CrowdPoiManager from '../components/manager/CrowdPoiManager';
import HarborManager from '../components/manager/HarborManager';
import EditHarbor from '../components/manager/EditHarbor';
import Dashboard from '../components/manager/Dashboard';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://vertti.io">
        Vertti.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ManagerPage = (props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { section, id } = useParams();

  const [selectedSection, setSelectedSection] = useState(section);
  const [selectedHarborId, setSelectedHarborId] = useState(id || '');

  useEffect(() => {
    setSelectedHarborId(id);
    setSelectedSection(section ? section : 'dashboard');
  }, [section, id])

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const selectSection = (section) => {
    setSelectedSection(section);
  }

  const editHarbor = (publicId) => {
    setSelectedHarborId(publicId);
    setSelectedSection('editHarbor');
  }

  if (!auth.user) {
    return <></>
  }

  if (!(auth.user?.role === 'admin' || auth.user?.entitlements?.harbors.length > 0)) {
    navigate('/');
  }

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <ManagerAppBar open={open} toggleDrawer={toggleDrawer}/>
        <ManagerDrawer open={open} toggleDrawer={toggleDrawer} setSelectedSection={setSelectedSection}/>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {selectedSection === 'dashboard' && <Dashboard />}
            {selectedSection === 'crowdPois' && <CrowdPoiManager />}
            {selectedSection === 'harbors' && <HarborManager editHarbor={editHarbor} />}
            {selectedSection === 'harbor' && <EditHarbor selectedHarborId={selectedHarborId} />}

            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Container>
        </Box>
      </Box>
  );

}

export default injectIntl(withSnackbar(ManagerPage));