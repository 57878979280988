import { Box } from '@mui/material';
import React from 'react';
import LinePropertiesEditor from './LinePropertiesEditor';
import StructurePropertiesEditor from './StructurePropertiesEditor';
import InformationPointPropertiesEditor from './InformationPointPropertiesEditor';
import { ContentPasteSearchOutlined } from '@mui/icons-material';
import MooringareaPropertiesEditor from './MooringareaPropertiesEditor';

const HarborMapPropertiesEditor = (props) => {
  const { feature, saveProperties } = props;
 
  const featureType = feature?.geometry?.type || false;
  const polygonType = feature?.properties?.polygonType || false;
  console.log(feature)
  if (!featureType) {
    return (
      <>
        Ominaisuuden tyyppi puuttuu. Tätä ei kyllä olisi pitänyt tapahtua.
      </>
    )
  }

  return (
    <>
    <Box my={2} p={2} sx={{border: '2px solid black'}}>
      {featureType === 'LineString' && <LinePropertiesEditor feature={feature} saveProperties={saveProperties} />}
      {featureType === 'Polygon' && polygonType === 'construction' && <StructurePropertiesEditor feature={feature} saveProperties={saveProperties} />}
      {featureType === 'Polygon' && polygonType === 'mooringarea' && <MooringareaPropertiesEditor feature={feature} saveProperties={saveProperties} />}
      {featureType === 'Point' && <InformationPointPropertiesEditor feature={feature} saveProperties={saveProperties} />}
    </Box>
    </>
  );
};

export default HarborMapPropertiesEditor;