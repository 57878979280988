import * as React from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Alert, AlertTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import fullLogo from '../../assets/img/verttifull.svg';
import { display } from '@mui/system';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const DisclaimerModal = (props) => {
  const  { disclaimerModalOpen, setDisclaimerModalOpen, startTour } = props;
  const handleClose = () => setDisclaimerModalOpen(false);
  const handleStartTutorial = () => {
    setDisclaimerModalOpen(false);
    startTour();
  }

  return (
    <BootstrapDialog
        PaperProps={{ sx: { width: "600px"} }}
        open={disclaimerModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <DialogContent>
          <Box p={3} >
            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box>
                <img src={fullLogo} alt='Vertti.io' width='120px'/>
              </Box>
              <Box mt={3}>
                <Typography variant='body1'>
                  Tervetuloa Verttiin! Vertti on erinomainen työkalu veneilyreissujen suunnitteluun. Se ei kuitenkaan ole navigointiväline, saati merikartta ✌️
                </Typography>
              </Box>
              <Box mt={3}>
                <Alert severity='info'>
                  <AlertTitle>Muutos reittipisteen lisäyksessä</AlertTitle>
                  Uusi reittipiste lisätään nyt painamalla pitkään valittua kohtaa kartalla.
                </Alert>
              </Box>
              <Box mt={3}>
                <Button variant='contained' onClick={handleStartTutorial}>Katso pikaohjeet</Button>
              </Box>
              <Box mt={3}>
                <a href='https://www.facebook.com/vertti.io' target="_blank" rel="noopener noreferrer">
                  <IconButton aria-label="delete" color="primary">
                    <FacebookRoundedIcon />
                  </IconButton>
                </a>
                <a href='https://www.instagram.com/vertti.io/' target="_blank" rel="noopener noreferrer">
                  <IconButton aria-label="delete" color="primary">
                    <InstagramIcon />
                  </IconButton>
                </a>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default injectIntl(DisclaimerModal);