import React, { useRef, useEffect, useState } from 'react';
import { withSnackbar, useSnackbar } from 'notistack';
import { Alert, Box, Button, FormControlLabel, Slider, Switch, Typography } from '@mui/material';
import maplibregl from 'maplibre-gl';
import Map, { Marker, NavigationControl, AttributionControl, Source, Layer, ScaleControl } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import './mapboxdraw.css';

import verttiApi from '../../../../api/verttiApi';

import VerttiMapStyle from '../../../../vertti_map_style.json';
import etelaviitta from '../../../../assets/img/etelaviitta.png';
import pohjoisviitta from '../../../../assets/img/pohjoisviitta.png';
import itaviitta from '../../../../assets/img/itaviitta.png';
import lansiviitta from '../../../../assets/img/lansiviitta.png';
import vihreaviitta from '../../../../assets/img/vihreaviitta.png';
import punainenviitta from '../../../../assets/img/punainenviitta.png';
import erikoismerkkiviitta from '../../../../assets/img/erikoismerkkiviitta.png';
import kariviitta from '../../../../assets/img/kariviitta.png';
import turvavesiviitta from '../../../../assets/img/turvavesiviitta.png';
import kiviVedenalainen from '../../../../assets/img/kivi-vedenalainen.png';
import kiviVedenpaallinen from '../../../../assets/img/kivi-vedenpaallinen.png';
import kiviVedenrajassa from '../../../../assets/img/kivi-vedenrajassa.png';
import unchartedPattern from '../../../../assets/img/uncharted-pattern.png';
import buoyImage from '../../../../assets/img/harbormap/buoy.png';
import fingerImage from '../../../../assets/img/harbormap/finger.png';
import polesImage from '../../../../assets/img/harbormap/poles.png';
import mooringlineImage from '../../../../assets/img/harbormap/mooringline.png';
import anchorImage from '../../../../assets/img/harbormap/anchor.png';
import longsidesImage from '../../../../assets/img/harbormap/longsides.png';

import HarborMapPropertiesEditor from './HarborMapPropertiesEditor';

import { harborMapMooringPolygonLayer, harborMapMooringLayer, harborMapPolygonLayer } from '../../../map/mapLayers';
import HarborMapsDialog from './HarborMapsDialog';
import HarborMapEditorToolbar from './HarborMapEditorToolbar';

const HarborMapEditor = (props) => {
  const { initialLng, initialLat, harborId } = props;
  const mapRef = useRef();
  const drawRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [displayPreviewLayer, setDisplayPreviewLayer] = useState(true);
  const [harborMapGeoJson, setHarborMapGeoJson] = useState(null);
  const [displaySatelliteLayer, setDisplaySatelliteLayer] = useState(false);
  const [satelliteLayerOpacity, setSatelliteLayerOpacity] = useState(40);
  const [loading, setLoading] = useState(false);
  const [harborMaps, setHarborMaps] = useState([]);
  const [publishedMapId, setPublishedMapId] = useState(null);
  const [selectedMapId, setSelectedMapId] = useState(null);
  const [mapsDialogOpen, setMapsDialogOpen] = useState(false);
  const [cursorType, setCursorType] = useState('auto');
  const [drawMode, setDrawMode] = useState('simple_select');
  const [logicalDrawMode, setLogicalDrawMode] = useState('');
  const [previousLogicalDrawMode, setPreviousLogicalDrawMode] = useState('');

  const setCursor = (mode) => {
    switch (mode) {
      case 'draw_line_string':
      case 'draw_polygon':
      case 'draw_point': setCursorType('crosshair'); break;
      default: setCursorType('auto');
    }
  }

  const handleDrawModeChange = (event, modeParam) => {
    const draw = drawRef.current;
    let mode = modeParam === null ? 'simple_select' : modeParam;

    if (modeParam === 'draw_polygon_construction') {
      setLogicalDrawMode('draw_polygon_construction');
      setPreviousLogicalDrawMode('draw_polygon_construction');
      mode = 'draw_polygon';
    }
    else if (modeParam === 'draw_polygon_mooringarea') {
      setLogicalDrawMode('draw_polygon_mooringarea');
      setPreviousLogicalDrawMode('draw_polygon_mooringarea');
      mode = 'draw_polygon';
    }
    else {
      setLogicalDrawMode(mode);
    }

    setDrawMode(mode);
    draw.changeMode(mode);
    setCursor(mode);
  } 

  const handleDisplaySatelliteLayer = (event, value) => {
    const layerStatus = value === true ? true : false;
    setDisplaySatelliteLayer(layerStatus);
  }

  const handleDisplayPreviewLayer = (event, value) => {
    const layerStatus = value === true ? true : false;
    setDisplayPreviewLayer(layerStatus);
  }

  const handlePublishMap = () => {
    verttiApi.get(`manager/harbor/${harborId}/harbormap/${selectedMapId}/publish`)
      .then((res) => {
        enqueueSnackbar('Satamakartta julkaistu!', { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Satamakartan julkaisu epäonnistui', { variant: 'error' });
      })
  }

  const deleteSelectedObjects = (event) => {
    const draw = drawRef.current;
    const selectedIds = draw.getSelectedIds();
    if (selectedIds.length > 0) {
      draw.delete(selectedIds);
      updateHarborMap();
    }
  }

  const [mapViewState, setMapViewState] = useState({
    longitude: initialLng,
    latitude: initialLat,
    zoom: 17,
  });

  const handleOpacityChange = (event, newValue) => {
    setSatelliteLayerOpacity(newValue);
  };

  const loadAllHarborMaps = () => {
    setLoading(true);
    verttiApi.get(`manager/harbor/${harborId}/allharbormaps`)
    .then((res) => {
      const maps = res.data.harborMaps.map(m => {
        if (m.data === '') return m;
        return {
          ...m,
          data: JSON.parse(m.data)
        }
      })
      setHarborMaps(maps);
      setPublishedMapId(res.data.publishedHarborMap?.publicId || null);
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setLoading(false);
      enqueueSnackbar('Satamakarttojen lataus epäonnistui.', { variant: 'error' });
    })
  }
  
  useEffect(() => {
    loadAllHarborMaps();
    if (selectedMapId === null) {
      setMapsDialogOpen(true);
    }
  }, [])

  /* draw.create needs to be reinitialized every time logicalDrawMode changes, 
     so that current mode is available to the handler */
  useEffect(() => {
    const { current: map } = mapRef;
    if (logicalDrawMode !== '') map.on('draw.create', handleObjectCreated);
  }, [logicalDrawMode])

  const updateHarborMap = () => {
    const draw = drawRef.current;
    setHarborMapGeoJson(draw.getAll()); 
  }

  /* This fires when a new object is created */
  const handleObjectCreated = (e) => {
    const type = e.features[0]?.geometry?.type;
    const id = e.features[0]?.id;

    if (!id) return;

    console.log('OOOOO', logicalDrawMode, type, id)

    // if type is LineString, automatically assign it as a recommended route
    if (type === 'LineString') {
      saveProperties({
        featureId: id,
        property: 'lineType',
        values: 'recommendedRoute',
      });
    }
    // If type is Polygon, set general type according to logical draw mode
    else if (type === 'Polygon') {
      if (logicalDrawMode === 'draw_polygon_construction') {
        saveProperties({
          featureId: id,
          property: 'polygonType',
          values: 'construction',
        });  
      }
      else if (logicalDrawMode === 'draw_polygon_mooringarea') {
        saveProperties({
          featureId: id,
          property: 'polygonType',
          values: 'mooringarea',
        });  
      }
    }
    else {
      // In other cases saveProperties calls updateHarborMap
      updateHarborMap();
    }
  }

  function setDataToEdit(data) {
    const draw = drawRef.current;
    draw.deleteAll();

    if (data === '') {
      enqueueSnackbar('Satamakartta on vielä tyhjä.', { variant: 'info' });  
      return true;
    }

    data.features.forEach(feature => {
        draw.add(feature);
    });
  }

  const saveMap = () => {
    const draw = drawRef.current;

    if (!selectedMapId) return false;

    const data = JSON.stringify(draw.getAll());

    verttiApi.patch(`manager/harbor/${harborId}/harbormap/${selectedMapId}`, {data})
      .then(() => {
        loadAllHarborMaps();
        enqueueSnackbar('Kartta tallennettu', { variant: 'success' });  
      })
      .catch((err) => {
        enqueueSnackbar('Kartan tallennus epäonnisui', { variant: 'error' });  
      })
  }

  const startEditing = (publicId) => {
    if (!publicId) return false;

    setSelectedMapId(publicId);

    const map = harborMaps.filter(m => m.publicId === publicId)[0];
    if (!map) return false;

    setDataToEdit(map.data);
    updateHarborMap();
    setMapsDialogOpen(false);
    enqueueSnackbar(`Kartta ${map.name} avattu muokattavaksi`, { variant: 'success' });
  }

  const saveProperties = ({featureId, property, values}) => {
    const draw = drawRef.current;
    draw.setFeatureProperty(featureId, property, values);
    updateHarborMap();
  };

  function handleSelectionChange(e) {
    const draw = drawRef.current;

    const selectedFeatures = draw.getSelected();

    if (selectedFeatures.features.length > 1) {
      enqueueSnackbar('Valitse vain yksi objekti, jos haluat muokata ominaisuuksia.', { variant: 'info' });
      setSelectedFeature(null);
      return false;
    }

    if (selectedFeatures.features.length < 1) {
      setSelectedFeature(null);
      return false;
    }

    setSelectedFeature(selectedFeatures.features[0]);
  }

  const initMap = () => {
    const { current: map } = mapRef;

    MapboxDraw.constants.classes.CONTROL_BASE  = 'maplibregl-ctrl';
    MapboxDraw.constants.classes.CONTROL_PREFIX = 'maplibregl-ctrl-';
    MapboxDraw.constants.classes.CONTROL_GROUP = 'maplibregl-ctrl-group';

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      singleSelect: true,
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: false
      },
    });

    map.addControl(draw);

    drawRef.current = draw;

    map.on('draw.modechange', function(event) {
      setDrawMode(event.mode);
      if (event.mode === 'simple_select') setLogicalDrawMode('simple_select');
      setCursor(event.mode);
    });

    updateHarborMap();

    // map.on('draw.create', handleObjectCreated);
    map.on('draw.delete', updateHarborMap);
    map.on('draw.update', updateHarborMap);
    map.on('draw.selectionchange', handleSelectionChange);

    const images = [
      {name: 'punainenviitta', file: punainenviitta},
      {name: 'vihreaviitta', file: vihreaviitta},
      {name: 'etelaviitta', file: etelaviitta},
      {name: 'pohjoisviitta', file: pohjoisviitta},
      {name: 'itaviitta', file: itaviitta},
      {name: 'lansiviitta', file: lansiviitta},
      {name: 'erikoismerkkiviitta', file: erikoismerkkiviitta},
      {name: 'kariviitta', file: kariviitta},
      {name: 'turvavesiviitta', file: turvavesiviitta},
      {name: 'kiviVedenalainen', file: kiviVedenalainen},
      {name: 'kiviVedenpaallinen', file: kiviVedenpaallinen},
      {name: 'kiviVedenrajassa', file: kiviVedenrajassa},
      {name: 'unchartedPattern', file: unchartedPattern},
      {name: 'buoy', file: buoyImage},
      {name: 'finger', file: fingerImage},
      {name: 'poles', file: polesImage},
      {name: 'mooringline', file: mooringlineImage},
      {name: 'anchor', file: anchorImage},
      {name: 'longsides', file: longsidesImage},
    ];

  
    Promise.all(
      images.map(img => 
        map.loadImage(img.file)
          .then(res => {
            map.addImage(img.name, res.data);
          })
          .catch(err => {
            console.error(`Error loading image ${img.name}:`, err);
          })
      )
    )
    .then(() => {
    })
    .catch((err) => {
      console.error('Error loading images:', err);
    });
  }

  const onMove = e => {
    setMapViewState(e.viewState);
  }

  //https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=4Bi3v0Oyc8t7v852sNUK
  return (
    <>
    <Alert severity='warning'>Satamakarttaeditori on vasta testikäytössä. Karttoja ei esitetä vielä käyttäjille.</Alert>
    <Box my={1}>
      <HarborMapEditorToolbar 
        logicalDrawMode={logicalDrawMode} 
        handleDrawModeChange={handleDrawModeChange} 
        deleteSelectedObjects={deleteSelectedObjects}
        handleDisplaySatelliteLayer={handleDisplaySatelliteLayer}
        displaySatelliteLayer={displaySatelliteLayer}
        handleDisplayPreviewLayer={handleDisplayPreviewLayer}
        displayPreviewLayer={displayPreviewLayer}
        setMapsDialogOpen={setMapsDialogOpen}
        handlePublishMap={handlePublishMap}
        editingDisabled={selectedMapId === null}
        saveMap={saveMap}
      />
    </Box>
    <Map
      onLoad={() => initMap()}
      mapLib={maplibregl}
      ref={mapRef}
      onMove={onMove}
      cursor={cursorType}
      {...mapViewState}
      style={{ width: '100%', height: '60vh', border: '2px solid black' }}
      mapStyle={VerttiMapStyle}
      attributionControl={false}
    >
      <NavigationControl />
      <AttributionControl customAttribution={['© Väylävirasto', '© Traficom', '© Maanmittauslaitos', 'Ei saa käyttää navigointiin']} compact={true}/>
      <ScaleControl unit='metric' maxWidth='250' />
      {/* <Marker longitude={initialLng} latitude={initialLat} anchor='bottom'/> */}
      
      {displaySatelliteLayer && (
        <Source
          id="maptiler-satellite"
          type="raster"
          tileSize={256}
          tiles={["https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=4Bi3v0Oyc8t7v852sNUK"]}
        >
          <Layer
            id="maptiler-satellite-layer"
            type="raster"
            source="maptiler-satellite"
            beforeId={harborMapGeoJson ? 'harborMapPolygons' : ''}
            paint={{
              'raster-opacity': satelliteLayerOpacity/100,
            }}
          />
        </Source>
      )}

      {displayPreviewLayer && harborMapGeoJson && (
        <Source id="harborMapPreview" type="geojson" data={harborMapGeoJson}>
          <Layer {...harborMapMooringPolygonLayer} />
          <Layer {...harborMapPolygonLayer} />
          {harborMapMooringLayer.map((layer, index) => (
            <Layer key={index} {...layer} />
          ))}
        </Source>
      )}
    <Box width={200} mt={1} ml={2}>
      {displaySatelliteLayer && (
        <Slider
          value={satelliteLayerOpacity}
          onChange={handleOpacityChange}
          aria-labelledby="satellite-layer-opacity-slider"
          min={0}
          max={100}
          step={1}
          size='small'
        />
      )}
    </Box>

    </Map>
    {selectedFeature && <HarborMapPropertiesEditor feature={selectedFeature} saveProperties={saveProperties} />}
    {mapsDialogOpen && (<HarborMapsDialog
      harborId={harborId}
      harborMaps={harborMaps} 
      startEditing={startEditing}
      publishedMapId={publishedMapId}
      selectedMapId={selectedMapId} 
      setSelectedMapId={setSelectedMapId}
      loadAllHarborMaps={loadAllHarborMaps} 
      mapsDialogOpen={mapsDialogOpen}
      setMapsDialogOpen={setMapsDialogOpen} /> 
    )}
    </>
  );
}

export default withSnackbar(HarborMapEditor);