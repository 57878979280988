import React, { useState, useEffect, useContext, createContext } from "react";
import verttiApi from '../api/verttiApi';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  //const [jwt, setJwt] = useState(null);
  const [loginResult, setLoginResult] = useState(null);
  const [silentLoginInProgress, setSilentLoginInProgress] = useState(true);

  useEffect(()=> {
    silentLogin();
  }, []);

  const silentLogin = () => {    
    const userString = localStorage.getItem('user');
    if (userString) setUser(JSON.parse(userString));

    const accessToken = localStorage.getItem('accessToken');
    if (userString & accessToken) setUser(JSON.parse(userString));

    if (accessToken && userString) setLoginResult('silent_login_completed');
    setSilentLoginInProgress(false);
  }

  const signin = (email, password) => {
    setLoginResult(null);
    verttiApi.post('signin', {email, password})
      .then((result) => { 
        const { user, accessToken, refreshToken } = result.data;
//        setJwt(token);
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        setLoginResult('success');
      })
      .catch((err) => {
        console.error(err);
        setLoginResult('failed');
      });
  }
  // const signup = (email, password) => {
  //   verttiApi.post('signup', {email, password})
  //     .then((result) => {
  //       console.log('Registration successfull', result.data.user);
  //     })
  //     .catch((err) => {
  //       console.error('Registration error', err);
  //     })
  // };

  const signout = () => {
    localStorage.clear();
    setUser(false);
    setLoginResult('logged-out');
    //setJwt(false);
  };

  const setNickName = (newNick, setResult) => {
    setResult('pending');
    verttiApi.post('user/nickname', { nickName: newNick })
      .then((result) => {
        const { user } = result.data;
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        setResult('saved');
      })
      .catch((err) => {
        console.error(err);
        setResult('error');
      })
  };

  const isNickNameAvailable = (newNick, setResult) => {
    verttiApi.post('user/nicknameavailable', { nickName: newNick })
      .then((result) => {
        const {available} = result.data;
        if (available === true) setResult('available');
        else setResult('unavailable');
      })
      .catch((err) => {
        console.error(err);
        setResult('error');
      })
  };

  const isAuthorizedToHarbor = (publicId) => {
    // Immediately return true if the user is an admin.
    if (user?.role === 'admin') return true;
  
    // Check if the user has entitlements to specific harbors and if one of those matches the publicId.
    return !!user?.entitlements?.harbors?.some(harbor => harbor.publicId === publicId);
  };

  const isAuthorizedToAnyHarbor = () => {
    if (user?.role === 'admin') return true;
    if ( user?.entitlements?.harbors?.length > 0) return true;
    return false;
  } 

  const isAdmin = () => {
    return user?.role === 'admin';
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(false);
  //     }
  //   });
  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);
  // Return the user object and auth methods
  return {
    user,
    loginResult,
    silentLoginInProgress,
    signin,
    signout,
    setNickName,
    isNickNameAvailable,
    isAuthorizedToHarbor,
    isAuthorizedToAnyHarbor,
    isAdmin,
  };
}