import * as React from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, Pagination, getPaginationItemUtilityClass, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import RoutingImage from '../../assets/img/tutorial/routing-tutorial.png';
import HarborImage from '../../assets/img/tutorial/harbor-tutorial.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const indexPage = () => {
  return (
  <>
    <Typography variant="body1" gutterBottom>
      Kiva nähdä!
    </Typography>
    <Typography variant="body2" gutterBottom>
      Tässä muutama vinkki Veneilijän reittiopas Vertin beta-version käyttöön.
    </Typography>
    <Typography variant="body2" gutterBottom>
      Ihan ensimmäisenä todettakoon, että Vertin kehitys on vielä kesken. Jos jokin ei toimi odotetusti, toivomme malttia matkaan ja mielellään raporttia osoitteeseen <a href="mailto:posti@vertti.io">posti@vertti.io</a>.
    </Typography>
    <Typography variant="body2" gutterBottom>
      <b>Tärkeää</b>: Vertti on reittisuunnittelun apuväline. Kartta ei ole merikartta, eikä sitä tule käyttää navigointiin.
    </Typography>
    <Typography variant="body2" gutterBottom>
      Seuraa Verttiä myös somessa ja tiedät heti kun uusia ominaisuuksia on tarjolla.
    </Typography>
    <Box marginY={1}>
      <a href='https://www.facebook.com/vertti.io' target="_blank" rel="noopener noreferrer">
        <IconButton aria-label="delete" color="primary">
          <FacebookRoundedIcon />
        </IconButton>
      </a>
      <a href='https://www.instagram.com/vertti.io/' target="_blank" rel="noopener noreferrer">
        <IconButton aria-label="delete" color="primary">
          <InstagramIcon />
        </IconButton>
      </a>
      <a href='https://twitter.com/vertti_io' target="_blank" rel="noopener noreferrer">
        <IconButton aria-label="delete" color="primary">
          <TwitterIcon />
        </IconButton>
      </a>
      </Box>

  </>
  )
}

const routingPage = () => {
  return (
    <>
    <Box mb={1}>
      <img src={RoutingImage} alt="routing" width="100%"/>
    </Box>
    <Typography variant="body1" gutterBottom>
      Automaattinen ja manuaalinen reititys
    </Typography>
    <Typography variant="body2" gutterBottom>
      Oikean alakulman napilla vaihdat reititystilaa automaattisen ja manuaalisen välillä. Kuvassa pisteiden 1 ja 2 välillä käytössä on automaattinen reititys väyliä pitkin. Seuraavat pisteet on reititetty manuaalisesti väylien ulkopuolella.
    </Typography>
    <Typography variant="body2" gutterBottom>
      Reittiä luot yksinkertaisesti klikkaamalla kartalta kohtaa, johon haluat lisätä reittipisteen.
    </Typography>
    <Typography variant="body2" gutterBottom>
      Mikäli automaattisen reitityksen alku- tai päätepiste on väylän ulkopuolella, etsitään läheinen piste väylältä ja tehdään suora reititys siihen. Jos lähellä ei ole väylää, joudut reitittämään manuaalisesti lähemmäksi väylää.
    </Typography>
    </>
  );
}

const harborPage = () => {
  return (
    <>
    <Box mb={1}>
      <img src={HarborImage} alt="routing" width="100%"/>
    </Box>
    <Typography variant="body1" gutterBottom>
      Satamat
    </Typography>
    <Typography variant="body2" gutterBottom>
      Vertti sisältää satamatiedot, jotka päivitetään ajantasalle talven aikana.
    </Typography>
    <Typography variant="body2" gutterBottom>
      Sataman ikonia klikkaamalla tai täppäämällä avautuu valikko, josta voit lisätä sataman reittipisteeksi tai avata sataman tiedot.
    </Typography>
    <Box marginY={2}>
    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
      <Grid item xs={1}>
        <img src={harborRedImage} alt="Vierassatama" width={25}/>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2"><b>Vierassatama</b>: Tarjoaa runsaasti palveluita vierasveneille</Typography>
      </Grid>
      <Grid item xs={1}>
        <img src={harborBlueImage} alt="Palvelusatama" width={25}/>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2"><b>Palvelusatama</b>: Satamassa voi käydä hyödyntämässä palveluita, mutta ei yöpyä.</Typography>
      </Grid>
      <Grid item xs={1}>
        <img src={harborYellowImage} alt="Retkisatama" width={25}/>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2"><b>Retkisatama</b>: Retkikohde jossa vain vähän tai ei lainkaan palveluita.</Typography>
      </Grid>
      <Grid item xs={1}>
        <img src={harborGrayImage} alt="Kotisatama" width={25}/>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2"><b>Kotisatama</b>: Ei tarjoa palveluita vierasveneille.</Typography>
      </Grid>
    </Grid> */}
    </Box>
    </>
  );
}


const TutorialModal = (props) => {
  const { tutorialModalOpen, setTutorialModalOpen } = props;
  const handleClose = () => setTutorialModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentPage = (page) => {
    switch (page) {
      case 1: return indexPage();
      case 2: return routingPage();
      case 3: return harborPage();
      default: return <></>;
    }
  }

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        PaperProps={{ sx: { width: "600px"} }}
        open={tutorialModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Vinkkejä Vertin käyttöön
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <>
          <Box marginY={2}>
            {currentPage(page)}
          </Box>
          </> 
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Pagination count={3} variant="outlined" color="primary" onChange={(e, page) => setPage(page)} />
          </Box>

          <Button onClick={handleClose} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default injectIntl(TutorialModal);