import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, IconButton, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MapIcon from '@mui/icons-material/Map';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import verttiApi from '../../../../api/verttiApi';
import moment from 'moment';
import 'moment/locale/fi'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const HarborMapsDialog = (props) => {
  const { harborMaps, harborId, selectedMapId, startEditing, publishedMapId, loadAllHarborMaps, mapsDialogOpen, setMapsDialogOpen } = props;

  const [newMapName, setNewMapName] = useState('');
  const [loading, setLoading] = useState(false);
  
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = () => setMapsDialogOpen(false);

  const handleCreateNewHarborMap = () => {
    if (newMapName === '') {
      enqueueSnackbar('Anna satamakartalle nimi', {
        variant: 'error',
      })
      return false;
    }

    setLoading(true);

    verttiApi.post(`/harbor/${harborId}/harbormap`, {name: newMapName})
      .then(() => {
        loadAllHarborMaps();
        setNewMapName('');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Hopsista, uuden satamakartan luonti epäonnistui', {
          variant: 'error',
        });
        console.error(err);
      });

  }

  const MapListItem = ({item}) => {
      return (
      <ListItem
        key={item.id}
        secondaryAction={
          <Tooltip title='Muokkaa'>
            <IconButton edge="end" disabled={item.publicId === selectedMapId} aria-label="delete" onClick={(e) => {e.stopPropagation(); startEditing(item.publicId)}}>
              <FileOpenIcon />
            </IconButton>
          </Tooltip>
        }
        // onClick={loadSateRoutePlan(item.id)}
      >
        <ListItemAvatar>
          <Avatar sx={{backgroundColor: item.publicId === publishedMapId ? 'green' : null}}>
            {item.publicId === publishedMapId ? <CheckCircleIcon /> : <MapIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={<>{item.name} {item.publicId === publishedMapId ? '(JULKAISTU)' : null}</>} secondary={`Muokattu viimeksi ${moment(item.lastUpdate).fromNow()}`} />
      </ListItem>
    );
  }
  
  const MapList = () => {
    return (
      <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
        {harborMaps.map(m => {
          return <MapListItem key={m.publicId} item={m} />
        })}
      </List>
    );
  };



  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        maxWidth='md'
        open={mapsDialogOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Satamakartat
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {harborMaps.length === 0 && <Typography variant='body1'>Satamalla ei ole vielä yhtään satamakarttaa.</Typography>}
          <MapList />

          <Box mt={4} />
          <Typography variant='h6'>Uusi satamakartta</Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <TextField 
              label='Uuden satamakartan nimi' 
              helperText='Satamakartan nimeä ei esitetä käyttäjille, mutta se auttaa ylläpitäjää tunnistamaan kartan.'
              value={newMapName}
              onChange={(e) => setNewMapName(e.target.value)}
              fullWidth
            />
            <IconButton ml={1} onClick={() => handleCreateNewHarborMap()} disabled={loading}>
              <AddBoxIcon fontSize='large'/>
            </IconButton>
          </Box> 
        </DialogContent>
    </BootstrapDialog>

  );
};

export default withSnackbar(HarborMapsDialog);