import { createTheme } from '@mui/material';

const verttiTheme = createTheme({
  palette: {
    primary: {
      main: '#3E7BBC'
    },
    success: {
      main: '#4caf50'
    },
    active: {
      main: '#FF3366'
    },
    inactive: {
      main: '#999999'
    },
    warning: {
      main: '#FFBA1E'
    },
  },
  typography: {
    fontFamily: 'finlandica',
    fontSize: 16,
    managerHeading: {
      fontWeight: 400,
      fontSize: '2.2rem'
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.2rem'
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.75rem'
    },
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 500
    },
    commentHeader: {
      fontWeight: 600,
      fontSize: '0.9rem'
    },
    commentSubject: {
      fontWeight: 600,
      fontSize: '1.1rem'
    },
    commentBody: {
      fontWeight: 400,
      fontSize: '1.0rem',
    },
    routeIndex: {
      fontWeight: 400,
      fontSize: '1.0rem'
    },
    routeItemPosition: {
      fontWeight: 400,
      fontSize: '0.7rem'
    },
    routeItemName: {
      fontWeight: 400,
      fontSize: '0.9rem'
    },
    weatherHeading: {
      fontSize: '1.5rem',
      fontWeight: 500
    },
    weatherLabel: {
      fontSize: '0.9rem',
      fontWeight: 400
    },
    weatherValue: {
      fontSize: '2.5rem',
      fontWeight: 500
    },
    weatherUnit: {
      fontSize: '1.0rem',
      fontWeight: 400
    },
    small: {
      fontSize: '0.8rem',
      fontWeigth: 400
    },
    disclaimer: {
      fontSize: '0.9rem',
      fontWeigth: 400,
    }
  }
})

  export default verttiTheme;