import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import verttiApi from '../api/verttiApi';

export const loadWeatherStations = createAsyncThunk('loadWeatherStations', async ({}, thunkAPI) => {
  try {
    var result = await verttiApi.get('weather/stations');
    var stations = [];
    for (const station of result.data) {
      stations.push({
        publicId: station.publicId,
        name: station.name,
        lngLat: [station.lng, station.lat],
        latestObservations: station.latestObservations ? JSON.parse(station.latestObservations) : [],
      });
    }
    return stations;

  } catch (err) {
    console.error('Load route plan failed', err);
    throw new Error(err);
  }
});

export const weatherStationsSlice = createSlice({
  name: 'weatherStations',
  initialState: { 
    value: {
      weatherStations: [],
      status: 'idle',
    }
  },
  reducers: {
    setWeatherStations: (state, action) => {
      state.weatherStations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWeatherStations.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(loadWeatherStations.rejected, (state, action) => {
      state.status = 'idle';    
    });
    builder.addCase(loadWeatherStations.fulfilled, (state, action) => {
      state.weatherStations = action.payload;
      state.status = 'idle';
    });
  }
});

export const { setWeatherStations } = weatherStationsSlice.actions;
export default weatherStationsSlice.reducer;