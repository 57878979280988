import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setRoutingMode } from '../../features/routePlan/newRoutePlanSlice';
import { setAddCrowdPoiActive } from '../../features/user';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import TimelineIcon from '@mui/icons-material/Timeline';
import EditOffIcon from '@mui/icons-material/EditOff';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import { AddLocation } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { setReachDistance, setDisplayReachArea, updateReachable } from '../../features/routePlan/newRoutePlanSlice';
import ReachDialog from './ReachDialog';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function MapToolbar() {
  const dispatch = useDispatch();
  const routingMode = useSelector(state => state.newRoutePlan.routingMode);
  const addCrowdPoiActive = useSelector(state => state.user.addCrowdPoiActive);
  const reachDistance = useSelector(state => state.newRoutePlan.reachDistance);
  const displayReachArea = useSelector(state => state.newRoutePlan.displayReachArea);
  const { enqueueSnackbar } = useSnackbar();

  const [reachDialogOpen, setReachDialogOpen] = useState(false);
  const handleReachDialogClose = () => {
    setReachDialogOpen(false);
  };

  const handleReachDialogOpen = () => {
    setReachDialogOpen(true);
  }

  const handleRoutingMode = (event, mode) => {
    if (mode === null) mode = 'off';
    dispatch(setRoutingMode(mode));
    const message = mode === 'auto' ? 'Automaattireititys käytössä' : mode === 'manual' ? 'Manuaalireititys käytössä' : 'Reitin muokkaus pois käytöstä'
    enqueueSnackbar(message, { variant: 'info' });
  };

  const handleAddCrowdPoi = (event, value) => {
    if (value !== true) value = false;
    dispatch(setAddCrowdPoiActive(value));
  };

  const handleToggleReach = (event, value) => {
    if (value !== true) value = false;
    dispatch(setDisplayReachArea(value));
    if (value === true) dispatch(updateReachable({distance: reachDistance}));
  };

  return (
    <>
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={routingMode}
          exclusive
          onChange={handleRoutingMode}
          aria-label="Reitin muokkaustila"
          className='route-edit-mode'
        >
            <ToggleButton value="off" aria-label="Reitin muokkaus pois">
              <Tooltip title='Reitin muokkaus pois'>
                <EditOffIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="auto" aria-label="Automaattireititys">
              <Tooltip title='Automaattireititys'>
                <AutoGraphIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="manual" aria-label="Manuaalireititys">
              <Tooltip title='Manuaalireititys'>
                <TimelineIcon />
              </Tooltip>
            </ToggleButton>
        </StyledToggleButtonGroup>
        
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        
        <StyledToggleButtonGroup
          size="small"
          exclusive
          value={addCrowdPoiActive}
          onChange={handleAddCrowdPoi}
          className='add-crowd-poi'
        >

            <ToggleButton value={true}>
              <Tooltip title='Lisää veneilykohde'>
                <AddLocationAltIcon />
              </Tooltip>
            </ToggleButton>
        </StyledToggleButtonGroup>

        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        
        <div className='display-reach-area'>
          <StyledToggleButtonGroup
            size="small"
            exclusive
            value={displayReachArea}
            onChange={handleToggleReach}
          >
            <ToggleButton value={true}>
              <Tooltip title='Näytä saavutettava alue'>
                <ArchitectureIcon />
              </Tooltip>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <StyledToggleButtonGroup
            size="small"
            onChange={handleReachDialogOpen}
          >
            <ToggleButton value="asdf" aria-label="color">
              <Tooltip title='Aseta saavutettava alue'>
                <SettingsIcon />
              </Tooltip>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </div>
      </Paper>
    </div>

    <ReachDialog
      dialogOpen={reachDialogOpen}
      handleDialogClose={handleReachDialogClose}
    />
  </>
  );
}

export default withSnackbar(MapToolbar);