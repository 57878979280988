import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, TextField, FormGroup, FormControlLabel, Checkbox, Button, Typography, Grid } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useAuth } from '../hooks/useAuth';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import verttiApi from '../api/verttiApi';
import * as yup from "yup";

import '../App.css';

import bgImage from "../assets/img/loginbg.jpg";
import PrivacyPolicyModal from '../components/map/PrivacyPolicyModal';

function RegisterPage(props) {
  const auth = useAuth();
  let navigate = useNavigate();
  const { window } = props;
  
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState('COLLECT_EMAIL');
  const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false);

  const { formatMessage } = props.intl;

  const registrationSchema = yup.object({
    email: yup
      .string()
      .email(formatMessage({ id: "register.error.missingEmail"}))
      .required(formatMessage({ id: "register.error.missingEmail"})),
   });
  

  const formik = useFormik({
    validationSchema: registrationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: values => {  
      if (values.consent === undefined || values.consent[0] !== 'on') {
        setErrorMessage(formatMessage({ id: "register.error.missingConsent"}));
        return;
      }
  
      attemptRegistration(values.email);
    }
  });

  const attemptRegistration = async (email) => {
    setErrorMessage('');
    setLoading(true);

    verttiApi.post('signup', {email})
      .then((result) => {
        console.log('Registration successfull', result.data.user);
        setLoading(false);
        setPhase('REGISTRATION_COMPLETE');
      })
      .catch((err) => {
        console.error('Registration error', err.response.data);
        switch (err.response.data.errorCode) {
          case "1": setErrorMessage(formatMessage({ id: "register.error.emailAlreadyExists"})); break;
          case "2": setErrorMessage(formatMessage({ id: "register.error.emailSendingError"})); break;
          default: setErrorMessage(formatMessage({ id: "register.error.unknownError"})); break;
        }
        setLoading(false);
      }) 
    };

    if (phase === "REGISTRATION_COMPLETE") return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
  
          <Grid item xs={3}>
            
            <Box p={5} sx={{
              bgcolor: '#ffffff',
              minWidth: {xs: 250, sm: 400},
              maxWidth: {xs: 250, sm: 400},
              boxShadow: 3,
            }}>
              <Box>
                <Typography variant="h4" component="h2">
                  <FormattedMessage id="register.heading"/>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body">
                  <FormattedMessage id="register.registrationComplete"/>
                </Typography>
              </Box>  
              <Box pt={3}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("../login", { replace: true })}>
                  <FormattedMessage id="passwordReset.backToLogin"/>
                </Typography>
              </Box>
            </Box>
            
          </Grid>   
          
        </Grid> 
        <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")",  }}    
        />
      </>
    );

  else return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >

        <Grid item xs={3}>
          
          <Box p={5} sx={{
            bgcolor: '#ffffff',
            minWidth: {xs: 250, sm: 400},
            maxWidth: {xs: 250, sm: 400},
            boxShadow: 3,
          }}>
            <Box>
              <Typography variant="h4" component="h2">
                <FormattedMessage id="register.heading"/>
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body">
                <FormattedMessage id="register.ingress"/>
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Box pt={3} >
                <TextField
                  id='email'
                  label={formatMessage({ id: "general.emailAddress"})}
                  variant='outlined'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  sx={{width: '100%'}}/>
                  {formik.touched.email && formik.errors.email && (
                    <span>{formik.errors.email}</span>
                  )}
              </Box>
              <Box pt={3}>
                <FormGroup>
                  <FormControlLabel 
                      control={<Checkbox id="consent" onChange={formik.handleChange}/>} 
                      label={<FormattedMessage id="register.terms"/>}
                  />
                </FormGroup>
              </Box>
              {errorMessage && (
                <Box pt={3}>
                  <p>{errorMessage}</p>
                </Box>
              )}
              <Box pt={3}>
                <Button 
                  variant="contained" 
                  size="large"
                  disabled={loading}
                  type="submit"
                >
                  <FormattedMessage id="register.submit"/>
                </Button>
              </Box>
              <Box pt={3}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("/", { replace: true })}>
                  <FormattedMessage id="cancel"/>
                </Typography>
              </Box>
              <Box pt={1}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => setPrivacyPolicyModalOpen(true)}>
                  <FormattedMessage id="register.privacyPolicy"/>
                </Typography>
              </Box>
              <Box pt={1}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("../login", { replace: true })}>
                  <FormattedMessage id="passwordReset.backToLogin"/>
                </Typography>
              </Box>
            </form>
          </Box>
          
        </Grid>   
        
      </Grid> 
      <PrivacyPolicyModal privacyPolicyModalOpen={privacyPolicyModalOpen} setPrivacyPolicyModalOpen={setPrivacyPolicyModalOpen} />
      <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")",  }}    
      />
    </>
  );
}

export default injectIntl(RegisterPage);