import * as React from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import licenses from './license.json';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const LicenseModal = (props) => {
  const { licenseModalOpen, setLicenseModalOpen } = props;
  const handleClose = () => setLicenseModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        PaperProps={{ sx: { width: "600px"} }}
        open={licenseModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Tietoja Vertti.io:sta
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Tietoja" {...a11yProps(0)} />
              <Tab label="Lisenssit" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Typography variant="body1" gutterBottom>
              Veneilijän reittiopas Vertti
            </Typography>
            <Typography variant="body2" gutterBottom>
              Verttiä ei saa käyttää navigointiin. Vertti.io on veneilyn etukäteissuunnitteluun tarkoitettu palvelu. 
            </Typography>
            <Typography variant="body2" gutterBottom>
              Sydämelliset kiitokset tahoille, joiden avoin data tekee Vertin mahdolliseksi:
            </Typography>
              <ul>
                <li>Väylät ja merenkulun turvalaitteet: Väylävirasto / latauspalvelu, lisenssi CC 4.0 BY.</li>
                <li>Syvyysalueet: Liikenne- ja viestintävirasto. Ei navigointikäyttöön. Ei täytä asianmukaisen merikartan vaatimuksia.</li>
                <li>Kivet ja matalikot: Maanmittauslaitoksen maastotietokanta</li>
                <li>Nimistö: Maanmittauslaitoksen nimistö 01/2023</li>
                <li>Satamien ilmakuvat: Maanmittauslaitoksen ortokuva-aineisto 02/2023</li>
                <li>Septityhjennysasemat: Septit.net</li>
                <li>Säätiedot: Suomen ilmatieteen laitos</li>
                <li>Jäätiedot: SMHI</li>
                <li>AIS-tiedot: Digitraffic ja Aishub.net</li>
              </ul>
            
            <Typography variant="body2" gutterBottom>
              Kaikissa palvelua koskevissa asioissa voit olla yhteydessä sähköpostitse: posti@vertti.io
            </Typography>
            <Typography variant="body2" gutterBottom>
              ⓒ VGL Innovations Oy<br/>
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <Typography variant="body1" gutterBottom>Lisenssit</Typography>
            {licenses.map((l) => {
              return (
              <Box key={l.name}>
                <Typography variant='h4'>{l.name}</Typography>
                <Typography variant='body2'>{l.licenseType}, {l.link}</Typography>
              </Box>
              )
            })}
          </TabPanel>
          </> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default injectIntl(LicenseModal);