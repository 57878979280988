import * as React from 'react';
//import { useEffect } from 'react';
import { ProvideAuth, useAuth } from './hooks/useAuth';
import { SnackbarProvider } from 'notistack';
import MapPage from './pages/MapPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import PasswordResetPage from './pages/PasswordResetPage';
import ManagerPage from './pages/ManagerPage';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import "@fontsource/finlandica/400.css";
import "@fontsource/finlandica/500.css";
import "@fontsource/finlandica/600.css";
import "@fontsource/finlandica/700.css";
import './App.css';

import { ThemeProvider } from '@mui/material';
import verttiTheme from './themes/verttiTheme';

import {IntlProvider} from 'react-intl'

import analytics from './analytics';
import MapAnimationPage from './pages/MapAnimationPage';

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}
appHeight();

window.addEventListener('resize', appHeight);

let i18nConfig = {
  locale: 'fi',
  messages: {
    "cancel": "Palaa sovellukseen",
    "login.heading": "Tervetuloa Verttiin!",
    "login.login": "Kirjaudu",
    "login.failed": "Sisäänkirjautuminen epäonnistui, tarkista sähköpostiosoite ja salasana.",
    "login.passwordForgotten": "Unohtuiko salasana?",
    "login.register": "Ei tiliä? Haluatko liittyä Vertin beta-testaajaksi?",
    "register.heading": "Tervetuloa mukaan!",
    "register.ingress": "Vertti on vielä varhaisen kehityksen vaiheessa, jossa lähinnä kerätään käyttäjiltä palautetta ja kehitysajatuksia. Näillä saatesanoilla olet tervetullut kokeilemaan!",
    "register.terms": "Hyväksyn henkilötietojen käsittelyn",
    "register.submit": "Rekisteröidy",
    "register.privacyPolicy": "Tietosuojaseloste",
    "register.error.missingEmail": "Anna toimiva sähköpostiosoite",
    "register.error.invalidPassword": "Salasana tarvitaan",
    "register.error.missingConsent": "Virhe: Käyttöehdot tulee hyväksyä",
    "register.error.missingEmailOrPassword": "Virhe: Sähköpostiosoite tai salasana puuttuu",
    "register.error.emailAlreadyExists": "Virhe: Sähköpostiosoite on jo käytössä",
    "register.error.emailSendingError": "Virhe: Sähköpostin lähetys epäonnistui, ottaisitko yhteyttä asiakaspalveluun?",
    "register.registrationComplete": "Käyttäjätili on nyt luotu ja sähköpostiisi on lähetetty ohjeet salasanan asettamiseksi. Jos viestiä ei heti löydy, tarkasta myös mahdolliset välilehdet ja roskapostilaatikko!",
    "register.error.unknownError": "Rekisteröinnissä tapahtui tuntematon virhe",
    "passwordReset.heading": "Salasanan vaihto",
    "passwordReset.request.ingress": "Voit vaihtaa salasanan sähköpostiisi lähetettävällä linkillä. Anna Vertissä käyttämäsi sähköpostiosoite ja lähetämme sinne linkin, mikäli sähköpostiosoite löytyy käyttäjätilistä.",
    "passwordReset.reset.ingress": "Hyvä, nyt voit asettaa uuden salasanan käyttäjätilillesi.",
    "passwordReset.error.missingEmail": "Anna toimiva sähköpostiosoite.",
    "passwordReset.error": "Nyt tapahtui odottamaton virhe. Yrittäisitkö uudelleen?",
    "passwordReset.error.tokennok.heading": "Salasanan vaihto ei onnistu",
    "passwordReset.error.tokennok.ingress": "Salasananvaihtolinkki on saattanut vanhentua tai se on muuten virheellinen. Voit pyytää uutta salasananvaihtolinkkiä.",
    "passwordReset.request.submit": "Pyydä salasanan vaihtoa",
    "passwordReset.reset.submit": "Aseta uusi salasana",
    "passwordReset.successfull.heading": "Salasana vaihdettu",
    "passwordReset.successfull.ingress": "Salasanasi on nyt vaihdettu ja voit kirjautua sisään uudella salasanallasi.",
    "passwordReset.successfull.button": "Kirjaudu sisään",
    "passwordReset.backToLogin": "Takaisin sisäänkirjautumiseen",
    "passwordReset.resetRequested.heading": "Tarkasta sähköpostisi",
    "passwordReset.resetRequested.ingress": "Mikäli antamasi sähköpostiosoite oli oikea, lähetimme juuri sinulle linkin jota seuraamalla voit jatkaa salasanan vaihtoa. Tarkasta myös sähköpostiohjelman välilehdet ja roskapostilaatikko.",
    "general.emailAddress": "Sähköpostiosoite",
    "general.password": "Salasana",
    "appbar.newRoute": "Uusi reittisuunnitelma",
    "appbar.routePlanManager": "Reittisuunnitelmat",
    "appbar.vesselConfigurator": "Aluksen asetukset",
    "appbar.instructions": "Ohjeita",
    "appbar.information": "Tietoja",
    "appbar.userprofile": "Käyttäjätili",
    "appbar.logout": "Kirjaudu ulos",
    "appbar.login": "Kirjaudu sisään",
    "map.dialog.routeNotFound.title": "Automaattinen reititys ei onnistu",
    "map.dialog.routeNotFound.description": "Reittipisteiden välille ei löytynyt reittiä väyliä pitkin. Luultavasti pisteiden välille ei löydy yhtenäistä väylää aluksen syväys huomioiden. Voit täydentää reittiä manuaalireitityksellä ja jatkaa taas automaattireitityksellä.",
    "map.dialog.routeNotFoundSourceTooFar.description": "Reitityksen alkupiste on liian kaukana väylästä (yli 300 metriä). Käytä manuaalista reititystä ja yritä uudelleen lähempänä väylää.",
    "map.dialog.routeNotFoundDestinationTooFar.description": "Reitityksen päätepiste on liian kaukana väylästä (yli 300 metriä). Valitse piste lähempänä väylää ja jatka manuaalisella reitityksellä.",
    "map.dialog.routeNotFound.button": "Selvä",
    "map.dialog.unknownError.title": "Virhe",
    "map.dialog.unknownError.description": "Oho, nyt tapahtui jokin tuntematon virhe.",
    "map.dialog.unknownError.button": "Selvä",
    "map.dialog.loginRequired.title": "Toiminto vaatii sisäänkirjautumisen",
    "map.dialog.loginRequired.description": "Tämän toiminnon suorittaminen edellyttää sisäänkirjautumista.",
    "map.dialog.loginRequired.button": "Ei kiitos",
    "service.harbor.drinkingWater": "Juomavesi",
    "service.harbor.electricity": "Maasähkö",
    "service.harbor.shower": "Suihku",
    "service.harbor.sauna": "Sauna",
    "service.harbor.toilet": "Vessa",
    "service.harbor.laundryRoom": "Pesutupa",
    "service.harbor.dishwashingPoint": "Tiskauspaikka",
    "service.harbor.barbecueArea": "Grillauspaikka",
    "service.boatUpkeep.diesel": "Polttoaine, diesel",
    "service.boatUpkeep.gasoline": "Polttoaine, bensiini",
    "service.boatUpkeep.fuelAvailability24h": "Polttoaine, automaatti",
    "service.boatUpkeep.LPG": "Nestekaasu",
    "service.boatUpkeep.septicDisposal": "Imutyhjennys",
    "service.boatUpkeep.chemicalToiletDisposal": "Kemiallisen WC:n tyhjennys",
    "service.boatUpkeep.garbageReception": "Roskien vastaanotto",
    "service.boatUpkeep.recyclingPoint": "Kierrätyspiste",
    "service.boatUpkeep.mastCrane": "Mastonosturi",
    "service.boatUpkeep.boatRepairs": "Venekorjaamo",
    "service.boatUpkeep.engineRepairs": "Moottorikorjaamo",
    "service.boatUpkeep.ramp": "Veneluiska",
    "service.boatUpkeep.boatingSupplies": "Venetarvikkeita",
    "service.amenities.restaurant": "Ravintola",
    "service.amenities.cafe": "Kahvila",
    "service.amenities.groceryStore": "Elintarvikkeita",
    "service.amenities.pharmacy": "Apteekki",
    "service.amenities.liquorStore": "Alko",
    "service.amenities.ATM": "Pankkiautomaatti",
    "service.amenities.bank": "Pankki",
    "service.amenities.postalOffice": "Posti",
    "service.amenities.playground": "Leikkipaikka",
    "service.amenities.healthServices": "Terveydenhuoltopalveluita",
    "service.amenities.vet": "Eläinlääkäri",
  }
};

function RequireAuth({ children }) {
  let auth = useAuth();

  //let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    window.location.href = '/login';
    //return <Navigate to="/app/login" state={{ from: location }} replace />;
  }

  return children;
}

function App(props) {
  const [isReady, setIsReady] = React.useState(false);

  // Wait until Montserrat font is loaded
  React.useEffect(() => {
    document.fonts.load("12px Montserrat").then(() => setIsReady(true));
    analytics.page();
  }, [])
  
  if (!isReady) return (
    <>
    </>
  );

  return (
    <ProvideAuth>
      <ThemeProvider theme={verttiTheme}>
        <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'top'}} maxSnack={3}>
          <IntlProvider
            locale={i18nConfig.locale}
            defaultLocale={i18nConfig.locale}
            messages={i18nConfig.messages}
          >
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={
                    // <RequireAuth>
                      <MapPage {...props}/>
                    // </RequireAuth>
                  }
                />
                {/* <Route
                  path="/esitys"
                  element={
                    // <RequireAuth>
                      <MapAnimationPage {...props}/>
                    // </RequireAuth>
                  }
                /> */}

                {/* <Route
                  path="/test"
                  element={<TestPage {...props}/>}
                /> */}
                <Route
                  path="/login"
                  element={<LoginPage {...props}/>}
                />
                <Route
                  path="/register"
                  element={<RegisterPage {...props}/>}
                />
                {/* <Route
                  path="/passwordreset/:token"
                  element={<PasswordResetPage {...props}/>}
                /> */}
                <Route
                  path="/passwordreset"
                  element={<PasswordResetPage {...props} />}
                />
                <Route
                  path="/manager/:section?/:id?"
                  element={<ManagerPage {...props} />}
                />
              </Routes>
            </Router>
          </IntlProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ProvideAuth>
  );
}

export default App;