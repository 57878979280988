import * as React from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const PrivacyPolicyModal = (props) => {
  const { privacyPolicyModalOpen, setPrivacyPolicyModalOpen } = props;
  const handleClose = () => setPrivacyPolicyModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        PaperProps={{ sx: { width: "600px"} }}
        open={privacyPolicyModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Vertti.io:n tietosuojaseloste
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <>

          <Typography variant="body1" gutterBottom>Rekisterinpitäjä</Typography>

          <Typography variant="body2" gutterBottom>
            VGL Innovations Oy<br/>
            Huopalahdentie 19 D 39<br/>
            00330 Helsinki<br/>
            Puhelin: 050 5711 000<br/>
            Sähköposti: posti@vertti.io<br/>
          </Typography>

          <Typography variant="body1" gutterBottom>Rekisteriasiat</Typography>

          <Typography variant="body2" gutterBottom>Rekisterinpitäjän asiakaspalvelu palvelee rekisteriasioissa arkisin kello 9 - 16.</Typography>

          <Typography variant="body1" gutterBottom>Rekisterin nimi</Typography>

          <Typography variant="body2" gutterBottom>Vertti.io-palvelun markkinointi- ja asiakasrekisteri.</Typography>

          <Typography variant="body1" gutterBottom>Rekisterin peruste ja käyttötarkoitus</Typography>

          <Typography variant="body2" gutterBottom></Typography>Vertti.io-palvelun asiakkaista ja asiakkuudesta kiinnostuneista pidetään rekisteriä, jonka tarkoituksena on asiakassuhteen hallinta, hoito ja kehittäminen. Rekisteriä käytetään myös asiakkuudesta kiinnostuneen tai asiakkaan ja Vertti.io-palvelun tarjoajan väliseen markkinointiin, mukaan lukien sähköinen markkinointi.

          <Typography variant="body1" gutterBottom>Rekisterin sisältö</Typography>

          <Typography variant="body2" gutterBottom>Rekisteriin tallennettava tieto ei sisällä asiakkaiden henkilökohtaisia tietoja, mikäli ne eivät ole tarpeellisia asiakassuhteen kannalta. Rekisteri voi sisältää seuraavia tietoja:<br/>
            - sähköpostiosoite<br/>
            - markkinointikiellot<br/>
          </Typography>
          <Typography variant="body1" gutterBottom>Tietolähteet</Typography>

          <Typography variant="body2" gutterBottom>Rekisterin ensisijaisia tietolähteitä ovat: Käyttäjän itsensä rekisteröimät tiedot sekä rekisteröidyn käyttäjän asiakkuuteen, palveluiden käyttöön, viestintään sekä asiointiin liittyvät tapahtumat. </Typography>

          <Typography variant="body1" gutterBottom>Tietojen luovutus ja vastaanottajat</Typography>

          <Typography variant="body2" gutterBottom>Rekisterin tietoja ei luovuteta kolmansille osapuolille ilman asiakkaan suostumusta. Asiakastietoja ei siirretä Euroopan unionin tai Euroopan talousalueen ulkopuolelle.</Typography>

          <Typography variant="body1" gutterBottom>Rekisterin suojauksen periaatteet</Typography>

          <Typography variant="body2" gutterBottom>Rekisteriä ylläpidetään teknisenä tallenteena. Tietojärjestelmä on suojattu, ja rekisteriä käyttävät ainoastaan henkilöt, joiden toimenkuvaan sen käyttö kuuluu.</Typography>

          <Typography variant="body1" gutterBottom>Tietojen säilytysaika</Typography>

          <Typography variant="body2" gutterBottom>Henkilötietoja säilytetään enintään kaksi vuotta asiakkaan sopimussuhteen päättymisen jälkeen.</Typography>

          <Typography variant="body1" gutterBottom>Rekisteröidyn oikeudet ja oikeuksien toteuttaminen</Typography>

          <Typography variant="body2" gutterBottom>Euroopan Unionin tietosuoja-asetus takaa rekisteriin kirjatulle henkilölle oikeuden tulla unohdetuksi. Tämä tarkoittaa, että rekisteröity voi vaatia tietojensa poistamista ilmoittamalla asiasta VGL Innovations Oy:n asiakaspalveluun. Pyynnön tulee olla kirjallinen ja allekirjoitettu. </Typography>

          </> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default injectIntl(PrivacyPolicyModal);