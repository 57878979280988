import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { round } from '@turf/helpers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WaterIcon from '@mui/icons-material/Water';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import { useDispatch, useSelector } from 'react-redux';
import { loadRoutePlan } from '../../features/routePlan/newRoutePlanSlice';

import moment from 'moment';
import 'moment/locale/fi'

import verttiApi from '../../api/verttiApi';
import analytics from '../../analytics';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const RoutePlanManagerModal = (props) => {
  const dispatch = useDispatch();
  const currentRoutePlanId = useSelector(state => state.newRoutePlan.id);
  const { routePlanManagementModalOpen, setRoutePlanManagementModalOpen } = props;
  const handleClose = () => setRoutePlanManagementModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [routePlans, setRoutePlans] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    verttiApi.get('routePlans')
      .then((res) => {
        if (!res.data) return;

        const sortedRoutePlans = res.data.sort(
          (objA, objB) => Number(new Date(objB.updated)) - Number(new Date(objA.updated)),
        );

        setRoutePlans(sortedRoutePlans);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Failed fetching route plans', err);
        enqueueSnackbar('Reittisuunnitelmien haku epäonnistui', { variant: 'error' });
      })
  }, []);

  const deleteRoutePlan = (plan) => {
    verttiApi.delete(`routePlan/${plan.id}`)
    .then((res) => {
      const newRoutePlans = routePlans.filter(item => item.id !== plan.id);
      setRoutePlans(newRoutePlans);
      enqueueSnackbar('Reittisuunnitelma poistettu', { variant: 'success' });
      analytics.track('routePlan.delete.succeeded');
    })
    .catch((err) => {
      console.error('Failed deleting route plan', err);
      enqueueSnackbar('Reittisuunnitelman poisto epäonnistui', { variant: 'error' })
      analytics.track('routePlan.delete.failed');
    })
  }

  const loadSateRoutePlan = (e) => {
    return function() {
      actualLoadRoutePlan(e); 
    }
  }

  const actualLoadRoutePlan = (planId) => {
    console.log('Loading route plan', planId);
    if (planId) dispatch(loadRoutePlan( { routePlanId: planId } ));
    handleClose();
    return true;
    // verttiApi.get(`routePlan/${planId}`)
    // .then((res) => {
    //   const { id, name, distance, routePoints: routePointsJson, routeSegments: routeSegmentsJson, vesselDraft } = res.data;
    //   const routePoints = JSON.parse(routePointsJson);
    //   const routeSegments = JSON.parse(routeSegmentsJson);
    
    //   dispatch(setRoute({ id, name, routePoints, routeSegments, totalDistance: distance, vesselDraft, routePlanLoaded: true }));
    //   analytics.track('routePlan.load.succeeded');
    //   enqueueSnackbar('Reittisuunnitelma ladattu', { variant: 'success' });
    // })
    // .catch((err) => {
    //   console.error('Failed loading route plan', err);
    //   enqueueSnackbar('Reittisuunnitelman lataus epäonnistui', { variant: 'error' });
    //   analytics.track('routePlan.load.failed');
    // })
  }


  const renameDialog = (id) => {
    
  }


  const RouteListItem = ({item}) => {
    if (currentRoutePlanId === item.id) return (
      <ListItem
        key={item.id}
      >
        <ListItemAvatar>
          <Avatar>
            <WaterIcon />
          </Avatar>
        </ListItemAvatar>
        {/* <IconButton size="small" onClick={(e) => {e.stopPropagation(); renameDialog(item.id)}}><EditRoundedIcon fontSize="small" /></IconButton> */}
        <ListItemText primary={<>{item.name} </>} secondary={`${round(item.distance/1852, 2)} nm, päivitetty ${moment(item.updated).fromNow()}`} />
      </ListItem>
    );

    return (
      <ListItem
        key={item.id}
        secondaryAction={
          <IconButton edge="end" aria-label="delete" onClick={(e) => {e.stopPropagation(); deleteRoutePlan(item)}}>
            <DeleteIcon />
          </IconButton>
        }
        onClick={loadSateRoutePlan(item.id)}
        button
      >
        <ListItemAvatar>
          <Avatar>
            <WaterIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={`${round(item.distance/1852, 2)} nm, päivitetty ${moment(item.updated).fromNow()}`} />
      </ListItem>
    );
  }
  
  const RouteList = () => {
    return (
      <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
        {routePlans.map(plan => {
          return <RouteListItem key={plan.id} item={plan} />
        })}
      </List>
    );
  };

  const ModalContent = () => {
    if (loading) return 'Odota hetki, haetaan reittisuunnitelmia.';
    if (routePlans.length < 1) return 'Tallennettuja reittisuunnitelmia ei löytynyt, ehkä luot nyt ensimmäisen?';
    return <RouteList />

  };

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        PaperProps={{ sx: { width: "600px"} }}
        open={routePlanManagementModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Reittisuunnitelmat
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ModalContent/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default injectIntl(withSnackbar(RoutePlanManagerModal));