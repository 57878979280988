import React from 'react';
import { useNavigate } from "react-router-dom";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Slider,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Box
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import { setReachDistance, setDisplayReachArea, updateReachable } from '../../features/routePlan/newRoutePlanSlice';


const ReachDialog = ({ dialogOpen, handleDialogClose }) => {
  const dispatch = useDispatch();
  const reachDistance = useSelector(state => state.newRoutePlan.reachDistance);
  const displayReachArea = useSelector(state => state.newRoutePlan.displayReachArea);

  const reachDistanceValueText = (value) => {
    return `${value} nm`;
  }

  const handleReachDistanceSliderChange = (event, newValue) => {
    dispatch(setReachDistance(newValue));
    if (displayReachArea === true) dispatch(updateReachable({distance: newValue}));
  };

  const handleDisplayReachAreaChange = (event, newValue) => {
    dispatch(setDisplayReachArea(newValue));
    if (newValue === true) dispatch(updateReachable({distance: newValue}));
  };
  
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      PaperProps={{ sx: { position: "fixed", top: 10 } }}
    >
    <DialogTitle id="alert-dialog-title">
      Saavutettava alue (BETA)
      <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box mt={3} />
      <Slider
          aria-label="Etäisyys"
          getAriaValueText={reachDistanceValueText}
          valueLabelDisplay="auto"
          value={reachDistance}
          onChange={handleReachDistanceSliderChange}
          step={5}
          min={10}
          max={100}
      />
      <Typography pb={2} pt={2} variant="h4" align="center">{reachDistance} nm</Typography>
    </DialogContent>
    <DialogActions>
    </DialogActions>
  </Dialog>
  )
};

export default ReachDialog;