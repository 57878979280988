import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import maplibregl from 'maplibre-gl';
import './map.css';
import { useAuth } from '../../hooks/useAuth';
import Map, { Marker, NavigationControl, GeolocateControl, FullscreenControl, AttributionControl, Source, Layer, Popup, ScaleControl } from 'react-map-gl';
import {
  Box,
  IconButton,
  ButtonGroup,
  Tooltip,
  Button,
  Chip,
  Typography,
  Stack
} from '@mui/material';
import { useSearchParams } from "react-router-dom";
import tourSteps from './tourSteps';
// import * as THREE from 'three';
// import Threebox from 'threebox-plugin';

import Joyride from 'react-joyride';
import DisclaimerModal from './DisclaimerModal';

import DeleteIcon from '@mui/icons-material/Delete';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';

import verttiApi from '../../api/verttiApi';
import debounce from 'lodash.debounce';
//import harborYellowImage from '../../assets/img/harbor-yellow.png';
import guestHarborImage from '../../assets/img/guestharbor.png';
import serviceHarborImage from '../../assets/img/serviceharbor.png';
import homeHarborImage from '../../assets/img/homeharbor.png';
import poiImage from '../../assets/img/poi.png';
import septicImage from '../../assets/img/septi.png';
import loistoImage from '../../assets/img/loisto.png';
import speedLimitImage from '../../assets/img/nopeusrajoitus.png';
import freeHeightImage from '../../assets/img/alituskorkeus.png';
import anchoringRestrictionAreaImage from '../../assets/img/anchoringrestrictionarea.png';
// import santaImage from '../../assets/img/santa.png';
// import santaUpImage from '../../assets/img/santaup.png';
// import santaDownImage from '../../assets/img/santadown.png';
import vesselImage from '../../assets/img/vessel.png';
import cargoVesselImage from '../../assets/img/ais/cargo.png';
import cargoStationaryVesselImage from '../../assets/img/ais/cargo-stat.png';
import tankerVesselImage from '../../assets/img/ais/tanker.png';
import tankerStationaryVesselImage from '../../assets/img/ais/tanker-stat.png';
import passengerVesselImage from '../../assets/img/ais/passenger.png';
import passengerStationaryVesselImage from '../../assets/img/ais/passenger-stat.png';
import highspeedVesselImage from '../../assets/img/ais/highspeed.png';
import highspeedStationaryVesselImage from '../../assets/img/ais/highspeed-stat.png';
import tugVesselImage from '../../assets/img/ais/tug.png';
import tugStationaryVesselImage from '../../assets/img/ais/tug-stat.png';
import fishingVesselImage from '../../assets/img/ais/fishing.png';
import fishingStationaryVesselImage from '../../assets/img/ais/fishing-stat.png';
import pleasureVesselImage from '../../assets/img/ais/pleasure.png';
import pleasureStationaryVesselImage from '../../assets/img/ais/pleasure-stat.png';
import unspecifiedVesselImage from '../../assets/img/ais/unspecified.png';
import unspecifiedStationaryVesselImage from '../../assets/img/ais/unspecified-stat.png';
import navaidVesselImage from '../../assets/img/ais/navaid.png';

import etelaviitta from '../../assets/img/etelaviitta.png';
import pohjoisviitta from '../../assets/img/pohjoisviitta.png';
import itaviitta from '../../assets/img/itaviitta.png';
import lansiviitta from '../../assets/img/lansiviitta.png';
import vihreaviitta from '../../assets/img/vihreaviitta.png';
import punainenviitta from '../../assets/img/punainenviitta.png';
import erikoismerkkiviitta from '../../assets/img/erikoismerkkiviitta.png';
import kariviitta from '../../assets/img/kariviitta.png';
import turvavesiviitta from '../../assets/img/turvavesiviitta.png';
import kiviVedenalainen from '../../assets/img/kivi-vedenalainen.png';
import kiviVedenpaallinen from '../../assets/img/kivi-vedenpaallinen.png';
import kiviVedenrajassa from '../../assets/img/kivi-vedenrajassa.png';
import unchartedPattern from '../../assets/img/uncharted-pattern.png';
import windBarb5 from '../../assets/img/windbarbs/5kn.png';
import windBarb10 from '../../assets/img/windbarbs/10kn.png';
import windBarb15 from '../../assets/img/windbarbs/15kn.png';
import windBarb20 from '../../assets/img/windbarbs/20kn.png';
import windBarb25 from '../../assets/img/windbarbs/25kn.png';
import windBarb30 from '../../assets/img/windbarbs/30kn.png';
import windBarb35 from '../../assets/img/windbarbs/35kn.png';
import windBarb40 from '../../assets/img/windbarbs/40kn.png';
import windBarb45 from '../../assets/img/windbarbs/45kn.png';
import windBarb50 from '../../assets/img/windbarbs/50kn.png';
import windBarb55 from '../../assets/img/windbarbs/55kn.png';
import windBarb60 from '../../assets/img/windbarbs/60kn.png';
import windBarb65 from '../../assets/img/windbarbs/65kn.png';
import windBarb70 from '../../assets/img/windbarbs/70kn.png';
import windBarb75 from '../../assets/img/windbarbs/75kn.png';
import windBarb80 from '../../assets/img/windbarbs/80kn.png';
import windBarb85 from '../../assets/img/windbarbs/85kn.png';
import windBarb90 from '../../assets/img/windbarbs/90kn.png';
import windBarb95 from '../../assets/img/windbarbs/95kn.png';
import windBarb100 from '../../assets/img/windbarbs/100kn.png';
import windBarbCalm from '../../assets/img/windbarbs/calm.png';
import windBarbNan from '../../assets/img/windbarbs/nan.png';
import windRing from '../../assets/img/windbarbs/windring.png';
import otherRing from '../../assets/img/windbarbs/otherring.png';
import MapDialog from './MapDialog';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setRoutingMode, addWaypoint, removeWaypoint, relocateWaypoint, splitLeg, loadRoutePlan, setVesselDraft, completeRoutePlanLoaded, clearApiErrorCode } from '../../features/routePlan/newRoutePlanSlice';
import { loadWeatherStations } from '../../features/weatherStationsSlice';
import { loadAisTargets } from '../../features/aisTargetsSlice';
import { setHarbors } from '../../features/harbors';
import { setCrowdPois } from '../../features/crowdPois';
import { harborOrtoLayer, windBackgroundLayer, windLayer, temperatureLayer, heatMapLayer, iceLayer, aisVectorsLayer, aisTargetsLayer, routeLineLayer, routePointLabelLayer, routePointLayer, tempPointLayer, tempLineLayer, harborLayer, septicLayer, hillShadingLayer, weatherStationsLayer, reachAreaLayer, crowdPoiLayer } from './mapLayers.js';
import HarborModal from './HarborModal';
import HarborManagerModal from '../harbor/HarborManagerModal';
import WeatherModal from './WeatherModal';
import VerttiMapStyle from '../../vertti_map_style.json';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import analytics from '../../analytics';
import SepticModal from '../septic/SepticModal';
import generateRouteGeoJSON from './lib/generateRouteGeoJSON';
import generateWeatherStationsGeoJSON from './lib/generateWeatherStationsGeoJSON.js';
import AddCrowdPoiDialog from './AddCrowdPoiDialog';
import MapToolbar from './MapToolbar';
import ViewCrowdPoiDialog from './ViewCrowdPoiDialog.js';
import { setAddCrowdPoiActive } from '../../features/user.js';
import IceLegendBox from './IceLegendBox.js';

// import WeatherTimeSlider from '../weather/WeatherTimeSlider.js';

// Santa imports and constants
//import { lineDistance, along, round, point as turfPoint, bearing as turfBearing } from '@turf/turf';
// import santaRoute from '../../assets/json/santa.json';
// import SantaModal from './SantaModal.js';
// const santaDistance = 2754.395725375901;

const joyrideLocale = {
  back: 'Takaisin',
  close: 'Sulje',
  last: 'Viimeinen',
  next: 'Seuraava',
  skip: 'Ohita',
};

const VerttiMap2 = props => {
  const auth = useAuth();
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const mapRef = useRef();

  let longPressInProgress = false;

  const [runTour, setRunTour] = useState(false);
  const startTour = () => {
    setRunTour(true);
  }

  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(true);

  const [searchParams] = useSearchParams();
  const paramLng = searchParams.get('lng');
  const paramLat = searchParams.get('lat');

  //const [ addPoiActive, setAddPoiActive ] = useState(false);
  const [ addPoiDialogOpen, setAddPoiDialogOpen ] = useState(false);
  const handleAddPoiDialogClose = () => {
    setAddPoiDialogOpen(false);
  }

  const [ viewPoiDialogOpen, setViewPoiDialogOpen ] = useState(false);
  const handleViewPoiDialogClose = () => {
    setViewPoiDialogOpen(false);
  }

  const [ mapCenter, setMapCenter ] = useState([]);

  const addCrowdPoiActive = useSelector(state => state.user.addCrowdPoiActive);
  const waypoints = useSelector(state => state.newRoutePlan.waypoints);
  const routePlanLoaded = useSelector(state => state.newRoutePlan.routePlanLoaded);
  const weatherStations = useSelector(state => state.weatherStations.weatherStations);
  const aisTargets = useSelector(state => state.aisTargets.aisTargets);
  const apiErrorCode = useSelector(state => state.newRoutePlan.apiErrorCode);
  const layerSettings = useSelector(state => state.user.layerSettings);
  const reduxHarbors = useSelector(state => state.harbors.value);
  const crowdPois = useSelector(state => state.crowdPois.value);
  const user = useSelector(state => state.user);
  const displayReachArea = useSelector(state => state.newRoutePlan.displayReachArea);
  const reachArea = useSelector(state => state.newRoutePlan.reachArea);
  const routingMode = useSelector(state => state.newRoutePlan.routingMode);
  const reduxAsyncStatus = useSelector(state => state.newRoutePlan.status);

  const { enqueueSnackbar } = useSnackbar();

  const [imagesLoading, setImagesLoading] = useState(true);
  const [routeGeoJSON, setRouteGeoJSON] = useState();
  const [weatherStationsGeoJSON, setWeatherStationsGeoJSON] = useState();

  // const [santaModalOpen, setSantaModalOpen] = useState(false);

  const [circleData, setCircleData] = useState({
    type: 'FeatureCollection',
    features: []
  });

  const [circleRadius, setCircleRadius] = useState(10);
  const pressTimer = useRef(null);
  const growingInterval = useRef(null);

  const maxRadius = 60; // Maximum radius in pixels
  const growthRate = 5; // Pixels per interval

  useEffect(() => {
    return () => {
      clearTimeout(pressTimer.current);
      clearInterval(growingInterval.current);
    };
  }, []);

  const startLongPress = (lngLat) => {
    setCircleData({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lngLat.lng, lngLat.lat]
        }
      }]
    });
  
    if (longPressInProgress) {
      return;
    }
    
    longPressInProgress = true;
    growingInterval.current = setInterval(() => {
      setCircleRadius(prevRadius => {
        if (prevRadius < maxRadius) {
          return prevRadius + growthRate;
        } else {
          if (longPressInProgress) {
            clearInterval(growingInterval.current);
            clearPress();
            newRoutePoint({
              lng: lngLat.lng,
              lat: lngLat.lat,
              description: undefined,
            });
  
            longPressInProgress = false;
            return prevRadius; // Ensure to return the current radius instead of false
          } else {
            // avoid double points
            return prevRadius;
          }
        }
      });
    }, 100); // Growth interval in milliseconds
  };

  const clearPress = () => {
    clearTimeout(pressTimer.current);
    clearInterval(growingInterval.current);
    longPressInProgress = false;
    setCircleRadius(10);
    setCircleData({
      type: 'FeatureCollection',
      features: []
    });
  };


  const [harborModalOpen, setHarborModalOpen] = useState(false);
  const [septicModalOpen, setSepticModalOpen] = useState(false);
  const [harborManagerModalOpen, setHarborManagerModalOpen] = useState(false);

  const [routeLoading] = useState(false);
  const [hoverPointId, setHoverPointId] = useState(null);
  const [displayRoutingMenu, setDisplayRoutingMenu] = useState(false);
  const [displayHarborMenu, setDisplayHarborMenu] = useState(false);
  const [routingMenuPosition, setRoutingMenuPosition] = useState({});
  const [selectedRoutePoint, setSelectedRoutePoint] = useState({});
  const [selectedHarbor, setSelectedHarbor] = useState({});
  const [selectedCrowdPoi, setSelectedCrowdPoi] = useState({});
  const [selectedSepticStation, setSelectedSepticStation] = useState(undefined);
  const [cursorType, _setCursorType] = useState('auto');

  const [selectedWeatherStation, setSelectedWeatherStation] = useState({});
  const [weatherModalOpen, setWeatherModalOpen] = useState(false);

  // const [santaGeoJSON, setSantaGeoJSON] = useState();

  const setCursorType = (type) => {
    if (!routeLoading) _setCursorType(type);
  }

  const [dialogBody, setDialogBody] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogButtonLabel, setDialogButtonLabel] = useState(null);
  const [dialogLoginCta, setDialogLoginCta] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Weather forecast options
  const [weatherDisplayMode, setWeatherDisplayMode] = useState('temperature');
  const [weatherStep, setWeatherStep] = useState(0);

  const handleWeatherStepChange = (newTimeStep) => {
    setWeatherStep(newTimeStep);
  };


  // This state variable needs to be accessible inside an event listener
  const [movingRoutePoint, _setMovingRoutePoint] = useState({});
  const movingRoutePointRef = useRef(movingRoutePoint);
  const setMovingRoutePoint = (data) => {
    movingRoutePointRef.current = data;
    _setMovingRoutePoint(data);
  };

  const [tempPointGeoJSON, setTempPointGeoJSON] = useState(null);
  const [harborsGeoJSON, setHarborsGeoJSON] = useState({});
  const [crowdPoisGeoJSON, setCrowdPoisGeoJSON] = useState({});
  const [septicGeoJSON, setSepticGeoJSON] = useState(undefined);

  var initialLng = 24.92951965287031;
  var initialLat = 60.14400965582416;
  var initialZoom = 12;
  
  if (paramLng & paramLat) {
    initialLng = paramLng;
    initialLat = paramLat;
    initialZoom = 14;
  }
  else if (localStorage.getItem('mapLng') && localStorage.getItem('mapLat')) {
    initialLng = localStorage.getItem('mapLng');
    initialLat = localStorage.getItem('mapLat');
    initialZoom = localStorage.getItem('mapZoom');
  }

  const [mapViewState, setMapViewState] = useState({
    longitude: initialLng,
    latitude: initialLat,
    zoom: initialZoom,
  });


  useEffect(() => {
    if (user.auth) {
//      console.log('Stored RP ID', localStorage.getItem('activeRoutePlanId'));
      if (!localStorage.getItem('activeRoutePlanId') && !paramLng) positionMapToCurrentLocation();
    }

    // Set timer for updating weather data
    const weatherInterval = setInterval(() => {
      dispatch(loadWeatherStations({}));
    }, 300000); // 5 minutes

    const aisInterval = setInterval(() => {
      fetchAisTargets();
    }, 60000); // 1 minute
    
    // const santaInterval = setInterval(() => {
    //   santaPosition();
    // }, 3000);
  
    return () => {
      clearInterval(weatherInterval);
      clearInterval(aisInterval);
      // clearInterval(santaInterval);
    }
  }, []);


  useEffect(() => {
    if (auth.loginResult === 'logged-out') {
      window.location.href = '/';
    }
  }, [auth.loginResult])

  useEffect(() => {
    if (props.mapCenter)
      flyTo({ lng: props.mapCenter[0], lat: props.mapCenter[1] });
  }, [props.mapCenter])

  useEffect(() => {
    if (user.mapFlyTo && user.mapFlyTo.length === 2)
      flyTo({ lng: user.mapFlyTo[0], lat: user.mapFlyTo[1] });
  }, [user.mapFlyTo])

  useEffect(() => {
    _setCursorType(reduxAsyncStatus !== 'idle' ? 'wait' : 'auto');
  }, [routeLoading])

  useEffect(() => {
    if (apiErrorCode != '') {
      displayDialog(apiErrorCode);
      dispatch(clearApiErrorCode({}));
    }
  }, [apiErrorCode])

  useEffect(() => {
    if (layerSettings.ais === true) {
      debouncedFetchAisTargets();
    }
    else {
    }
  }, [layerSettings])


  /**
   * Set map center when starting to add crowd poi
   */
  useEffect(() => {
    if (addCrowdPoiActive !== true) return;
    
    const { current: map } = mapRef;
    if (!map.getCenter) return false;
    const mc = map.getCenter();
    setMapCenter([mc.lng, mc.lat]);
  }, [addCrowdPoiActive])


  // Center map whenever a route plan is loaded
  useEffect(() => {
    const { current: map } = mapRef;

    if (routePlanLoaded === 'yes') {
      setRouteGeoJSON({ type: 'FeatureCollection', features: [] });

      if (!waypoints || !Array.isArray(waypoints)) {
        console.error('empty waypoints');
        return false;
      }

      const passages = waypoints.map(a => a.passage);

      var lngs = [];
      var lats = [];

      for (const p of passages) {
        if (!p) continue;
        for (const f of p) {
          const { geometry } = f;
          //console.log(geometry)
          if (geometry) {
            const tlngs = geometry.coordinates.map(l => l[0]);
            const tlats = geometry.coordinates.map(l => l[1]);
            lats = lats.concat(tlats);
            lngs = lngs.concat(tlngs);
          }
        }
      }

      const minLat = Math.min(...lats) - 0.05;
      const maxLat = Math.max(...lats) + 0.05;
      const minLng = Math.min(...lngs) - 0.05;
      const maxLng = Math.max(...lngs) + 0.05;

      if (minLng < 180 && minLng > -180 && 
          maxLng < 180 && maxLng > -180 && 
          minLat < 90 && minLat > -90 && 
          maxLat < 90 && maxLat > -90) {
        map.fitBounds([[minLng, minLat], [maxLng, maxLat]]);
      }

      dispatch(completeRoutePlanLoaded());
    }
  }, [routePlanLoaded]);


  /* Santa stuff */
  // const calculateBearing = (point1, point2) => {
  //   var point = turfPoint(point1);
  //   var prevPoint = turfPoint(point2);
  //   var bearing; 

  //   bearing = turfBearing(point, prevPoint);

  //   return (bearing + 360) % 360;
  // };

  // function calculateEndPoint(startLat, startLng, angleDeg, distance) {
  //   const R = 6371; // Radius of the Earth in km
  //   const bearing = angleDeg * Math.PI / 180; // Convert bearing to radians
  //   const d = distance / R; // Convert distance to angular distance in radians

  //   const lat1 = startLat * Math.PI / 180; // Convert latitude to radians
  //   const lng1 = startLng * Math.PI / 180; // Convert longitude to radians

  //   const lat2 = Math.asin(Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(bearing));
  //   const lng2 = lng1 + Math.atan2(Math.sin(bearing) * Math.sin(d) * Math.cos(lat1), Math.cos(d) - Math.sin(lat1) * Math.sin(lat2));

  //   return [lng2 * 180 / Math.PI, lat2 * 180 / Math.PI]; // Convert back to degrees
  // }

  // const santaPosition = () => {
  //   if (!santaRoute) return;

  //   const startDate = new Date('2023-12-05T22:00:00');
  //   const targetDate = new Date('2023-12-24T16:00:00');
  //   const currentDate = new Date();

  //   const secondsUntilJoulu = targetDate - currentDate;
  //   const totalEndurance = targetDate - startDate;

  //   const santaSog = santaDistance / (totalEndurance / 1000 / 3600);

  //   var fraction = (totalEndurance - secondsUntilJoulu) / totalEndurance;
  //   var prevFraction = (totalEndurance - secondsUntilJoulu - 10) / totalEndurance;

  //   if (fraction > 1) fraction = 1;
  //   if (prevFraction > 1) prevFraction = 0.99;

  //   const coords = along(santaRoute, santaDistance * fraction).geometry.coordinates;
  //   const prevCoords = along(santaRoute, santaDistance * prevFraction).geometry.coordinates;
  //   const bearing = calculateBearing(prevCoords, coords);
  //   const vectorCoords = calculateEndPoint(coords[1], coords[0], bearing, 1);

  //   var displayBearing = bearing;

  //   var image = 'santaup';

  //   if (bearing > 180 && bearing < 360) {
  //     displayBearing = (displayBearing - 180) % 360;
  //     var image = 'santadown';
  //   }

  //   const santaGeoJSON = {
  //     type: 'FeatureCollection',
  //     features: [
  //       {
  //         type: 'Feature',
  //         properties: {
  //           sog: round(santaSog / 1.852, 1),
  //           displayBearing: displayBearing,
  //           image: image,
  //         },
  //         geometry: {
  //           type: 'Point',
  //           coordinates: coords,
  //         }
  //       },
  //       {
  //         type: 'Feature',
  //         geometry: {
  //             type: 'LineString',
  //             coordinates: [coords, vectorCoords]
  //         }
  //       }
  //     ],  
  //   };

  //   setSantaGeoJSON(santaGeoJSON);

  //   return;
  // }

  const fetchAisTargets = () => {
    const {current: map} = mapRef;
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const latMin = sw.lat;
    const lngMin = sw.lng;
    const latMax = ne.lat;
    const lngMax = ne.lng;

    dispatch(loadAisTargets({ lngMin, latMin, lngMax, latMax }));
  }

  const positionMapToCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation not supported by the browser.')
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.longitude && position.coords.latitude) flyTo({ lng: position.coords.longitude, lat: position.coords.latitude });
      }, () => {
        console.log('Cannot determine geolocation');
      });
    }
  }

  // INIT APP
  useEffect(() => {
    if (reduxHarbors.allHarbors.length < 1) {
      fetchHarbors({ fetchAll: false });
    }
    if (crowdPois.allCrowdPois.length < 1) {
      fetchCrowdPois({ selectPoiId: null });
    }
    dispatch(loadWeatherStations({}));
    // santaPosition();
    fetchSepticStations();

    // tbRef.current = new Threebox(
    //   mapRef,
    //   mapRef.getCanvas().getContext('webgl'),
    //   { defaultLights: true }
    // );

    //loadModel(selectedModel);
  }, []);


  // Generate RouteGeoJSON whenever waypoints are updated
  useEffect(() => {
    const newRouteGeoJSON = generateRouteGeoJSON(waypoints);
    setRouteGeoJSON(newRouteGeoJSON);
  }, [waypoints])

  // Regenerate harborsGeoJson once harbors are updated
  useEffect(() => {
    if (!reduxHarbors.displayedHarbors) return;

    var newHarborsGeoJSON = {
      type: 'FeatureCollection',
      features: [],
    };

    reduxHarbors.displayedHarbors.forEach((harbor) => {
      let feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [harbor.lngLat[0], harbor.lngLat[1]],
        },
        properties: {
          id: harbor._id,
          name: harbor.name,
          category: harbor.category,
        }
      }

      newHarborsGeoJSON.features.push(feature);
    });

    setHarborsGeoJSON(newHarborsGeoJSON);

  }, [reduxHarbors]);

    // Regenerate crowdPoisGeoJSON once crowdpois are updated
    useEffect(() => {
      if (!crowdPois.displayedCrowdPois) return;
  
      var newCrowdPoisGeoJSON = {
        type: 'FeatureCollection',
        features: [],
      };
  
      crowdPois.displayedCrowdPois.forEach((crowdPoi) => {
        let feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [crowdPoi.lngLat[0], crowdPoi.lngLat[1]],
          },
          properties: {
            id: crowdPoi.publicId,
            name: crowdPoi.name,
          }
        }

        newCrowdPoisGeoJSON.features.push(feature);
      });
  
      setCrowdPoisGeoJSON(newCrowdPoisGeoJSON);
  
    }, [crowdPois]);

  // Update weather stations geo json whenver weather stations are updated
  useEffect(() => {
    setWeatherStationsGeoJSON(generateWeatherStationsGeoJSON(weatherStations));
  }, [weatherStations]);


  useEffect(() => {
    if (reduxHarbors.searchResultHarbor && reduxHarbors.searchResultHarbor.lngLat) {
      const [lng, lat] = reduxHarbors.searchResultHarbor.lngLat;
      flyTo({ lng, lat })
    }
  }, [reduxHarbors.searchResultHarbor]);

  // Load previously active route plan
  useEffect(() => {
    if (!auth.user) {
      return false;
    }

    const routePlanId = localStorage.getItem('activeRoutePlanId');
    if (routePlanId) {
      dispatch(loadRoutePlan( { routePlanId } ));
      // dispatch(setVesselDraft(localStorage.getItem('vesselSpeed') ? localStorage.getItem('vesselSpeed') : 6.0));
    }
    else {
      dispatch(setVesselDraft(localStorage.getItem('vesselDraft') ? localStorage.getItem('vesselDraft') : 1.6));
      // dispatch(setVesselDraft(localStorage.getItem('vesselSpeed') ? localStorage.getItem('vesselSpeed') : 6.0));
    }
  }, [auth.user]);

  const fetchHarbors = ({ fetchAll }) => {
    var path;

    if (fetchAll) {
      path = '/harbors?all=true';
    }
    else {
      path = '/harbors';
    }
    verttiApi.get(path)
      .then((res) => {
        const { harbors: receivedHarbors } = res.data;
        dispatch(setHarbors({ allHarbors: receivedHarbors, displayedHarbors: receivedHarbors, searchResultHarbor: {} }));
        //setHarbors(receivedHarbors);
      })
      .catch((err) => {
        console.error('Fetching harbors failed', err);
        enqueueSnackbar('Satamatietojen haku epäonnistui.', { variant: 'error' });
      })
  }

  /**
   * 
   * Loads 3D model of a harbor
   * 
   */
  // const loadModel = (url) => {
  //   if (!tbRef.current) return;

  //   // Remove any existing model
  //   tbRef.current.remove('model-id');

  //   const loader = new THREE.GLTFLoader();
  //   loader.load(url, (gltf) => {
  //     const model = gltf.scene;
  //     model.scale.set(10, 10, 10); // Adjust scale as needed
  //     tbRef.current.add(model, 'model-id');
  //   });
  // };


  /**
   * Debounced store map center to local storage
   */
  const saveMapCenter = () => {
    if (mapRef.current) {
      const mc = mapRef.current.getCenter();
      localStorage.setItem('mapLng', mc.lng);
      localStorage.setItem('mapLat', mc.lat);
      localStorage.setItem('mapZoom', mapRef.current.getZoom());
    }
  };

  const debouncedSaveMapCenter = useCallback(
    debounce(() => {
      saveMapCenter();
    }, 2000),
    [] // Dependencies array
  );

  const debouncedFetchAisTargets = useCallback(
    debounce(() => {
      fetchAisTargets();
    }, 1000),
    [] // Dependencies array
  );


  /**
   * If selectPoi is set, it will be selected after fetch is complete and dialog opened
   */
  const fetchCrowdPois = ({ selectPoiId }) => {
    var path;

    path = '/crowdpois';

    verttiApi.get(path)
      .then((res) => {
        const { crowdPois: receivedCrowdPois } = res.data;  

        dispatch(setCrowdPois({ allCrowdPois: receivedCrowdPois, displayedCrowdPois: receivedCrowdPois, searchResultCrowdPoi: {} }));

        if (selectPoiId) {
          const selectedPoi = receivedCrowdPois.filter(p => p.publicId === selectPoiId)[0];
          setSelectedCrowdPoi(selectedPoi);
          setViewPoiDialogOpen(true);
        }
      })
      .catch((err) => {
        console.error('Fetching crowd pois failed', err);
        enqueueSnackbar('Veneilykohteiden haku epäonnistui.', { variant: 'error' });
      })
  }

  const fetchSepticStations = () => {
    verttiApi.get('/septic')
      .then((res) => {
        const { septic } = res.data;
        var newSepticGeoJSON = {type: "FeatureCollection", features:[]};
        septic.features.forEach((s, i, arr) => {
          newSepticGeoJSON.features.push({
            type: "Feature",
            properties: s.properties,
            geometry: {
              coordinates: [s.geometry.coordinates[1], s.geometry.coordinates[0]],
              type: "Point",
            }
          });
        })
        setSepticGeoJSON(newSepticGeoJSON);
      })
      .catch((err) => {
        console.error('Fetching septic stations failed', err);
        //enqueueSnackbar('Septiasematietojen haku ei onnistunut.', { variant: 'error' });
      })
  }

  
  const handleCrowdPoiAdded = ({ crowdPoiPublicId }) => {
    fetchCrowdPois({ selectPoiId: crowdPoiPublicId });
    handleAddPoiDialogClose();
  }
  
  const initMap = () => {
    const { current: map } = mapRef;

    fetchAisTargets();

    const images = [
      {name: 'punainenviitta', file: punainenviitta},
      {name: 'vihreaviitta', file: vihreaviitta},
      {name: 'etelaviitta', file: etelaviitta},
      {name: 'pohjoisviitta', file: pohjoisviitta},
      {name: 'itaviitta', file: itaviitta},
      {name: 'lansiviitta', file: lansiviitta},
      {name: 'erikoismerkkiviitta', file: erikoismerkkiviitta},
      {name: 'kariviitta', file: kariviitta},
      {name: 'turvavesiviitta', file: turvavesiviitta},
      {name: 'guest-harbor', file: guestHarborImage},
      {name: 'service-harbor', file: serviceHarborImage},
      {name: 'home-harbor', file: homeHarborImage},
      {name: 'poi', file: poiImage},
      {name: 'septic', file: septicImage},
      {name: 'loisto', file: loistoImage},
      {name: 'speedLimit', file: speedLimitImage},
      {name: 'freeHeight', file: freeHeightImage},
      {name: 'anchoringRestrictionArea', file: anchoringRestrictionAreaImage},
      {name: 'vessel', file: vesselImage},
      {name: 'cargoVessel', file: cargoVesselImage},
      {name: 'cargoStationaryVessel', file: cargoStationaryVesselImage},
      {name: 'tankerVessel', file: tankerVesselImage},
      {name: 'tankerStationaryVessel', file: tankerStationaryVesselImage},
      {name: 'passengerVessel', file: passengerVesselImage},
      {name: 'passengerStationaryVessel', file: passengerStationaryVesselImage},
      {name: 'highspeedVessel', file: highspeedVesselImage},
      {name: 'highspeedStationaryVessel', file: highspeedStationaryVesselImage},
      {name: 'tugVessel', file: tugVesselImage},
      {name: 'tugStationaryVessel', file: tugStationaryVesselImage},
      {name: 'fishingVessel', file: fishingVesselImage},
      {name: 'fishingStationaryVessel', file: fishingStationaryVesselImage},
      {name: 'pleasureVessel', file: pleasureVesselImage},
      {name: 'pleasureStationaryVessel', file: pleasureStationaryVesselImage},
      {name: 'unspecifiedVessel', file: unspecifiedVesselImage},
      {name: 'unspecifiedStationaryVessel', file: unspecifiedStationaryVesselImage},
      {name: 'navaidVessel', file: navaidVesselImage},
      // {name: 'santa', file: santaImage},
      // {name: 'santaup', file: santaUpImage},
      // {name: 'santadown', file: santaDownImage},
      {name: 'kiviVedenalainen', file: kiviVedenalainen},
      {name: 'kiviVedenpaallinen', file: kiviVedenpaallinen},
      {name: 'kiviVedenrajassa', file: kiviVedenrajassa},
      {name: 'unchartedPattern', file: unchartedPattern},
      {name: 'windBarb5', file: windBarb5},
      {name: 'windBarb10', file: windBarb10},
      {name: 'windBarb15', file: windBarb15},
      {name: 'windBarb20', file: windBarb20},
      {name: 'windBarb25', file: windBarb25},
      {name: 'windBarb30', file: windBarb30},
      {name: 'windBarb35', file: windBarb35},
      {name: 'windBarb40', file: windBarb40},
      {name: 'windBarb45', file: windBarb45},
      {name: 'windBarb50', file: windBarb50},
      {name: 'windBarb55', file: windBarb55},
      {name: 'windBarb60', file: windBarb60},
      {name: 'windBarb65', file: windBarb65},
      {name: 'windBarb70', file: windBarb70},
      {name: 'windBarb75', file: windBarb75},
      {name: 'windBarb80', file: windBarb80},
      {name: 'windBarb85', file: windBarb85},
      {name: 'windBarb90', file: windBarb90},
      {name: 'windBarb95', file: windBarb95},
      {name: 'windBarb100', file: windBarb100},
      {name: 'windBarbCalm', file: windBarbCalm},
      {name: 'windBarbNan', file: windBarbNan},
      {name: 'windRing', file: windRing},
      {name: 'otherRing', file: otherRing},
    ];

  
    Promise.all(
      images.map(img => 
        map.loadImage(img.file)
          .then(res => {
            map.addImage(img.name, res.data);
          })
          .catch(err => {
            // Handle any errors that occur during loadImage
            console.error(`Error loading image ${img.name}:`, err);
            // You might want to reject this promise explicitly if an error here should stop the entire process
            // Otherwise, this error will be caught in the final .catch() block but will not stop other images from loading
          })
      )
    )
    .then(() => {
      console.log('Images loaded');
      setImagesLoading(false);
    })
    .catch((err) => {
      // This will catch any errors that weren't caught in the individual loadImage promises
      console.error('Error loading images:', err);
    });


    // Promise.all(
    //   images.map(img => new Promise((resolve, reject) => {
    //     console.log(map.loadImage);
    //       map.loadImage(img.file, function (error, res) {
    //           map.addImage(img.name, res)
    //           resolve();
    //       })
    //   }))
    // )
    // .then(() => {
    //   console.log('Images loaded');
    //   setImagesLoading(false);
    // })
    // .catch((err) => {
    //   console.error(err);
    // })
  }

  const displayDialog = dialogType => {
    const { formatMessage } = props.intl;
    var title, body, buttonLabel;
    var loginCta = false;

    switch (dialogType) {
      case 'login_required':
        title = formatMessage({ id: 'map.dialog.loginRequired.title' });
        body = formatMessage({ id: 'map.dialog.loginRequired.description' });
        loginCta = true;
        buttonLabel = formatMessage({ id: 'map.dialog.loginRequired.button' });
        break;      
      case 'route_not_found':
        title = formatMessage({ id: 'map.dialog.routeNotFound.title' });
        body = formatMessage({ id: 'map.dialog.routeNotFound.description' });
        buttonLabel = formatMessage({ id: 'map.dialog.routeNotFound.button' });
        break;
      case 'autorouting_source_too_far':
        title = formatMessage({ id: 'map.dialog.routeNotFound.title' });
        body = formatMessage({ id: 'map.dialog.routeNotFoundSourceTooFar.description' });
        buttonLabel = formatMessage({ id: 'map.dialog.routeNotFound.button' });
        break;  
      case 'autorouting_destination_too_far':
        title = formatMessage({ id: 'map.dialog.routeNotFound.title' });
        body = formatMessage({ id: 'map.dialog.routeNotFoundDestinationTooFar.description' });
        buttonLabel = formatMessage({ id: 'map.dialog.routeNotFound.button' });
        break;    
      default: 
        title = formatMessage({ id: 'map.dialog.unknownError.title' });
        body = formatMessage({ id: 'map.dialog.unknownError.description' });
        buttonLabel = formatMessage({ id: 'map.dialog.unknownError.button' });
        break;
    }

    if (title) {
      setDialogTitle(title);
      setDialogBody(body);
      setDialogButtonLabel(buttonLabel);
      setDialogLoginCta(loginCta);
      setDialogOpen(true);
    }
  }


  const flyTo = ({ lat, lng }) => {
    const { current: map } = mapRef;
    map.flyTo({
      center: [lng, lat],
      zoom: 14,
      essential: false
    });
  };


  const removePoint = (pointId) => {
    dispatch(removeWaypoint(pointId));
    setDisplayRoutingMenu(false);
  }


  const relocatePoint = (pointId, newCoordinates) => {
    dispatch(relocateWaypoint({ waypointId: pointId, lngLat: newCoordinates }))
  }


  const handleRoutePointClick = (feature, e) => {
    const { coordinates } = feature.geometry;
    setSelectedRoutePoint(feature);
    setRoutingMenuPosition({ lng: coordinates[0], lat: coordinates[1] });
    setDisplayRoutingMenu(true);
  }

  const handleHarborClick = (harbor, e) => {
    setSelectedHarbor(harbor);
    displayHarborInformation();
    // setHarborMenuPosition({ lng: harbor.lngLat[0], lat: harbor.lngLat[1] });
    // setDisplayHarborMenu(true);
  }

  const handleCrowdPoiClick = (crowdPoi, e) => {
    setSelectedCrowdPoi(crowdPoi);
    displayCrowdPoiInformation();
  }
  
  const handleWeatherStationClick = (station, e) => {
    setSelectedWeatherStation(station);
    setWeatherModalOpen(true);
  }

  const newRoutePoint = ({ lng, lat, description, poiId }) => {
    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }

    dispatch(addWaypoint({
      lat, 
      lng, 
      poiId: poiId, 
      name: description
    }));
  }

  const handleMouseDown = (event) => {
    const { lngLat } = event;
    const { current: map } = mapRef;

    // If adding a new route point, but not clicked on water, ignore click
    let features = map.queryRenderedFeatures(event.point, { layers: ['water'] });
    if (features.length === 0) {
      return false;
    }

    if (routingMode === 'off') return false;

    pressTimer.current = setTimeout(() => startLongPress(lngLat), 100);
  };

  const handleMouseUp = () => {
    clearPress();
  };

  const handleTouchStart = (event) => {
    const { lngLat } = event;
    const { current: map } = mapRef;

    // If adding a new route point, but not clicked on water, ignore click
    let features = map.queryRenderedFeatures(event.point, { layers: ['water'] });
    if (features.length === 0) {
      return false;
    }

    if (routingMode === 'off') return false;

    if (event.points.length === 1) { // Only consider single touch
      const { lngLat } = event;
      pressTimer.current = setTimeout(() => startLongPress(lngLat), 100);
    }
  };

  const handleTouchEnd = () => {
    clearPress();
  };

  const handleTouchCancel = () => {
    clearPress();
  };

  const handleMapClick = e => {
    if (reduxAsyncStatus !== 'idle' || imagesLoading || addCrowdPoiActive) return false;
    const { current: map } = mapRef;
    var features = [];

    // More virier check, if route popup menu is open and if, then close it
    if (displayRoutingMenu === true) {
      setDisplayRoutingMenu(false);
      return;
    }
    if (displayHarborMenu === true) {
      setDisplayHarborMenu(false);
      return;
    }

    // Then check, if route point is being moved
    const { id: movingRoutePointId } = movingRoutePointRef.current;

    // Do not continue as route point is being moved
    if (movingRoutePointId) {
      setTempPointGeoJSON(null);
      setMovingRoutePoint({});
      return;
    }

    // Then check, if clicked on top of route marker
    features = map.queryRenderedFeatures(e.point, { layers: ['routePoints'] });
    if (features.length > 0) {
      handleRoutePointClick(features[0], e);
      return;
    }

    // features = map.queryRenderedFeatures(e.point, { layers: ['santaTarget'] });
    // if (features.length > 0) {
    //   setSantaModalOpen(true);
    //   return;
    // }

    // Then check, if clicked on harbor marker
    if (layerSettings.harbor) {
      features = map.queryRenderedFeatures(e.point, { layers: ['harborMarkers'] });
      if (features.length > 0) {
        const [harborToDisplay] = reduxHarbors.allHarbors.filter(harbor => harbor._id === features[0].properties.id);
        handleHarborClick(harborToDisplay);
        return;
      }
    }

    // Then check, if clicked on crowd poi marker
    if (layerSettings.crowdPoi) {
      features = map.queryRenderedFeatures(e.point, { layers: ['crowdPois'] });
      if (features.length > 0) {
        const [crowdPoiToDisplay] = crowdPois.allCrowdPois.filter(crowdPoi => crowdPoi.publicId === features[0].properties.id);
        handleCrowdPoiClick(crowdPoiToDisplay);
        return;
      }
    }

    // Then check, if clicked on septic marker
    if (layerSettings.septic) {
      features = map.queryRenderedFeatures(e.point, { layers: ['septicMarkers'] });
      if (features.length > 0) {
        setSelectedSepticStation(features[0]);
        setSepticModalOpen(true);
        return;
      }    
    }

    // Then check, if clicked on weather station marker
    if (layerSettings.weather) {
      features = map.queryRenderedFeatures(e.point, { layers: ['weatherStations'] });
      if (features.length > 0) {
        const [weatherStationToDisplay] = weatherStations.filter(e => e.publicId === features[0].properties.id);
        handleWeatherStationClick(weatherStationToDisplay);
        return;
      }
    }

    // // If adding a new route point, but not clicked on water, ignore click
    // features = map.queryRenderedFeatures(e.point, { layers: ['water'] });
    // if (features.length === 0) {
    //   return false;
    // }

    // if (routingMode === 'off') return false;

    // newRoutePoint({
    //   lng: e.lngLat.lng,
    //   lat: e.lngLat.lat,
    //   description: undefined,
    // });
  }

  /**
   * Map panning event
   */
  const onMove = e => {
    if (imagesLoading) return false;
    setMapViewState(e.viewState);

    debouncedSaveMapCenter();
    if (layerSettings.ais) debouncedFetchAisTargets();

    // Set marker position to center of the map
    if (addCrowdPoiActive) {
      const { current: map } = mapRef;
      const mc = map.getCenter();
      setMapCenter([mc.lng, mc.lat]);
    }
  }


  // Dragging routePoint where mouse was put down
  const onRoutePointMove = (e) => {
    const coords = e.lngLat;
    // Callback can't access state directly
    const { coordinates } = movingRoutePointRef.current;

    setCursorType('grabbing');

    //console.log(movingRoutePoint);

    var features = [];

    features[0] = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [coords.lng, coords.lat],
      },
    };

    if (coordinates) {
      features[1] = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [coords.lng, coords.lat],
            [coordinates[0], coordinates[1]]
          ]
        }
      };
    }

    setTempPointGeoJSON({ 'type': 'FeatureCollection', 'features': features });
  }


  // This fires when moving a routepoint is completed
  const onRoutePointMoveEnd = e => {
    const { current: map } = mapRef;

    const coords = e.lngLat;
    const { id: movedRoutePointId } = movingRoutePointRef.current;

    relocatePoint(movedRoutePointId, coords);

    // Unbind mouse/touch events
    map.off('mousemove', onRoutePointMove);
    //map.off('touchmove', onMove);
  }

  const handleMoveRoutePoint = (feature, e) => {
    const { current: map } = mapRef;

    const { coordinates } = feature.geometry;
    setDisplayRoutingMenu(false);
    setMovingRoutePoint({ id: feature.properties.id, coordinates });

    setCursorType('grab');

    map.on('mousemove', onRoutePointMove);
    map.once('mouseup', onRoutePointMoveEnd);
  }


  const onMouseMove = e => {
    if (imagesLoading) return false;
    const { current: map } = mapRef;
    const width = 10;
    const height = 20;
    var features = [];

    // Case mouse over route point
    features = map.queryRenderedFeatures([
      [e.point.x - width / 2, e.point.y - height / 2],
      [e.point.x + width / 2, e.point.y + height / 2]
    ], { layers: ['routePoints'] });
    if (features.length) {
      const pointId = features[0].id;

      if (pointId) {
        map.setFeatureState(
          {
            source: 'route',
            id: pointId
          },
          {
            hover: true
          }
        );
      }

      setHoverPointId(pointId);

      setCursorType('pointer');
      return true;
    }
    else {
      if (hoverPointId) {
        map.setFeatureState(
          {
            source: 'route',
            id: hoverPointId
          },
          {
            hover: false,
          }
        );
      }
    }

    var layers = [];
    if (layerSettings.harbor) layers.push('harborMarkers');
    if (layerSettings.crowdPoi) layers.push('crowdPois');
    if (layerSettings.septic) layers.push('septicMarkers');
    if (layerSettings.weather) layers.push('weatherStations');
    //layers.push('santaTarget');
    // Case mouse over POI or weather station
    features = map.queryRenderedFeatures(e.point, { layers });

    if (features.length) {
      setCursorType('pointer');
      return true;
    }

    // Case mouse over water
    features = map.queryRenderedFeatures(e.point, { layers: ['water'] });
    if (features.length && routingMode !== 'off') {
      setCursorType('crosshair');
      return true;
    }

    setCursorType('auto');
  }

  //const onMouseLeave = useCallback(() => setCursorType('auto'), []);

//   const setAutomaticRouting = () => {
//     if (!auth.user) {
//       displayDialog('login_required');
//       return false;
//     }

//     dispatch(setRoutingMode('auto'));
//     enqueueSnackbar('Automaattireititys käytössä', { variant: 'info' });
// }

//   const setManualRouting = () => {
//     if (!auth.user) {
//       displayDialog('login_required');
//       return false;
//     }

//     dispatch(setRoutingMode('manual'));
//     enqueueSnackbar('Manuaalireititys käytössä', { variant: 'info' });
// }

  const displayHarborInformation = () => {
    analytics.track('harbor.viewed', {
      harborId: selectedHarbor._id,
      harborName: selectedHarbor.name,
    })
    setHarborModalOpen(true);
  }

  const displayCrowdPoiInformation = () => {
    analytics.track('crowdPoi.viewed', {
      harborId: selectedCrowdPoi.publicId,
      harborName: selectedCrowdPoi.name,
    })
    setViewPoiDialogOpen(true);
  }

  const displayHarborManager = () => {
    setHarborModalOpen(false);
    setHarborManagerModalOpen(true);
  }

  const addHarborToRoute = () => {
    newRoutePoint({
      lng: selectedHarbor.lngLat[0],
      lat: selectedHarbor.lngLat[1],
      description: selectedHarbor.name,
      poiId: selectedHarbor._id,
    });
  }

  /**
   * Function adds a new crowd poi
   */
  const handleAddPoiClick = () => {
    const { current: map } = mapRef;
    
    if (map.getZoom() < 14) {
      enqueueSnackbar('Tarkasta sijainti vielä lähemmäksi zoomattuna', { variant: 'warning' });
      map.flyTo({
        center: [mapCenter[0], mapCenter[1]],
        zoom: 16,
        essential: true
      });
      return;
    }

    setAddPoiDialogOpen(true);

  }

  return (
    <>
      <div className='map-wrap'>

        {/* Menu */}
        <Box display='flex' p={1} sx={{position: 'absolute', top: '0px', left:'0px', zIndex: 1001}}>
          {auth.user && <MapToolbar />}
          {!auth.user && <Button variant='outlined' onClick={() => navigate('/login')}>Kirjaudu sisään</Button>}
          {/* {auth.user && auth.user.role === 'admin' && (
            <Button onClick={() => fetchHarbors({ fetchAll: true })}>Hae kaikki</Button>
          )} */}
        </Box>
        {/* End of Menu */}

        <Map
          onLoad={() => initMap()}
          mapLib={maplibregl}
          ref={mapRef}
          {...mapViewState}
          style={{ width: '100%', height: '100%' }}
          cursor={cursorType}
          onMouseMove={e => onMouseMove(e)}
          mapStyle={VerttiMapStyle}
          onMove={e => onMove(e)}
          onClick={handleMapClick}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchCancel}
          attributionControl={false}
          maxPitch={60}
          // terrain={user.threeDimensionalMapEnabled && {"source": "terrain","exaggeration": 1,}}
          // light={{
          //   "anchor": "viewport",
          //   "color": "white",
          //   "intensity": 0.5
          // }}
        >
          <NavigationControl />
          <GeolocateControl />
          {/* <FullscreenControl /> */}
          <AttributionControl customAttribution={['© Väylävirasto', '© Traficom', '© Maanmittauslaitos', 'Ei saa käyttää navigointiin']} compact={true}/>
          <ScaleControl unit='nautical' maxWidth='250' />

          {addCrowdPoiActive && mapCenter.length > 1 && (
            <Marker longitude={mapCenter[0]} latitude={mapCenter[1]} anchor="bottom" />
          )}

        {circleData.features.length > 0 && (
          <Source id="circle" type="geojson" data={circleData}>
            <Layer
              id="circle"
              type="circle"
              paint={{
                'circle-radius': circleRadius,
                'circle-color': '#FF00FF',
                'circle-opacity': 0.5
              }}
            />
          </Source>
        )}

          {/* {santaGeoJSON && !imagesLoading && (
            <Source id="santaTarget" type="geojson" data={santaGeoJSON}>
              <Layer {...santaVectorLayer} />
              <Layer {...santaTargetLayer} />
            </Source>
          )} */}

          {aisTargets && !imagesLoading && layerSettings.ais && (
            <Source id="aisTargets" type="geojson" data={aisTargets}>
              <Layer {...aisVectorsLayer} />
              <Layer {...aisTargetsLayer} />
            </Source>
          )}

          {routeGeoJSON && !imagesLoading && (
            <Source id="route" type="geojson" data={routeGeoJSON}>
              <Layer {...routeLineLayer} />
              <Layer {...routePointLayer} />
              <Layer {...routePointLabelLayer} />
            </Source>
          )}

          {/* {user.threeDimensionalMapEnabled && <Layer {...hillShadingLayer} />} */}

          {harborsGeoJSON && !imagesLoading && layerSettings.harbor && (
            <Source id="pois" type="geojson" data={harborsGeoJSON}>
              <Layer {...harborLayer} />
            </Source>
          )}

          {crowdPoisGeoJSON && !imagesLoading && layerSettings.crowdPoi && (
            <Source id="crowdPois" type="geojson" data={crowdPoisGeoJSON}>
              <Layer {...crowdPoiLayer} />
            </Source>
          )}

          {septicGeoJSON && !imagesLoading && layerSettings.septic && (
            <Source id="septic" type="geojson" data={septicGeoJSON}>
              {layerSettings.harbor && <Layer {...septicLayer} beforeId="harborMarkers"/>}
              {!layerSettings.harbor && <Layer {...septicLayer} />}
            </Source>
          )}

          {weatherStationsGeoJSON && !imagesLoading && layerSettings.weather && (
            <Source id="weatherStations" type="geojson" data={weatherStationsGeoJSON}>
              <Layer {...weatherStationsLayer} />
            </Source>
          )}

          {tempPointGeoJSON && !imagesLoading && (
            <Source id="tempLines" type="geojson" data={tempPointGeoJSON}>
              <Layer {...tempPointLayer} />
              <Layer {...tempLineLayer} />
            </Source>
          )}

          {displayReachArea && reachArea && (
            <Source id="reachArea" type="geojson" data={reachArea}>
              <Layer {...reachAreaLayer} />
            </Source>
          )}

          {layerSettings.ice && (
            <Source
              id="ice"
              type="vector"
              tiles={["https://vertiles.fra1.digitaloceanspaces.com/ice/tiles/{z}/{x}/{y}.pbf"]}
            >
              <Layer
                {...iceLayer}
              />
            </Source>
          )}

          {layerSettings.heatMap && (
            <Source
              id="heatmap"
              type="vector"
              tiles={["https://vertiles.fra1.digitaloceanspaces.com/heat/tiles/{z}/{x}/{y}.pbf"]}
            >
              <Layer
                {...heatMapLayer}
              />
            </Source>
          )}

          {1 === 2 && (
            <Source
              id="weather"
              type="vector"
              tiles={["https://vertiles.fra1.digitaloceanspaces.com/wfc8/tiles/{z}/{x}/{y}.pbf"]}
            >
              {weatherDisplayMode === 'temperature' && (
                <Layer
                  {...temperatureLayer}
                  filter={[
                    'all',
                    ['==', ['geometry-type'], 'Polygon'],
                  ]}
                />
              )}
              {weatherDisplayMode === 'wind' && (
                <>
                <Layer
                  {...windBackgroundLayer}
                  filter={[
                    'all',
                    ['==', ['geometry-type'], 'Polygon'],
                  ]}
                />
                <Layer
                  {...windLayer}
                  filter={[
                    'all',
                    ['==', ['geometry-type'], 'Point'],
                  ]}
                />

              </>
              )}
            </Source>
          )}


          {/* <Source
            id="harborOrto"
            type="raster"
            tiles={["https://vertiles.fra1.digitaloceanspaces.com/ortotiles/{z}/{x}/{y}.png"]}
          >
            <Layer
              {...harborOrtoLayer}
            />
          </Source> */}

{/* http://openwms.fmi.fi/geoserver/wms?service=WMS&version=1.3.0&request=GetMap&layers=Radar:suomi_rr12h_eureffin&styles=&bbox=59.7,19.1,70.1,31.7&width=512&height=422&crs=EPSG:4326&format=image/png&
http://openwms.fmi.fi/geoserver/wms?service=WMS&version=1.3.0&request=GetMap&layers=Radar:suomi_rr12h_eureffin&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png */}
            {/* <Source
              id="weatherRadar"
              type="raster"
              tiles={["http://openwms.fmi.fi/geoserver/wms?service=WMS&version=1.3.0&request=GetMap&layers=Radar:suomi_rr12h_eureffin&styles=&crs=EPSG:3857&bbox={bbox-epsg-3857}&width=512&height=512&format=image/png"]}
            >
              <Layer
                {...weatherRadarLayer}
              />
            </Source> */}


          {displayRoutingMenu && routingMenuPosition.lng && routingMenuPosition.lat && (
            <Popup longitude={routingMenuPosition.lng} latitude={routingMenuPosition.lat}
              anchor="bottom"
              closeButton={false}
              onClose={() => setDisplayRoutingMenu(false)}>
              <ButtonGroup>
                <Tooltip title="Siirrä reittipistettä">
                  <IconButton aria-label="move" onClick={() => handleMoveRoutePoint(selectedRoutePoint)}>
                    <ControlCameraIcon />
                  </IconButton>
                </Tooltip>
                {(selectedRoutePoint.properties.pointIndex > 1) && (
                  <React.Fragment>
                    <Tooltip title="Poista reittipiste">
                      <IconButton aria-label="delete" onClick={() => removePoint(selectedRoutePoint.properties.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Jaa edellinen legi">
                      <IconButton aria-label="Split leg" onClick={() => dispatch(splitLeg(selectedRoutePoint.properties.id))}>
                        <AddLocationIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                )}                
                {selectedRoutePoint.properties.poiId && (
                  <>
                    <Tooltip title="Näytä sataman tiedot">
                      <IconButton aria-label="delete" onClick={() => displayHarborInformation()}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </ButtonGroup>
            </Popup>)}

          {/* {displayHarborMenu && harborMenuPosition.lng && harborMenuPosition.lat && (
            <Popup longitude={harborMenuPosition.lng} latitude={harborMenuPosition.lat}
              anchor="bottom"
              closeButton={false}
              onClose={() => setDisplayHarborMenu(false)}>
              <IconButton aria-label="move" onClick={() => addHarborToRoute()}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => displayHarborInformation()}>
                <InfoOutlinedIcon />
              </IconButton>
            </Popup>)} */}


          {/* {pois.map(poi => <PoiMarker key={poi._id} poi={poi} />)} */}
          {/* <MapMenu x={1100} y={1100} /> */}
        </Map>


        {mapRef.current && addCrowdPoiActive && (
          <Box display='flex' sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', zIndex: 1000, pointerEvents: 'none'}}>
              <Box p={5} sx={{
                boxShadow: '0px 0px 0px 2000px rgba(0, 0, 0, 0.4)',
                border: '5px solid white',
                borderRadius: '15%',
                height: '300px',
                width: '300px',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <Box>
                  <Chip color='primary' label='Aseta julkisen veneilykohteen sijainti'/>
                </Box>
                <Box>
                  {mapRef.current.getZoom() < 14 && <Chip color='warning' label='Zoomaa lähemmäksi'/>}
                  {mapRef.current.getZoom() >= 14 && (
                    <Button onClick={() => handleAddPoiClick()} variant='contained' size='small' sx={{pointerEvents: 'auto'}}>
                      Lisää julkinen veneilykohde tähän
                    </Button>
                  )}
                </Box>
              </Box>
          </Box>
        )}

      </div>


      {layerSettings.ice && (
        <IceLegendBox />
      )}

      {/* <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <SpeedDial
          ariaLabel="Reititystila"
          sx={{ position: 'absolute', bottom: 45, right: 16 }}
          icon={routingMode === 'auto' ? <AutoFixHighIcon /> : <ModeIcon />}
        >
          <SpeedDialAction
            key='autorouting'
            icon={<AutoFixHighIcon />}
            tooltipTitle='Automaattireititys'
            onClick={setAutomaticRouting}
          />
          <SpeedDialAction
            key='manualrouting'
            icon={<ModeIcon />}
            tooltipTitle='Käsin reititys'
            onClick={setManualRouting}
          />
          {auth.user && auth.user.role && auth.user.role === 'admin' && (
            <SpeedDialAction
              key='updateharborsadmin'
              icon={<AddLocationIcon />}
              tooltipTitle='Hae kaikki satamat'
              onClick={() => fetchHarbors({ fetchAll: true })}
            />
          )}
        </SpeedDial>
      </Box> */}

      <MapDialog
        title={dialogTitle}
        body={dialogBody}
        buttonLabel={dialogButtonLabel}
        dialogOpen={dialogOpen}
        dialogLoginCta={dialogLoginCta}
        handleDialogClose={handleDialogClose}
      />

      {harborModalOpen && <HarborModal harborModalOpen={harborModalOpen} setHarborModalOpen={setHarborModalOpen} displayHarborManager={displayHarborManager} addHarborToRoute={addHarborToRoute} harborInfo={selectedHarbor} displayDialog={displayDialog} />}
      {selectedSepticStation && septicModalOpen && <SepticModal septicModalOpen={septicModalOpen} setSepticModalOpen={setSepticModalOpen} septicStationInfo={selectedSepticStation} />}
      {harborManagerModalOpen && <HarborManagerModal harborManagerModalOpen={harborManagerModalOpen} setHarborManagerModalOpen={setHarborManagerModalOpen} harborInfo={selectedHarbor} fetchHarbors={fetchHarbors} />}
      {weatherModalOpen && <WeatherModal weatherModalOpen={weatherModalOpen} setWeatherModalOpen={setWeatherModalOpen} weatherStation={selectedWeatherStation} />}

      {/* open={reduxAsyncStatus !== 'idle' || imagesLoading} */}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={reduxAsyncStatus !== 'idle'}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <div style={{ position: 'absolute', top: 20, left: 100, zIndex: 999999, background: 'white', padding: '10px', border: '1px solid #ccc' }}>
          Zoom level: {mapRef.current ? mapRef.current.getZoom() : ''}
        </div> */}

      {/* <div style={{ position: 'absolute', top: 20, left: 100, zIndex: 999999, background: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <WeatherTimeSlider
          min={0}
          max={10}
          value={weatherStep}
          onChange={handleWeatherStepChange}
        />
      </div> */}

      {addPoiDialogOpen && <AddCrowdPoiDialog handleDialogClose={handleAddPoiDialogClose} dialogOpen={addPoiDialogOpen} lngLat={mapCenter} handleCrowdPoiAdded={handleCrowdPoiAdded} />}
      {viewPoiDialogOpen && <ViewCrowdPoiDialog handleDialogClose={handleViewPoiDialogClose} dialogOpen={viewPoiDialogOpen} poi={selectedCrowdPoi} displayDialog={displayDialog} />}
      {/* {santaModalOpen && <SantaModal santaModalOpen={santaModalOpen} setSantaModalOpen={setSantaModalOpen}/>} */}
      {(disclaimerModalOpen) ? <DisclaimerModal disclaimerModalOpen={disclaimerModalOpen} setDisclaimerModalOpen={setDisclaimerModalOpen} startTour={startTour} /> : null}

      <div>
        <Joyride
          steps={tourSteps}
          run={runTour}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          locale={joyrideLocale}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

      </div>

    </>
  );
};

export default injectIntl(withSnackbar(VerttiMap2));