import React from 'react';
import Box from '@mui/material/Box';
import { Typography, TextField } from '@mui/material';

const FormField = ({fieldName, label, formik, multiline}) => {
  return (
    <Box pb={2} key={`ff${fieldName}`}>
      <TextField
          key={fieldName}
          name={fieldName}
          label={label}
          multiline={multiline}
          aria-label={label}
          onChange={formik.handleChange}
          value={formik.values[fieldName]}
          onBlur={formik.handleBlur}
          error={formik.touched[fieldName] && formik.errors[fieldName] ? true : false}
          fullWidth
      />
      {formik.touched[fieldName] && formik.errors[fieldName] && <Typography variant='formError'>{formik.errors[fieldName]}</Typography>}
    </Box>
  )
}

export default FormField;