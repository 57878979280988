import React, { useState } from 'react';
import { Box, Typography, Stack, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const IceLegendBox = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 250,
        right: 0,
        zIndex: 1001,
        bgcolor: 'white',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        display: 'flex',
        flexDirection: 'row', // Ensures the button and content are in a row
        alignItems: 'center', // Vertically center the button and content
        minWidth: '40px', // Minimum width to accommodate the button
        overflow: 'hidden', // Prevents content from overflowing the container's bounds
      }}
    >
      <IconButton
        onClick={toggleMinimize}
        size='small'
        sx={{
          color: 'primary.main', // Adjust color as needed
          zIndex: 2, // Ensure the button is above the sliding content
        }}
      >
        {isMinimized ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
      <Box
        py={2}
        pr={1}
        sx={{
          display: isMinimized ? 'none' : 'flex', // Hide or show content based on state
          flexDirection: 'column', // Stack content vertically
          transition: 'transform 0.3s ease-out', // Smooth transition for sliding effect
          width: '100%', // Ensure content box takes full width when visible
          transform: isMinimized ? 'translateX(-100%)' : 'translateX(0)', // Slide content in/out
        }}
      >
        <Typography>Jääkartan värit</Typography>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#95C8FF'}} mr={1} /><Typography variant='body2'>Avovesi</Typography>
        </Stack>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#FFAFFF'}} mr={1} /><Typography variant='body2'>Uusi jää</Typography>
        </Stack>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#FF64FF'}} mr={1} /><Typography variant='body2'>Ohut tasainen jää</Typography>
        </Stack>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#969696'}} mr={1} /><Typography variant='body2'>Kiintojää</Typography>
        </Stack>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#8CFFA0'}} mr={1} /><Typography variant='body2'>Hyvin harva ajojää</Typography>
        </Stack>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#FFFF00'}} mr={1} /><Typography variant='body2'>Harva ajojää</Typography>
        </Stack>
        <Stack direction='row'>
        <Box sx={{height: 20, width: 20, bgcolor: '#FF7D09'}} mr={1} /><Typography variant='body2'>Tiheä ajojää</Typography>
        </Stack>
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#FF0000'}} mr={1} /><Typography variant='body2'>Hyvin tiheä ajojää</Typography>
        </Stack>  
        <Stack direction='row'>
          <Box sx={{height: 20, width: 20, bgcolor: '#910000'}} mr={1} /><Typography variant='body2'>Yhteenjäätynyt ajojää</Typography>
        </Stack>

      </Box>
    </Box>
  );
};

export default IceLegendBox;