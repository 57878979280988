import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import distance from '@turf/distance';
import autoRoute from './lib/autoRoute';
import reachArea from './lib/reachArea';
import manualLeg from './lib/manualLeg';
import passageMiddlePoint from './lib/passageMiddlePoint';
import verttiApi from '../../api/verttiApi';
import routeTotalDistance from './lib/routeTotalDistance';

export const updateReachable = createAsyncThunk('updateReachable', async ({ distance }, thunkAPI) => {
  const state = thunkAPI.getState();
  const { rejectWithValue } = thunkAPI;

  let reachResult = undefined;

  if (state.newRoutePlan.displayReachArea === true && state.newRoutePlan.reachDistance > 0) {
    const waypoint = state.newRoutePlan.waypoints[state.newRoutePlan.waypoints.length - 1];
    const lng = waypoint.lngLat[0];
    const lat = waypoint.lngLat[1];

    try {
      reachResult = await reachArea({ sourceLat: lat, sourceLng: lng, vesselDraft: state.newRoutePlan.vesselDraft, distance: state.newRoutePlan.reachDistance * 1852 });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
  else return rejectWithValue();

  return {reachArea: reachResult};
});

export const addWaypoint = createAsyncThunk('addWaypoint', async ({ lat, lng, poiId, name }, thunkAPI) => {
  const state = thunkAPI.getState();
  const { rejectWithValue } = thunkAPI;

  const vesselDraft = state.newRoutePlan.vesselDraft ? state.newRoutePlan.vesselDraft : 0;
  // const reachDistance = state.newRoutePlan.reachDistance ? state.newRoutePlan.reachDistance : 20;
  // const displayReachDistance = false;

  var passage = null;
  var distance = 0;

  // Check that the first route point is close enough to waterway, if mode auto
  if (state.newRoutePlan.waypoints.length < 1 && state.newRoutePlan.routingMode === 'auto') {
    try {
      await verttiApi.post('/distancetowaterway', { lng, lat })
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }

  if (state.newRoutePlan.waypoints.length > 0) {
    const prevWaypoint = state.newRoutePlan.waypoints[state.newRoutePlan.waypoints.length - 1];
    const prevLng = prevWaypoint.lngLat[0];
    const prevLat = prevWaypoint.lngLat[1];

    if (state.newRoutePlan.routingMode === 'auto') {
      var result;
      try {
        result = await autoRoute({ sourceLat: prevLat, sourceLng: prevLng, destinationLat: lat, destinationLng: lng, vesselDraft })
      } catch (err) {
        console.error(err);
        return rejectWithValue(err.response.data)
      }

      distance = result.distance;
      passage = result.geoJson;
    }
    else {
      const result = manualLeg({ sourceLat: prevLat, sourceLng: prevLng, destinationLat: lat, destinationLng: lng });
      distance = result.distance;
      passage = result.geoJson;
    }
  }

  if (!name) {
    const result = await verttiApi.get('/nearbyMapNames', { params: {lng, lat}});
    if (result.data && result.data.name) {
      name = result.data.name;
    }
  }

  const waypoint = {
    id: nanoid(),
    name: name,
    lngLat: [lng, lat],
    poiId: poiId ? poiId : null,
    legGroup: null,
    routingMode: state.newRoutePlan.routingMode,
    distance,
    passage: passage
  }

  const totalDistance = routeTotalDistance(state.newRoutePlan.waypoints) + distance;

  let reachResult = undefined;

  if (state.newRoutePlan.displayReachArea === true && state.newRoutePlan.reachDistance > 0) {
    const lng = waypoint.lngLat[0];
    const lat = waypoint.lngLat[1];

    try {
      reachResult = await reachArea({ sourceLat: lat, sourceLng: lng, vesselDraft, distance: state.newRoutePlan.reachDistance * 1852 });
    } catch (err) {
      console.error(err);
    }
  }

  return {waypoint, totalDistance, reachArea: reachResult};
})


export const removeWaypoint = createAsyncThunk('removeWaypoint', async (waypointId, thunkAPI) => {
  const state = thunkAPI.getState();
  const { rejectWithValue } = thunkAPI;
  var newWaypoints = [];

  if (!waypointId) return false;
  
  const wpIndex = state.newRoutePlan.waypoints.map(e => e.id).indexOf(waypointId);

  // If last one, just remove it
  if (wpIndex + 1 === state.newRoutePlan.waypoints.length) {
    newWaypoints = state.newRoutePlan.waypoints.slice(0, -1);

    // Update reachable area
    let reachResult = undefined;

    if (state.newRoutePlan.displayReachArea === true && state.newRoutePlan.reachDistance > 0) {
      const waypoint = newWaypoints[newWaypoints.length - 1];
      const lng = waypoint.lngLat[0];
      const lat = waypoint.lngLat[1];
  
      try {
        reachResult = await reachArea({ sourceLat: lat, sourceLng: lng, vesselDraft: state.newRoutePlan.vesselDraft, distance: state.newRoutePlan.reachDistance * 1852 });
      } catch (err) {
        console.error(err);
      }
    }  

    return {waypoints: newWaypoints, reachArea: reachResult};
  }

  // If first one
  if (wpIndex === 0 && state.newRoutePlan.waypoints.length > 1) {
    newWaypoints = state.newRoutePlan.waypoints.slice(1);
    newWaypoints[0] = {...newWaypoints[0], passage: []};
    return newWaypoints;
  }

  // Handle middle waypoint
  var passage;
  var distance;

  // Copy it instead of referencing
  newWaypoints = state.newRoutePlan.waypoints.slice(0);

  const prevPointLngLat = state.newRoutePlan.waypoints[wpIndex-1].lngLat;
  const nextPointLngLat = state.newRoutePlan.waypoints[wpIndex+1].lngLat;

  if (state.newRoutePlan.waypoints[wpIndex+1].routingMode === 'auto') {
    var result;
    try {
      result = await autoRoute({ sourceLat: prevPointLngLat[1], sourceLng: prevPointLngLat[0], destinationLat: nextPointLngLat[1], destinationLng: nextPointLngLat[0], vesselDraft: state.newRoutePlan.vesselDraft })
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data)
    }

    distance = result.distance;
    passage = result.geoJson;
  }
  else {
    const result = manualLeg({ sourceLat: prevPointLngLat[1], sourceLng: prevPointLngLat[0], destinationLat: nextPointLngLat[1], destinationLng: nextPointLngLat[0] })
    distance = result.distance;
    passage = result.geoJson;
  }

  newWaypoints[wpIndex+1] = {...newWaypoints[wpIndex+1], passage, distance};

  newWaypoints = newWaypoints.filter((w) => w.id !== waypointId);

  return {waypoints: newWaypoints, reachArea: undefined};
  
});


export const splitLeg = createAsyncThunk('splitLeg', async (waypointId, thunkAPI) => {
  const state = thunkAPI.getState();
  const { rejectWithValue } = thunkAPI;
  var newWaypoints = state.newRoutePlan.waypoints.slice(0);

  if (!waypointId) return false;

  const wpIndex = state.newRoutePlan.waypoints.map(e => e.id).indexOf(waypointId);
  if (!wpIndex || !wpIndex === 0) return false;

  const routingMode = newWaypoints[wpIndex].routingMode;

  const midPointLngLat = passageMiddlePoint(newWaypoints[wpIndex].passage, newWaypoints[wpIndex].distance);

  // Add the new waypoint
  var distance = 0;
  var passage = [];
  const prevPointLngLat = newWaypoints[wpIndex-1].lngLat;

  if (routingMode === 'auto') {
    var result;
    try {
      result = await autoRoute({ sourceLat: prevPointLngLat[1], sourceLng: prevPointLngLat[0], destinationLat: midPointLngLat[1], destinationLng: midPointLngLat[0], vesselDraft: state.newRoutePlan.vesselDraft })
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data)
    }

    distance = result.distance;
    passage = result.geoJson;
  }
  else {
    const result = manualLeg({ sourceLat: prevPointLngLat[1], sourceLng: prevPointLngLat[0], destinationLat: midPointLngLat[1], destinationLng: midPointLngLat[0] })
    distance = result.distance;
    passage = result.geoJson;
  }

  const newWaypoint = {
    id: nanoid(),
    name: '',
    lngLat: [midPointLngLat[0], midPointLngLat[1]],
    poiId: null,
    legGroup: null,
    routingMode: state.newRoutePlan.routingMode,
    distance,
    passage: passage
  }

  // Modify current waypoint
  var distance = 0;
  var passage = [];

  if (routingMode === 'auto') {
    var result;
    try {
      result = await autoRoute({ sourceLat: midPointLngLat[1], sourceLng: midPointLngLat[0], destinationLat: newWaypoints[wpIndex].lngLat[1], destinationLng: newWaypoints[wpIndex].lngLat[0], vesselDraft: state.newRoutePlan.vesselDraft })
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data)
    }

    distance = result.distance;
    passage = result.geoJson;
  }
  else {
    const result = manualLeg({ sourceLat: midPointLngLat[1], sourceLng: midPointLngLat[0], destinationLat: newWaypoints[wpIndex].lngLat[1], destinationLng: newWaypoints[wpIndex].lngLat[0] })
    distance = result.distance;
    passage = result.geoJson;
  }

  newWaypoints[wpIndex] = {...newWaypoints[wpIndex], passage, distance};

  newWaypoints.splice(wpIndex, 0, newWaypoint);

  return newWaypoints;
});


export const relocateWaypoint = createAsyncThunk('relocateWaypoint', async ({ waypointId, lngLat }, thunkAPI) => {
  const state = thunkAPI.getState();
  const { rejectWithValue } = thunkAPI;
  
  var newWaypoints = state.newRoutePlan.waypoints.slice(0);

  if (!waypointId || !lngLat) return false;
  
  const wpIndex = state.newRoutePlan.waypoints.map(e => e.id).indexOf(waypointId);

  if (wpIndex < 0) return false;

  var passage = [];
  var distance = 0;

  const prevLngLat = wpIndex > 0 ? state.newRoutePlan.waypoints[wpIndex-1].lngLat : null;
  const nextLngLat = wpIndex < state.newRoutePlan.waypoints.length - 1 ? state.newRoutePlan.waypoints[wpIndex+1].lngLat : null;

  // Modify current point
  if (prevLngLat) {
    if (state.newRoutePlan.waypoints[wpIndex].routingMode === 'auto') {
      var result;
      try {
        result = await autoRoute({ sourceLat: prevLngLat[1], sourceLng: prevLngLat[0], destinationLat: lngLat.lat, destinationLng: lngLat.lng, vesselDraft: state.newRoutePlan.vesselDraft })
      } catch (err) {
        console.error(err);
        return rejectWithValue(err.response.data)
      }

      distance = result.distance;
      passage = result.geoJson;
    }
    else {
      const result = manualLeg({ sourceLat: prevLngLat[1], sourceLng: prevLngLat[0], destinationLat: lngLat.lat, destinationLng: lngLat.lng })
      distance = result.distance;
      passage = result.geoJson;
    }
  }

  var name = '';
  const nameRes = await verttiApi.get('/nearbyMapNames', { params: {lng: lngLat.lng, lat: lngLat.lat }});
  if (nameRes.data && nameRes.data.name) {
    name = nameRes.data.name;
  }

    newWaypoints[wpIndex] = {...newWaypoints[wpIndex], passage, name, distance, lngLat: [lngLat.lng, lngLat.lat]};

  // Modify next point
  var passage = [];
  var distance = 0;

  if (nextLngLat) {
    if (state.newRoutePlan.waypoints[wpIndex+1].routingMode === 'auto') {
      var result;
      try {
        result = await autoRoute({ sourceLat: lngLat.lat, sourceLng: lngLat.lng, destinationLat: nextLngLat[1], destinationLng: nextLngLat[0], vesselDraft: state.newRoutePlan.vesselDraft })
      } catch (err) {
        console.error(err);
        return rejectWithValue(err.response.data)
      }

      distance = result.distance;
      passage = result.geoJson;
    }
    else {
      const result = manualLeg({ sourceLat: lngLat.lat, sourceLng: lngLat.lng, destinationLat: nextLngLat[1], destinationLng: nextLngLat[0] })
      distance = result.distance;
      passage = result.geoJson;
    }

    newWaypoints[wpIndex+1] = {...newWaypoints[wpIndex+1], passage, distance};
  }

  return newWaypoints;
});

export const breakWaypointGroup = createAsyncThunk('breakWaypointGroup', async ({ legGroupId }, thunkAPI) => {
  const state = thunkAPI.getState();

  const newWaypoints = state.newRoutePlan.waypoints.map(e => {
    return {...e, legGroup: e.legGroup === legGroupId ? null : e.legGroup}
  });

  return newWaypoints;
});

export const groupWaypoints = createAsyncThunk('groupWaypoints', async ({ waypointIds }, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!waypointIds || !Array.isArray(waypointIds) || waypointIds.length < 2) {
    throw new Error('Unfit data');
  }

  var newWaypoints = state.newRoutePlan.waypoints.slice(0);
  var legGroupId = null;

  for (var index = 0; index < newWaypoints.length; index++) {
    if (waypointIds.includes(newWaypoints[index].id)) {
      // Check if previous waypoint belonged to a group
      if (!legGroupId) {
        if (index > 0 && newWaypoints[index-1].legGroup) {
          legGroupId = newWaypoints[index-1].legGroup
        }
        else if (index+1 <= newWaypoints.length -1 && newWaypoints[index+1].legGroup) {
          legGroupId = newWaypoints[index+1].legGroup;
        }
        else {
          legGroupId = nanoid();
        }
      }

      newWaypoints[index] = {...newWaypoints[index], legGroup: legGroupId};

    }
  }

  return newWaypoints;
});


export const loadRoutePlan = createAsyncThunk('loadRoutePlan', async ({ routePlanId }, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!routePlanId) return false;
  try {
    var result = await verttiApi.get(`routePlan/${routePlanId}`);

    if (result.status !== 200) {
      throw new Error('Could not load route plan');
    }

    const { id, name, distance, waypoints: waypointsJson, vesselDraft, vesselSpeed } = result.data;
    const waypoints = JSON.parse(waypointsJson);

    // Update reachable area
    let reachResult = undefined;

    if (state.newRoutePlan.displayReachArea === true && state.newRoutePlan.reachDistance > 0) {
      const waypoint = waypoints[waypoints.length - 1];
      const lng = waypoint.lngLat[0];
      const lat = waypoint.lngLat[1];
  
      try {
        reachResult = await reachArea({ sourceLat: lat, sourceLng: lng, vesselDraft, distance: state.newRoutePlan.reachDistance * 1852 });
      } catch (err) {
        console.error(err);
      }
    }  
    

    return { id, name, distance, waypoints, vesselDraft, vesselSpeed, reachArea: reachResult};
  } catch (err) {
    console.error('Load route plan failed', err);
    throw new Error(err)
  }
});


export const saveRoutePlan = createAsyncThunk('saveRoutePlan', async ({}, thunkAPI) => {
  const state = thunkAPI.getState();

  // Update existing if id set
  if (state.newRoutePlan.id) {
    try {
      const { id, name, distance, waypoints, vesselDraft, vesselSpeed } = state.newRoutePlan;

      const routeObject = {
        name,
        distance,
        waypoints: JSON.stringify(waypoints),
        vesselDraft,
        vesselSpeed
      }

      const result = await verttiApi.patch(`routeplan/${id}`, routeObject)

      return true;
    } catch (err) {
      console.error(err);
    }
  }

  // Create a new one if no id set
  try {
    const { name, distance, waypoints, vesselDraft, vesselSpeed } = state.newRoutePlan;

    const routeObject = {
      name,
      distance,
      waypoints: JSON.stringify(waypoints),
      vesselDraft,
      vesselSpeed
    }

    const result = await verttiApi.post('routeplan', routeObject)

    return result.data;
  } catch (err) {
    console.error(err);
  }

});


export const newRoutePlanSlice = createSlice({
  name: 'routePlan',
  initialState: { 
      id: null,
      name: '',
      distance: 0,
      vesselDraft: 1.5,
      vesselSpeed: 6.0,
      waypoints: [],
      routingMode: 'auto',
      status: 'idle',
      savingStatus: 'ok', // ok, changed, saving, error
      routePlanLoaded: 'no',
      apiErrorCode: '',
      displayReachArea: false,
      reachDistance: 15,
      reachArea: undefined,
  },
  reducers: {
    setRoutingMode: (state, action) => {
      state.routingMode = action.payload;
      state.savingStatus = 'changed';
    },
    setName: (state, action) => {
      state.name = action.payload;
      state.savingStatus = 'changed';
    },
    setVesselDraft: (state, action) => {
      state.vesselDraft = action.payload;
      localStorage.setItem('vesselDraft', action.payload);
      state.savingStatus = 'changed';
    },
    setVesselSpeed: (state, action) => {
      state.vesselSpeed = action.payload;
      localStorage.setItem('vesselSpeed', action.payload);
      state.savingStatus = 'changed';
    },
    setReachDistance: (state, action) => {
      state.reachDistance = action.payload;
      localStorage.setItem('reachDistance', action.payload);
      state.savingStatus = 'changed';
    },
    setDisplayReachArea: (state, action) => {
      state.displayReachArea = action.payload;
      localStorage.setItem('displayReachDistance', action.payload);
      state.savingStatus = 'changed';
    },
    setWaypoints: (state, action) => {
      state.waypoints = action.payload;
    },
    newRoutePlan: (state, action) => {
      state.id = null;
      state.waypoints = [];
      state.distance = 0;
      state.name = '';
      state.status = 'idle';
      state.savingStatus = 'ok';
    },
    completeRoutePlanLoaded: (state, action) => {
      state.routePlanLoaded = 'no';
    },
    clearApiErrorCode: (state, action) => {
      state.apiErrorCode = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addWaypoint.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(addWaypoint.rejected, (state, action) => {
      state.apiErrorCode = action.payload.errorCode;
      state.status = 'idle';    
    });
    builder.addCase(addWaypoint.fulfilled, (state, action) => {
      state.waypoints.push(action.payload.waypoint);
      state.distance = action.payload.totalDistance;
      state.reachArea = action.payload.reachArea;
      state.status = 'idle';
      state.savingStatus = 'changed';
    });
    builder.addCase(updateReachable.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(updateReachable.rejected, (state, action) => {
      state.apiErrorCode = action.payload?.errorCode ? action.payload.errorCode : '';
      state.status = 'idle';    
    });
    builder.addCase(updateReachable.fulfilled, (state, action) => {
      state.reachArea = action.payload.reachArea;
      state.status = 'idle';
      state.savingStatus = 'changed';
    });
    builder.addCase(removeWaypoint.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(removeWaypoint.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(removeWaypoint.fulfilled, (state, action) => {
      state.waypoints = action.payload.waypoints;
      if (action.payload.reachArea !== undefined) state.reachArea = action.payload.reachArea;
      state.distance = routeTotalDistance(action.payload);
      state.status = 'idle';
      state.savingStatus = 'changed';
    });

    builder.addCase(relocateWaypoint.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(relocateWaypoint.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(relocateWaypoint.fulfilled, (state, action) => {
      state.waypoints = action.payload;
      state.distance = routeTotalDistance(action.payload);
      state.status = 'idle';
      state.savingStatus = 'changed';
    });

    builder.addCase(groupWaypoints.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(groupWaypoints.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(groupWaypoints.fulfilled, (state, action) => {
      state.waypoints = action.payload;
      state.status = 'idle';
      state.savingStatus = 'changed';
    });

    builder.addCase(breakWaypointGroup.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(breakWaypointGroup.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(breakWaypointGroup.fulfilled, (state, action) => {
      state.waypoints = action.payload;
      state.status = 'idle';
      state.savingStatus = 'changed';
    });

    builder.addCase(splitLeg.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(splitLeg.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(splitLeg.fulfilled, (state, action) => {
      state.waypoints = action.payload;
      state.distance = routeTotalDistance(action.payload);
      state.status = 'idle';
      state.savingStatus = 'changed';
    });

    builder.addCase(loadRoutePlan.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(loadRoutePlan.rejected, (state, action) => {
      state.status = 'idle';
    });
    builder.addCase(loadRoutePlan.fulfilled, (state, action) => {
      localStorage.setItem('activeRoutePlanId', action.payload.id);
      
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.distance = action.payload.distance;
      state.waypoints = action.payload.waypoints;
      state.vesselDraft = action.payload.vesselDraft;
      state.vesselSpeed = action.payload.vesselSpeed;
      state.reachArea = action.payload.reachArea;

      state.status = 'idle';
      state.savingStatus = 'changed';
      state.routePlanLoaded = 'yes';
    });

    builder.addCase(saveRoutePlan.pending, (state, action) => {
      state.status = 'idle';
      state.savingStatus = 'saving';
    });
    builder.addCase(saveRoutePlan.rejected, (state, action) => {
      state.status = 'idle';
      state.savingStatus = 'error';
    });
    builder.addCase(saveRoutePlan.fulfilled, (state, action) => {
      const { routePlanId } = action.payload;
      if (routePlanId) {
        state.id = action.payload.routePlanId;
        localStorage.setItem('activeRoutePlanId', routePlanId);
      }
      state.status = 'idle';
      state.savingStatus = 'ok';
    });
  }
});

export const { 
  setName,
  setRoutingMode, 
  setId, 
  setDistance, 
  setVesselDraft, 
  setVesselSpeed,
  setReachDistance,
  setDisplayReachArea,
  setWaypoints, 
  newRoutePlan,
  completeRoutePlanLoaded,
  clearApiErrorCode } = newRoutePlanSlice.actions;
  
export default newRoutePlanSlice.reducer;