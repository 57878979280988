import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useAuth } from '../hooks/useAuth';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

import '../App.css';

import bgImage from "../assets/img/loginbg.jpg";

function LoginPage(props) {
  const auth = useAuth();
  let navigate = useNavigate();
  
//  const { window } = props;
  
  // const container = window !== undefined ? () => window().document.body : undefined;
  // const [mobileOpen, setMobileOpen] = useState(false);
  // const [route, setRoute] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { formatMessage } = props.intl;

  // Login status listener
  useEffect(() => {
    if (auth.loginResult === 'success' || auth.loginResult === 'silent_login_completed') {
      setErrorMessage('');
      setLoading(false);
      navigate("/", { replace: true });
    }
    else if (auth.loginResult === 'failed') {
      setErrorMessage(formatMessage({ id: "login.failed"}));
      console.error('Login failed');
      setLoading(false);
    }
  }, [auth.loginResult]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      attemptLogin(values.email, values.password);
    }
  });

  const attemptLogin = async (email, password) => {
    setErrorMessage('');

    if (!email || !password) {
      setErrorMessage(formatMessage({ id: "login.failed"}));
      return false;
    }

    setLoading(true);

    auth.signin(email, password);

  }

  if (auth.silentLoginInProgress || (auth.loginResult === 'success' && auth.loginResult === 'silent_login_completed')) {
    return <></>
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >

        <Grid item xs={3}>
          
          <Box p={5} sx={{
            bgcolor: '#ffffff',
            minWidth: {xs: 250, sm: 400},
            maxWidth: {xs: 250, sm: 400},
            boxShadow: 3,
          }}>
            <Typography variant="h4" component="h2">
              <FormattedMessage id="login.heading"/>
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box pt={3} >
                <TextField
                  id='email'
                  label={formatMessage({ id: "general.emailAddress"})}
                  variant='outlined'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  sx={{width: '100%'}}/>
              </Box>
              <Box pt={3}>
                <TextField 
                  id='password' 
                  label={formatMessage({ id: "general.password"})}
                  variant='outlined'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type='password'
                  autoComplete='current-password'
                  sx={{width: '100%'}}/>
              </Box>
              {errorMessage && (
                <Box pt={3}>
                  <p>{errorMessage}</p>
                </Box>
              )}
              <Box pt={3}>
                <Button 
                  variant="contained" 
                  size="large"
                  disabled={loading}
                  type="submit"
                >
                  <FormattedMessage id="login.login"/>
                </Button>
              </Box>
              <Box pt={3}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("/", { replace: true })}>
                  <FormattedMessage id="cancel"/>
                </Typography>
              </Box>
              <Box pt={1}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("../passwordreset", { replace: true })}>
                  <FormattedMessage id="login.passwordForgotten"/>
                </Typography>
              </Box>
              <Box pt={1}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("../register", { replace: true })}>
                  <FormattedMessage id="login.register"/>
                </Typography>
              </Box>

            </form>
          </Box>
          
        </Grid>   
        
      </Grid> 
      <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")",  }}    
      />
    </>
  );
}

export default injectIntl(LoginPage);