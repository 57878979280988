import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { withSnackbar, useSnackbar } from 'notistack';
import { Typography, Grid, Paper, Pagination, Stack, Button } from '@mui/material';
import verttiApi from '../../api/verttiApi';

const HarborItem = ({ harbor, editHarbor }) => {
  const navigate = useNavigate();
  if (!harbor) return null;

  return (
    <div>
    <Grid container>
      <Grid item xs={12}>
        <Button onClick={() => navigate(`/manager/harbor/${harbor.publicId}`, {replace: true})}><Typography>{harbor.name} ({harbor.location})</Typography></Button>
          {/* <a href='/' onClick={e => {e.preventDefault(); editHarbor(harbor.publicId)}}><Typography>{harbor.name} ({harbor.location})</Typography></a> */}
      </Grid>
    </Grid>

    </div>
  )
}


const HarborManager = (props) => {
  const navigate = useNavigate();
  const [harbors, setHarbors] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState(10);

  const { editHarbor } = props;

  useEffect(() => {
    loadHarbors();
  }, []);  

  useEffect(() => {
    loadHarbors();
  }, [page]);

  const loadHarbors = () => {
    verttiApi.get(`manager/harbors/${page}/${pageSize}`)
      .then((res) => {
        setHarbors(res.data.harbors);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handleHarborPageChange = (event, value) => {
    setPage(value);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant='managerHeading'>Satamat</Typography>

            <Pagination count={totalPages} page={page} onChange={handleHarborPageChange} />
            {harbors.map(h => <HarborItem key={h.publicId} harbor={h} /> )}

          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default withSnackbar(HarborManager);