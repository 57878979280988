import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const StructurePropertiesEditor = (props) => {
  const { feature, saveProperties } = props;

  const [structureType, setStructureType] = useState(feature?.properties?.structureType);

  useEffect(() => {
    if (!feature?.id) return false;

    saveProperties({
      featureId: feature.id,
      property: 'structureType',
      values: structureType,

    });
  }, [structureType])

  // Update state if selection is changeg without deselecting first
  useEffect(() => {
    setStructureType(feature?.properties?.structureType);
  }, [feature])

  const handleStructureTypeChange = (typeName) => (event) => {
    setStructureType(typeName);
  };

  const featureType = feature?.geometry?.type || false;

  if (!featureType) {
    return (
      <>
        n/a
      </>
    )
  }

  return (
    <>
        <Typography variant='body1'>Rakenne</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction='column'>
              <FormControlLabel control={<Switch checked={structureType === 'dock'} onChange={handleStructureTypeChange('dock')} />} label="Laituri" />
              <FormControlLabel control={<Switch checked={structureType === 'breakwater'} onChange={handleStructureTypeChange('breakwater')} />} label="Aallonmurtaja" />
              <FormControlLabel control={<Switch checked={structureType === 'otherStructure'} onChange={handleStructureTypeChange('otherStructure')} />} label="Muu rakenne" />
            </Stack>
          </Grid>
        </Grid>
        <Box display='block'>
          <TextField fullWidth label='Lisätietoja' sx={{marginTop: '20px'}}></TextField>
        </Box>
    </>
  );
};

export default StructurePropertiesEditor;