import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { withSnackbar, useSnackbar } from 'notistack';
import verttiApi from '../../../api/verttiApi';
import { 
  Grid,
  Stack,
  Typography,
  Box,
  TextField,
  Alert,
  Pagination,
  LinearProgress,
  ButtonGroup,
  Button,
} from '@mui/material';

const PhotoItem = ({ photo }) => {
  if (!photo) return null;

  return (
    <div>
    <Grid container>
      <Grid item xs={6}>
        <Stack direction='column'>
          <Typography>{photo.status}</Typography>
          <Typography>{photo.uploader.nickName}</Typography>
          <Typography>{photo.createdAt}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <img src={`${process.env.REACT_APP_CDN_URL}/${photo.url}`} height='150px'/>
      </Grid>
    </Grid>

    </div>
  )
}

const CrowdPoiPhotoManager = (props) => {
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(10);
  const [statusFilter, setStatusFilter] = useState('all'); // 'all', 'published', 'pending', 'hidden'

  const filters = ['all', 'published', 'pending', 'hidden'];

  useEffect(() => {
    loadPhotos();
  }, []);  

  useEffect(() => {
    loadPhotos();
  }, [page, statusFilter]);

  const loadPhotos = () => {
    verttiApi.get(`manager/photos/${statusFilter}/${page}/${pageSize}`)
      .then((res) => {
        setPhotos(res.data.photos);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handlePhotoPageChange = (event, value) => {
    setPage(value);
  }

  const handleFilterChange = (option) => {
    setStatusFilter(option);
  }

  return (
    <>
      <Typography variant='managerHeading'>Veneilykohteiden kuvat</Typography>
      <Box my={2}>
        <ButtonGroup variant='outlined' size='small'>
          {filters.map((option) => (
            <Button
              key={option}
              onClick={() => handleFilterChange(option)}
              // Apply some style or variant change to indicate which button is active
              color={statusFilter === option ? 'primary' : 'secondary'}
            >
              {option}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Pagination count={totalPages} page={page} onChange={handlePhotoPageChange} />
      {photos.map(p => <PhotoItem key={p.publicId} photo={p} /> )}
    </>
  );
}

export default withSnackbar(CrowdPoiPhotoManager);