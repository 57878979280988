import React, {useState, useEffect, useRef} from 'react';
import { Box, Grid, Button, Typography, useMediaQuery, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Alert } from '@mui/material';
import { useFormik } from 'formik';
import verttiApi from '../../../../api/verttiApi';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../../hooks/useAuth';
import HarborEntitlementsManager from './HarborEntitlementsManager';
import FormField from './FormField';


const HarborInfo = (props) => {
  const { harbor, loadHarbor } = props;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const infoFormik = useFormik({
    initialValues: {
      name: harbor?.name ?? '',
      lng: harbor?.lngLat?.[0] ?? '',
      lat: harbor?.lngLat?.[1] ?? '',
      location: harbor?.location ?? '',
      preview: harbor?.preview ?? '',
      approachGuide: harbor?.approachGuide ?? '',
      mooringGuide: harbor?.mooringGuide ?? '',
      fees: harbor?.fees ?? '',
      opennessToWinds: harbor?.opennessToWinds ?? '',
      category: harbor?.category ?? '',   
    },
    enableReinitialize: true,
    onSubmit: values => {    
      updateHarbor(values);
    }
  });
  
  const updateHarbor = (values) => {
    //console.log(values);

    const body = {
      name: values.name,
      location: values.location || null,
      // lngLat: [values.lng, values.lat],
      fees: values.fees || null,
      preview: values.preview,
      approachGuide: values.approachGuide || null,
      mooringGuide: values.mooringGuide || null,
      opennessToWinds: values.opennessToWinds || null,
      // category: values.category,
    }

    verttiApi.patch(`manager/harbor/${harbor.publicId}/information`, body)
      .then((res) => {
        loadHarbor();
        enqueueSnackbar('Sataman tiedot tallennettu.', { variant: 'success' });
      })
      .catch((err) => {
        console.error('Error updating harbor', err);
        enqueueSnackbar('Tietojen tallennus epäonnistui.', { variant: 'error' });
        // setLoading(false);
      }) 

  }

  return (
    <React.Fragment>
    <form onSubmit={infoFormik.handleSubmit}>
        {infoFormik.dirty && <Alert severity='info'>Sinulla on tallentamattomia muutoksia.</Alert>}
        <Box mt={1} />
        {!auth.isAdmin() && (
          <Alert severity='info'>Jos haluat muuttaa sataman kategoriaa tai sijaintia, ota yhteys asiakaspalveluun osoitteessa posti@vertti.io.</Alert>
        )}
        {/* {auth.isAdmin() && (
          <Box pt={3} >
            <FormControl>
              <FormLabel id="harbor-category-group-label">Sataman tyyppi</FormLabel>
              <RadioGroup
                id='category'
                aria-labelledby="harbor-category-group-label"
                value={infoFormik.values.category}
                onChange={infoFormik.handleChange}
                name="radio-buttons-group"
              >
                <FormControlLabel name='category' value="guest_harbor" control={<Radio />} label="Vierassatama" />
                <FormControlLabel name='category' value="service_harbor" control={<Radio />} label="Palvelusatama" />
                <FormControlLabel name='category' value="excursion_harbor" control={<Radio />} label="Retkisatama" />
                <FormControlLabel name='category' value="natural_harbor" control={<Radio />} label="Luonnonsatama" />
                <FormControlLabel name='category' value="home_harbor" control={<Radio />} label="Kotisatama (ei vieraspaikkoja)" />
              </RadioGroup>
            </FormControl>
          </Box>
        )}

        {auth.isAdmin() && (
          <React.Fragment>
          <Box pt={3} >
            <TextField
              id='lng'
              label='Longitudi'
              variant='standard'
              onChange={infoFormik.handleChange}
              onBlur={infoFormik.handleBlur}
              value={infoFormik.values.lng}
              sx={{width: '100%'}}/>
          </Box>
          <Box pt={3} >
            <TextField
              id='lat'
              label='Latitudi'
              variant='standard'
              onChange={infoFormik.handleChange}
              onBlur={infoFormik.handleBlur}
              value={infoFormik.values.lat}
              sx={{width: '100%'}}/>
          </Box>
          </React.Fragment>
        )} */}

        <Box pt={1}>
          <Button type='submit' variant='contained'>Tallenna muutokset</Button>
        </Box>

        <Box pt={3} >
          <FormField fieldName='name' label='Sataman nimi' formik={infoFormik}/>
        </Box>
        <Box pt={1} >
          <FormField fieldName='location' label='Sataman sijainti' formik={infoFormik}/>
        </Box>
        <Box pt={1} >
          <FormField fieldName='preview' label='Esittelyteksti' formik={infoFormik} multiline={true}/>
        </Box>
        <Box pt={1} >
          <FormField fieldName='approachGuide' label='Lähestymisohje' formik={infoFormik} multiline={true}/>
        </Box>
        <Box pt={1} >
          <FormField fieldName='mooringGuide' label='Kiinnittymisohje' formik={infoFormik} multiline={true}/>
        </Box>
        <Box pt={1} >
          <FormField fieldName='opennessToWinds' label='Avoimuus tuulille' formik={infoFormik} multiline={true}/>
        </Box>
        <Box pt={1} >
          <FormField fieldName='fees' label='Tietoa satamamaksuista' formik={infoFormik} multiline={true}/>
        </Box>
        <Box pt={1}>
          <Button type='submit' variant='contained'>Tallenna muutokset</Button>
        </Box>

        </form>

        {auth.user.role === 'admin' && <HarborEntitlementsManager harborId={harbor.publicId}/>}
        </React.Fragment>
  );
}

export default withSnackbar(HarborInfo);