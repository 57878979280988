import React, {useState, useEffect, useMemo, useRef} from 'react';
import { Box, Grow, Grid, Button, Typography, useMediaQuery, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Alert, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import verttiApi from '../../../../api/verttiApi';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../../hooks/useAuth';
import {useDropzone} from 'react-dropzone';
import ImageCarouselHarborAdmin from './ImageCarouselHarborAdmin';
import { LoadingButton } from '@mui/lab';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#C0C0C0',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#555555',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#203CCC'
};

const acceptStyle = {
  borderColor: '#008445'
};

const rejectStyle = {
  borderColor: '#FF4C23'
};

function StyledDropzone(props) {
  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {'image/jpeg': ['.jpg', '.JPG', '.jpeg', '.JPEG']},
    maxSize: 5242880*2,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        props.setImageFile(null);
      //   enqueueSnackbar('Varmista, että tiedosto on oikeassa muodossa ja kooltaan alle 5 MB.', {
      //     variant: 'error',
      //  })  
      };
      if (acceptedFiles[0]) {
        props.setImageFile(Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0])}));
      }
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Vedä kuvatiedosto (jpeg) tähän, tai klikkaa valitaksesi tiedostoista. Kuvan resoluution tulisi olla vähintään 1280 x 960 pikseliä ja enimmäiskoko on 20 MB. Kuva rajataan kuvasuhteeseen 4:3, joten suosi vaakakuvia. Lisää yksi kuva kerrallaan.</p>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center" width='100%' mt={2}>
        {props.imageFile && <img src={props.imageFile.preview} width='100px' alt='ladattava kuva'/>}
      </Box>
    </div>
  );
}

const PhotoManager = (props) => {
  const { harbor, loadHarbor } = props;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const photoUploadRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageFile, _setImageFile] = useState(undefined);  
  const setImageFile = (img) => {
    if (imageFile && imageFile.preview) URL.revokeObjectURL(imageFile.preview);
    _setImageFile(img);
  }

  const [addImageActive, setAddImageActive] = useState(false);
  const handleSetAddImageActive = () => {
    if (!auth.user) {
      return false;
    }
    setAddImageActive(true);
    setTimeout(() => {photoUploadRef.current.scrollIntoView()}, 100);
  }

  const uploadImage = () => {
    setUploading(true);
    setProgress(0);
    const config = { 
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    };
    let fd = new FormData();

    fd.append('image', imageFile);

    verttiApi.post(`/photo/upload/${harbor.publicId}`, fd, config)
      .then((res) => {
        loadHarbor();
        setUploading(false);
        setAddImageActive(false);
        setImageFile(null);
        URL.revokeObjectURL(imageFile.preview);
        enqueueSnackbar('Kiitos, kuva lisätty!', {
          variant: 'success',
        });        
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Kuvan tallennus epäonnistui.', {
          variant: 'error',
        })
        setImageFile(null);
        setUploading(false);
      })
  };

  const removePhoto = (photoId) => {
    const newPhotos = harbor.photos.filter(p => p.publicId !== photoId)
  
    const newHarbor = {
      ...harbor,
      photos: newPhotos,
    };
    
    loadHarbor();
  };

  return (
    <React.Fragment>
      {harbor.photos && harbor.photos.length > 0 && (
          <>
            <ImageCarouselHarborAdmin images={harbor.photos} removePhoto={removePhoto} />
            <Box display='flex' justifyContent='flex-end'>
              {!addImageActive && <Button size='small' onClick={() => handleSetAddImageActive(true)}>Lisää kuva</Button>}
            </Box>
          </>
        )}
        
        {(!harbor.photos || harbor.photos.length === 0) && (
          <>
            <Alert variant='info'>
              Meillä ei ole vielä kuvia kohteesta. Olisiko sinulla lisätä ensimmäinen?
            </Alert>
            {!addImageActive && (
              <Box display='flex' justifyContent='center'>
                <Button size='small' variant='contained' onClick={() => handleSetAddImageActive(true)}>Lisää kuva</Button>
              </Box>
            )}
          </>
        )}

        <Box ref={photoUploadRef} />
          {addImageActive && (
            <Grow in={true}>
              <Box my={3}>
                <StyledDropzone setImageFile={setImageFile} imageFile={imageFile}/>
                {uploading && <Box mt={2}><LinearProgress variant="determinate" value={progress} /></Box>}
                <Box mt={1} display='flex' justifyContent='flex-end'>
                  <LoadingButton disabled={!imageFile} onClick={() => uploadImage()} loading={uploading} size='small' variant='contained'>Tallenna kuva</LoadingButton>
                </Box>
              </Box>
            </Grow>
          )}
    </React.Fragment>
  );
}

export default withSnackbar(PhotoManager);