import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const LinePropertiesEditor = (props) => {
  const { feature, saveProperties } = props;

  const [lineType, setLineType] = useState(feature?.properties?.lineType);

  useEffect(() => {
    if (!feature?.id) return false;
    saveProperties({
      featureId: feature.id,
      property: 'lineType',
      values: lineType,
    });
  }, [lineType])

  // Update state if selection is changeg without deselecting first
  useEffect(() => {
    setLineType(feature?.properties?.lineType);
  }, [feature])

  const handleLineTypeChange = (type) => (event) => {
    setLineType(type)
  }

  const featureType = feature?.geometry?.type || false;

  if (!featureType) {
    return (
      <>
        n/a
      </>
    )
  }

  return (
    <>
        <Typography variant='body1'>Janan ominaisuudet</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction='column'>
              <FormControlLabel control={<Switch checked={lineType === 'mooringOvernight'} onChange={handleLineTypeChange('mooringOvernight')} />} label="Yöpyminen sallittu" />
              <FormControlLabel control={<Switch checked={lineType === 'mooringWithbooking'} onChange={handleLineTypeChange('mooringWithbooking')} />} label="Yöpyminen sallittu ennakkovarauksella" />
              <FormControlLabel control={<Switch checked={lineType === 'mooringShortterm'} onChange={handleLineTypeChange('mooringShortterm')} />} label="Lyhytaikainen kiinnittyminen sallittu" />
              <FormControlLabel control={<Switch checked={lineType === 'mooringProhibited'} onChange={handleLineTypeChange('mooringProhibited')} />} label="Vierasveneiden kiinnittyminen kielletty" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction='column'>
              <FormControlLabel control={<Switch checked={lineType === 'recommendedRoute'} onChange={handleLineTypeChange('recommendedRoute')} />} label="Suositeltu reitti" />
            </Stack>
          </Grid>
        </Grid>
        <Box display='block'>
          <TextField fullWidth label='Lisätietoja' sx={{marginTop: '20px'}}></TextField>
        </Box>
    </>
  );
};

export default LinePropertiesEditor;