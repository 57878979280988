import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const MooringareaPropertiesEditor = (props) => {
  const { feature, saveProperties } = props;

  const [mooringareaType, setMooringareaType] = useState(feature?.properties?.mooringareaType);

  useEffect(() => {
    if (!feature?.id) return false;

    saveProperties({
      featureId: feature.id,
      property: 'mooringareaType',
      values: mooringareaType,

    });
  }, [mooringareaType])

  // Update state if selection is changeg without deselecting first
  useEffect(() => {
    setMooringareaType(feature?.properties?.mooringareaType);
  }, [feature])

  const handleMooringareaTypeChange = (typeName) => (event) => {
    setMooringareaType(typeName);
  };

  const featureType = feature?.geometry?.type || false;

  if (!featureType) {
    return (
      <>
        n/a
      </>
    )
  }

  return (
    <>
        <Typography variant='body1'>Kiinnittäytymisalueen tyyppi</Typography>
          <Stack direction='column'>
              <FormControlLabel control={<Switch checked={mooringareaType === 'overnight'} onChange={handleMooringareaTypeChange('overnight')} />} label="Yöpyminen sallittu" />
              <FormControlLabel control={<Switch checked={mooringareaType === 'booking'} onChange={handleMooringareaTypeChange('booking')} />} label="Yöpyminen sallittu ennakkovarauksella" />
              <FormControlLabel control={<Switch checked={mooringareaType === 'shortTerm'} onChange={handleMooringareaTypeChange('shortTerm')} />} label="Lyhytaikainen kiinnittyminen sallittu" />
              <FormControlLabel control={<Switch checked={mooringareaType === 'prohibited'} onChange={handleMooringareaTypeChange('prohibited')} />} label="Vierasveneiden kiinnittyminen kielletty" />
            </Stack>
    </>
  );
};

export default MooringareaPropertiesEditor;