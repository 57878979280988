import React from 'react';
import downloadGPX from './lib/downloadGPX';
import generateRouteGeoJSON from './lib/generateRouteGeoJSON';
import { useSelector } from 'react-redux';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle } from '@mui/material';
  
const DownloadGPXDialog = ({ dialogOpen, handleDialogClose }) => {
  const waypoints = useSelector(state => state.newRoutePlan.waypoints);

  const handleDownload = () => {
    downloadGPX(generateRouteGeoJSON(waypoints))
    handleDialogClose();
  }

  return (
      <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted
      sx={{zIndex: 9999}}
    >
      <DialogTitle id="alert-dialog-title">
        Lataa reittisuunnitelma GPX-muodossa
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Vertti ei ole navigointisovellus ja reittisuunnitelmaa ei saa käyttää sellaisenaan navigointikäyttöön.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>
          Peru lataus
        </Button>
        <Button onClick={handleDownload} autoFocus>
          Hyväksy ehdot
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default DownloadGPXDialog;