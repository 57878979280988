import togpx from 'togpx';

const convertToGPX = (geojson) => {
  const gpx = togpx(geojson);
  
  // Create a Blob object
  const blob = new Blob([gpx], { type: 'application/gpx+xml' });
  return blob;
};

export default convertToGPX;