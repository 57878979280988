import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import SquareIcon from '@mui/icons-material/Square';
import TimelineIcon from '@mui/icons-material/Timeline';
import SatelliteIcon from '@mui/icons-material/Satellite';
import HandymanIcon from '@mui/icons-material/Handyman';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import TextureIcon from '@mui/icons-material/Texture';
import Divider from '@mui/material/Divider';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PreviewIcon from '@mui/icons-material/Preview';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IconButton, Slider, Tooltip } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function HarborMapEditorToolbar(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { 
    logicalDrawMode, 
    handleDrawModeChange, 
    deleteSelectedObjects, 
    displaySatelliteLayer, 
    handleDisplaySatelliteLayer, 
    setMapsDialogOpen, 
    displayPreviewLayer,
    handleDisplayPreviewLayer,
    handlePublishMap,
    editingDisabled,
    saveMap } = props;

  // const handleRoutingMode = (event, mode) => {
  //   if (mode === null) mode = 'off';
  //   dispatch(setRoutingMode(mode));
  //   const message = mode === 'auto' ? 'Automaattireititys käytössä' : mode === 'manual' ? 'Manuaalireititys käytössä' : 'Reitin muokkaus pois käytöstä'
  //   enqueueSnackbar(message, { variant: 'info' });
  // };

  // const handleAddCrowdPoi = (event, value) => {
  //   if (value !== true) value = false;
  //   dispatch(setAddCrowdPoiActive(value));
  // };

  // const handleToggleReach = (event, value) => {
  //   if (value !== true) value = false;
  //   dispatch(setDisplayReachArea(value));
  //   if (value === true) dispatch(updateReachable({distance: reachDistance}));
  // };

  return (
    <>
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={logicalDrawMode}
          exclusive
          onChange={handleDrawModeChange}
          aria-label="Piirustustila"
        >
            <ToggleButton value="simple_select" aria-label="Valitse objekti">
              <Tooltip title='Valitse objekti'>
                <TouchAppIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="draw_line_string" aria-label="Piirrä reitti" disabled={editingDisabled}>
              <Tooltip title='Piirrä jana'>
                <TimelineIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="draw_polygon_construction" aria-label="Piirrä rakenne" disabled={editingDisabled}>
              <Tooltip title='Piirrä rakenne'>
                <HandymanIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="draw_polygon_mooringarea" aria-label="Piirrä kiinnittymisalue" disabled={editingDisabled}>
              <Tooltip title='Piirrä kiinnittymisalue'>
                <TextureIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="draw_point" aria-label="Lisää merkki" disabled={editingDisabled}>
              <Tooltip title='Lisää merkki'>
                <AddLocationAltIcon />
              </Tooltip>
            </ToggleButton>
        </StyledToggleButtonGroup>
        <IconButton value="Poista valittu objekti" aria-label="color" onClick={() => deleteSelectedObjects()} disabled={editingDisabled}>
            <Tooltip title='Poista valittu objekti'>
              <DeleteForeverIcon />
            </Tooltip>
        </IconButton>
        
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
  
        <StyledToggleButtonGroup
          size="small"
          value={displaySatelliteLayer}
          exclusive
          onChange={handleDisplaySatelliteLayer}
          aria-label="Satelliittikuva"
        >
          <ToggleButton value={true} aria-label="Näytä satelliittikuva">
              <Tooltip title='Näytä satelliittikuva'>
                <SatelliteIcon />
              </Tooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>

        <StyledToggleButtonGroup
          size="small"
          value={displayPreviewLayer}
          exclusive
          onChange={handleDisplayPreviewLayer}
          aria-label="Satelliittikuva"
        >
          <ToggleButton value={true} aria-label="Näytä esikatselu">
              <Tooltip title='Näytä esikatselu'>
                <PreviewIcon />
              </Tooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>

        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <IconButton value="" aria-label="Näytä kartat" onClick={() => setMapsDialogOpen(true)}>
            <Tooltip title='Näytä kartat'>
              <FolderOpenIcon />
            </Tooltip>
        </IconButton>
        <IconButton value="" aria-label="Tallenna" onClick={() => saveMap()} disabled={editingDisabled}>
            <Tooltip title='Tallenna kartta'>
              <SaveIcon />
            </Tooltip>
        </IconButton>
        <IconButton value="" aria-label="Julkaise" onClick={() => handlePublishMap()} disabled={editingDisabled}>
            <Tooltip title='Julkaise'>
              <PublishedWithChangesIcon />
            </Tooltip>
        </IconButton>
      </Paper>
    </div>
  </>
  );
}

export default withSnackbar(HarborMapEditorToolbar);