import React, {useState, useMemo, useEffect, useRef} from 'react';
// import { useNavigate } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Alert,
  Grow
} from '@mui/material';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useDispatch } from 'react-redux';
import { setACrowdPoi } from '../../features/crowdPois';
import verttiApi from '../../api/verttiApi';

import CloseIcon from '@mui/icons-material/Close';

import BonfireIcon from '../icons/BonfireIcon';
import FuelIcon from '../icons/FuelIcon';
import BarbecueIcon from '../icons/BarbecueIcon';
import BeachIcon from '../icons/BeachIcon';
import CafeIcon from '../icons/CafeIcon';
import CampingIcon from '../icons/CampingIcon';
import FishingIcon from '../icons/FishingIcon';
import NatureTrailIcon from '../icons/NatureTrailIcon';
import PlaygroundIcon from '../icons/PlaygroundIcon';
import WasteDisposalIcon from '../icons/WasteDisposalIcon';
import ToiletIcon from '../icons/ToiletIcon';
import RestaurantIcon from '../icons/RestaurantIcon';
import DogparkIcon from '../icons/DogparkIcon';
import BirdTowerIcon from '../icons/BirdTowerIcon';
import AccomodationIcon from '../icons/AccomodationIcon';
import DrinkingWaterIcon from '../icons/DrinkingWaterIcon';
import SaunaIcon from '../icons/SaunaIcon';
import AttractionIcon from '../icons/AttractionIcon';
import { LoadingButton } from '@mui/lab';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import ImageCarousel from './ImageCarousel';
import {useDropzone} from 'react-dropzone';
import CrowdPoiComments from './CrowdPoiComments';

const poiFeatures = [
  {key: 'barbecuePlace', icon: <BarbecueIcon fontSize='large'/>, label: 'Grillaus'},
  {key: 'campfirePlace', icon: <BonfireIcon fontSize='large'/>, label: 'Nuotiopaikka'},
  {key: 'cafe', icon: <CafeIcon fontSize='large'/>, label: 'Kahvila'},
  {key: 'restaurant', icon: <RestaurantIcon fontSize='large'/>, label: 'Ravintola'},
  {key: 'sauna', icon: <SaunaIcon fontSize='large'/>, label: 'Sauna'},
  {key: 'beach', icon: <BeachIcon fontSize='large'/>, label: 'Uimaranta'},
  {key: 'birdTower', icon: <BirdTowerIcon fontSize='large'/>, label: 'Lintutorni'},
  {key: 'natureTrail', icon: <NatureTrailIcon fontSize='large'/>, label: 'Luontopolku'},
  {key: 'fishing', icon: <FishingIcon fontSize='large'/>, label: 'Kalastus'},
  {key: 'dogPark', icon: <DogparkIcon fontSize='large'/>, label: 'Koirapuisto'},
  {key: 'playground', icon: <PlaygroundIcon fontSize='large'/>, label: 'Leikkipaikka'},
  {key: 'drinkingWater', icon: <DrinkingWaterIcon fontSize='large'/>, label: 'Juomavesi'},
  {key: 'toilet', icon: <ToiletIcon fontSize='large'/>, label: 'Käymälä'},
  {key: 'wasteDisposal', icon: <WasteDisposalIcon fontSize='large'/>, label: 'Jätehuolto'},
  {key: 'campsite', icon: <CampingIcon fontSize='large'/>, label: 'Telttailu'},
  {key: 'accommodation', icon: <AccomodationIcon fontSize='large'/>, label: 'Majoitus'},
  {key: 'attraction', icon: <AttractionIcon fontSize='large'/>, label: 'Nähtävyys'},
  {key: 'fuelStation', icon: <FuelIcon fontSize='large' />, label: 'Polttoaine'},
];


const AddFeatureConfirmationDialog = ({ open, setOpen, addFeature, featureToBeAdded }) => {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    addFeature(featureToBeAdded);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="vahvistus"
        aria-describedby="vahvista ominaisuuden lisääminen"
      >
        <DialogTitle id="alert-dialog-title">
          {"Vahvista ominaisuuden lisääminen"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oletko varma, että kohteessa on ominaisuus "{poiFeatures.filter(f => f.key === featureToBeAdded)[0].label}" ja haluat lisätä sen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Peru</Button>
          <Button onClick={handleConfirm} autoFocus>
            Lisää ominaisuus
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const FeatureItem = ({ featureKey, label, icon, selected }) => {
  return (
    <Grid item xs={4} sm={3}>
      <Box 
        p={1}
        sx={{
          borderRadius: 3,
          boxShadow: 2,
          backgroundColor: '#fafafa',
          '&:hover': {
            boxShadow: 3,
            cursor: 'pointer'
          },
      }}>
        <Stack direction='column' spacing={1} display='flex' height='100%' alignItems='center' >
          {icon}
          <Typography fontSize={{xs: '0.9rem', sm: '1.0rem'}}>{label}</Typography>
        </Stack>
      </Box>
    </Grid>
  );
}

const FeatureList = ({features}) => {
  return (
    <>
      <Box py={3} px={1}>
        <Grid container spacing={3}>
          {poiFeatures.map(f => features[f.key] === true && 
            <FeatureItem 
                key={f.key}
                featureKey={f.key}
                label={f.label}
                icon={f.icon} 
            />
          )}
        </Grid>
      </Box>
    </>
  )
}


const FeatureSelectItem = ({ featureKey, label, icon, selected, confirmAddFeature }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Box 
        p={1}
        onClick={() => confirmAddFeature(featureKey)}
        sx={{
          height: '50px',
          borderRadius: 3,
          boxShadow: 2,
          backgroundColor: '#fafafa',
          '&:hover': {
            boxShadow: 3,
            cursor: 'pointer'
          },
      }}>
        <Stack direction='row' spacing={2} display='flex' height='100%' alignItems='center' >
          {icon}
          <Typography fontSize={{xs: '1.2rem', sm: '1.2rem'}}>{label}</Typography>
        </Stack>
      </Box>
    </Grid>
  );
}

const AddFeatureList = ({ confirmAddFeature, currentFeatures }) => {
  return (
    <>
      <Box mt={3}/>
      <Typography variant='h5'>Lisää uusi ominaisuus</Typography>
      <Typography variant='body1'>Lisää kohteeseen ominaisuus, jonka varmasti tiedät olevan kohteessa.</Typography>
      <Box py={3} px={1}>
        <Grid container spacing={3}>
          {poiFeatures.map(f => {
            if (currentFeatures[f.key] === true) return;
              return (<FeatureSelectItem 
                key={f.key}
                featureKey={f.key}
                label={f.label}
                icon={f.icon} 
                confirmAddFeature={confirmAddFeature}
            />);
          })}
        </Grid>
      </Box>
    </>
  )
}


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#C0C0C0',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#555555',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#203CCC'
};

const acceptStyle = {
  borderColor: '#008445'
};

const rejectStyle = {
  borderColor: '#FF4C23'
};

function StyledDropzone(props) {
  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {'image/jpeg': ['.jpg', '.JPG', '.jpeg', '.JPEG']},
    maxSize: 5242880*2,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        props.setImageFile(null);
      //   enqueueSnackbar('Varmista, että tiedosto on oikeassa muodossa ja kooltaan alle 5 MB.', {
      //     variant: 'error',
      //  })  
      };
      if (acceptedFiles[0]) {
        props.setImageFile(Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0])}));
      }
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Vedä kuvatiedosto (jpeg) tähän, tai klikkaa valitaksesi tiedostoista.</p>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center" width='100%' mt={2}>
        {props.imageFile && <img src={props.imageFile.preview} width='100px'/>}
      </Box>
    </div>
  );
}


const ViewCrowdPoiDialog = ({ dialogOpen, handleDialogClose, poi: paramPoi, displayDialog }) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const photoUploadRef = useRef(null);
  const addFeatureRef = useRef(null);
  const featureListRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState('1');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
  const [confirmAddFeatureDialogOpen, setConfirmAddFeatureDialogOpen] = useState(false);
  const [featureToBeAdded, setFeatureToBeAdded] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [saving, setSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [addImageActive, setAddImageActive] = useState(false);
  const handleSetAddImageActive = () => {
    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }
    setAddImageActive(true);
    setTimeout(() => {photoUploadRef.current.scrollIntoView()}, 100);
  }
  const [addFeatureActive, setAddFeatureActive] = useState(false);
  const handleSetAddFeatureActive = () => {
    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }
    setAddFeatureActive(true);
    setTimeout(() => {addFeatureRef.current.scrollIntoView()}, 100);
  }
  const [poi, setPoi] = useState(false);

  const [imageFile, _setImageFile] = useState(undefined);
  const setImageFile = (img) => {
    if (imageFile && imageFile.preview) URL.revokeObjectURL(imageFile.preview);
    _setImageFile(img);
  }

  useEffect(() => {
    const { publicId } = paramPoi;
    
    verttiApi.get(`/crowdpoi/${publicId}`)
      .then(res => {
        setPoi(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar('Kohteen tietojen haku epäonnistui.', {
          variant: 'error',
        })
      })
    
    return () => {
    };
  }, [])


  const updatePhotoLike = (photoId, newStatus) => {
    const newPhotos = poi.photos.map((photo) => {
      if (photo.publicId === photoId) {
        return {
          ...photo,
          liked: newStatus,
        };
      }
      return photo;
    });
  
    const newPoi = {
      ...poi,
      photos: newPhotos,
    };
  
    setPoi(newPoi);
  };

  const uploadImage = () => {
    setUploading(true);
    setProgress(0);
    const config = { 
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    };
    let fd = new FormData();

    fd.append('image', imageFile);

    verttiApi.post(`/photo/upload/${poi.publicId}`, fd, config)
      .then((res) => {
        return verttiApi.get(`/crowdpoi/${poi.publicId}`)
          .then(res => {
            URL.revokeObjectURL(imageFile.preview);
            setUploading(false);
            setAddImageActive(false);    
            setPoi(res.data);
            dispatch(setACrowdPoi(res.data));
            setSaving(false);
            setAddFeatureActive(false);
            setTimeout(() => { featureListRef.current.scrollIntoView() }, 100);
            enqueueSnackbar('Kiitos, kuva tallennettu ja odottaa tarkastusta!', {
              variant: 'success',
            });
          })
          .catch(err => {
            enqueueSnackbar('Kohteen tietojen haku epäonnistui.', {
              variant: 'warning',
            });
          });
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Kuvan tallennus epäonnistui.', {
          variant: 'error',
        })
        setUploading(false);
      })
  };

  const confirmAddFeature = (featureKey) => {
    setFeatureToBeAdded(featureKey);
    setConfirmAddFeatureDialogOpen(true);
  };

  const addFeature = (featureKey) => {
    if (!featureKey) return;

    const body = {
      feature: featureKey,      
    };

    setSaving(true);

    verttiApi.post(`crowdpoi/${poi.publicId}/addfeature`, body)
    .then((res) => {
      return verttiApi.get(`/crowdpoi/${poi.publicId}`)
        .then(res => {
          setPoi(res.data);
          dispatch(setACrowdPoi(res.data));
          setSaving(false);
          setAddFeatureActive(false);
          setTimeout(() => { featureListRef.current.scrollIntoView() }, 100);
          enqueueSnackbar('Kiitos, ominaisuus lisätty!', {
            variant: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar('Kohteen tietojen haku epäonnistui.', {
            variant: 'warning',
          });
        });
    })
    .catch(error => {
      console.error(error);
      enqueueSnackbar('Ominaisuuden lisäys epäonnistui.', {
        variant: 'error',
      });
      setSaving(false);
    });
  }


  return (
    <>
    <TabContext value={selectedTab}>
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{ sx: { bgcolor: '#FBF6F2', position: 'fixed', top: 0 } }}
    >
    <DialogTitle>
      {poi.name}
      <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
      </IconButton>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Kohteen välilehdet">
            <Tab label="Tiedot" value="1" />
            <Tab label="Lokikirja" value="2" />
          </TabList>
        </Box>
    </DialogTitle>

    {loading && (
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    )}

    {!loading && (
      <DialogContent>
        <TabPanel value='1' sx={{ padding: {xs: 0, md: 2} }}>
          {poi.photos && poi.photos.length > 0 && (
            <>
              <ImageCarousel images={poi.photos} updatePhotoLike={updatePhotoLike} displayDialog={displayDialog} />
              <Box display='flex' justifyContent='flex-end'>
                {!addImageActive && <Button size='small' onClick={() => handleSetAddImageActive(true)}>Lisää kuva</Button>}
              </Box>
            </>
          )}
          
          {(!poi.photos || poi.photos.length === 0) && (
            <>
              <Alert variant='info'>
                Meillä ei ole vielä kuvia kohteesta. Olisiko sinulla lisätä ensimmäinen?
              </Alert>
              {!addImageActive && (
                <Box display='flex' justifyContent='center'>
                  <Button size='small' variant='contained' onClick={() => handleSetAddImageActive(true)}>Lisää kuva</Button>
                </Box>
              )}
            </>
          )}
          <Box ref={photoUploadRef} />
          {addImageActive && (
            <Grow in={true}>
              <Box my={3}>
                <StyledDropzone setImageFile={setImageFile} imageFile={imageFile}/>
                {uploading && <Box mt={2}><LinearProgress variant="determinate" value={progress} /></Box>}
                <Box mt={1} display='flex' justifyContent='flex-end'>
                  <LoadingButton disabled={!imageFile} onClick={() => uploadImage()} loading={uploading} size='small' variant='contained'>Tallenna kuva</LoadingButton>
                </Box>
              </Box>
            </Grow>
          )}

          <Box mt={1} />
          <Box ref={featureListRef} />
          <FeatureList features={poi.features}/>
          {!addFeatureActive && (
              <Box display='flex' justifyContent='flex-end'>
                <Button size='small' onClick={() => handleSetAddFeatureActive(true)}>Lisää ominaisuus</Button>
              </Box>
          )}
          <Box ref={addFeatureRef}>
          {addFeatureActive && ( 
            <Grow in={true}>
              <Box my={3}>
                <AddFeatureList currentFeatures={poi.features} confirmAddFeature={confirmAddFeature}/>
              </Box>
            </Grow>
          )}
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: {xs: 0, md: 2} }}>
          <CrowdPoiComments crowdPoiId={poi.publicId} displayDialog={displayDialog} />
        </TabPanel>
      </DialogContent>
    )} {/* End of if !loading */}
    <DialogActions>
      <Button onClick={() => handleDialogClose()}>
        Sulje
      </Button>
    </DialogActions>
  </Dialog>
  </TabContext>
  {confirmAddFeatureDialogOpen && (
    <AddFeatureConfirmationDialog 
      open={confirmAddFeatureDialogOpen} 
      setOpen={setConfirmAddFeatureDialogOpen} 
      addFeature={addFeature}
      featureToBeAdded={featureToBeAdded}
    />
  )}
  </>
  )
};

export default withSnackbar(ViewCrowdPoiDialog);