import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const InformationPointPropertiesEditor = (props) => {
  const { feature, saveProperties } = props;

  const [pointType, setPointType] = useState(feature?.properties?.informationPointType);

  useEffect(() => {
    if (!feature?.id) return false;

    saveProperties({
      featureId: feature.id,
      property: 'informationPointType',
      values: pointType,
    });
  }, [pointType])

  // Update state if selection is changeg without deselecting first
  useEffect(() => {
    setPointType(feature?.properties?.informationPointType);
  }, [feature])

  const handlePointTypeChange = (typeName) => (event) => {
    setPointType(typeName);
  };

  const featureType = feature?.geometry?.type || false;

  if (!featureType) {
    return (
      <>
        n/a
      </>
    )
  }

  return (
    <>
        <Typography variant='body1'>Tietopallero</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction='column'>
              <FormControlLabel control={<Switch checked={pointType === 'buoyMooring'} onChange={handlePointTypeChange('buoyMooring')} />} label="Poiju" />
              <FormControlLabel control={<Switch checked={pointType === 'fingerMooring'} onChange={handlePointTypeChange('fingerMooring')} />} label="Aisa" />
              <FormControlLabel control={<Switch checked={pointType === 'polesMooring'} onChange={handlePointTypeChange('polesMooring')} />} label="Paalut" />
              <FormControlLabel control={<Switch checked={pointType === 'mooringlineMooring'} onChange={handlePointTypeChange('mooringlineMooring')} />} label="Mooring-köysi" />
              <FormControlLabel control={<Switch checked={pointType === 'anchorMooring'} onChange={handlePointTypeChange('anchorMooring')} />} label="Ankkuri" />
              <FormControlLabel control={<Switch checked={pointType === 'longsidesMooring'} onChange={handlePointTypeChange('longsidesMooring')} />} label="Kylkikiinnitys" />
            </Stack>
          </Grid>
        </Grid>
        <Box display='block'>
          <TextField fullWidth label='Lisätietoja' sx={{marginTop: '20px'}}></TextField>
        </Box>
    </>
  );
};

export default InformationPointPropertiesEditor;