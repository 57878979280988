import React, {useState, useEffect} from 'react';
// import { useNavigate } from "react-router-dom";
import { 
  Typography,
  Box,
  TextField,
  Alert,
  Pagination,
  LinearProgress,
} from '@mui/material';
import verttiApi from '../../api/verttiApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/fi'
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hooks/useAuth';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';


const CommentToolbar = ({ comment, updateCommentLike, displayDialog }) => {
  const auth = useAuth();
  const [likeLoading, setLikeLoading] = useState(false);

  const likeComment = (crowdPoiCommentId) => {
    if (likeLoading) return false;
    
    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }

    setLikeLoading(true);

    updateCommentLike(crowdPoiCommentId, true);

    verttiApi.get(`/crowdpoicomment/${crowdPoiCommentId}/like/1`)
      .then(res => {
        setLikeLoading(false);
      })
      .catch(err => {
        console.error(err);
        updateCommentLike(crowdPoiCommentId, false);
        setLikeLoading(false);
      })
  }

  const unLikeComment = (crowdPoiCommentId) => {
    if (likeLoading) return false;

    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }

    setLikeLoading(true);

    updateCommentLike(crowdPoiCommentId, false);

    verttiApi.get(`/crowdpoicomment/${crowdPoiCommentId}/like/0`)
      .then(res => {
        setLikeLoading(false);
      })
      .catch(err => {
        updateCommentLike(crowdPoiCommentId, true);
        setLikeLoading(false);
      })
  }

  return (
    <Box mt={1}>
      {comment.liked && 
        <ThumbUpAltIcon 
          style={{ color: '#3E7BBC', cursor: 'hand' }}
          onClick={() => unLikeComment(comment.publicId)}
        /> 
      }
      {!comment.liked && 
        <ThumbUpOffAltIcon 
          style={{cursor: 'hand'}}
          onClick={() => likeComment(comment.publicId)}
        /> 
      }
    </Box>

  );
}


const CommentBox = ({comment, updateCommentLike, displayDialog}) => {
  return (
    <Box mt={3} p={3} sx={{
      borderRadius: 2,
      boxShadow: 2,
      backgroundColor: '#fafafa',
    }}>
       <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant='commentHeader'>
            {comment.nickName}
          </Typography>
        </Box>
        <Box>
          <Typography variant='commentHeader'>
            {moment(comment.dateWritten).fromNow()}
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant='commentSubject'>{comment.subject}</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant='commentBody' sx={{whiteSpace: 'pre-line'}}>{comment.text}</Typography>
      </Box>
      <CommentToolbar comment={comment} updateCommentLike={updateCommentLike} displayDialog={displayDialog}/>
    </Box>
  );
}


const CrowdPoiComments = ({ crowdPoiId, displayDialog }) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [commentSent, setCommentSent] = useState(false);
  const [commentsPerPage, setCommentsPerPage] = useState(5);
  const [commentsPage, setCommentsPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const commentSchema = Yup.object().shape({
    subject: Yup.string()
      .required('Lisää otsikko')
      .min(10, 'Otsikko on liian lyhyt')
      .max(100, 'Otsikko on liian pitkä'),
    text: Yup.string()
      .required('Lisää teksti')
      .min(50, 'Teksi on liian lyhyt')
      .max(15000, 'Teksi on liian pitkä')
  });

  useEffect(() => {
    if (!crowdPoiId) return false;
    
    loadComments();
    
    return () => {
    };
  }, [])

  useEffect(() => {
    if (commentSent === true) {
      loadComments();
      formik.values.text = '';
      formik.values.subject = '';
      setCommentSent(false);
    }
    
  }, [commentSent]);

  useEffect(() => {
    loadComments();
  }, [commentsPage]);

  const loadComments = () => {
    setLoading(true);
    verttiApi.get(`/crowdpoi/${crowdPoiId}/comments/${commentsPage}/${commentsPerPage}`)
      .then(res => {
        const {comments, totalPages} = res.data;
        setComments(comments);
        setTotalPages(totalPages);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        enqueueSnackbar('Kohteen kommenttien haku epäonnistui.', {
          variant: 'warning',
        })
      })
  }

  const updateCommentLike = (commentId, newStatus) => {
    const newComments = comments.map((comment) => {
      if (comment.publicId === commentId) {
        return {
          ...comment,
          liked: newStatus,
        };
      }
      return comment;
    });
    
    setComments(newComments);
  };


  const handleCommentsPageChange = (event, value) => {
    setCommentsPage(value);
  }

  const formik = useFormik({
    initialValues: {
      crowdPoiId: crowdPoiId,
      subject: '',
      text: '',
    },
    validationSchema: commentSchema,
    onSubmit: values => {
      submitComment(values);
    }
  });

  const submitComment = (values) => {
    setSubmitting(true);

    const body = {
      subject: values.subject,
      text: values.text,
    }

    verttiApi.post(`/crowdpoi/${crowdPoiId}/comment`, body)
    .then((res) => {
      setSubmitting(false);
      setCommentSent(true);
    })
    .catch((err) => {
      setSubmitting(false);
      console.error(err);
    })
  }

  return (
    <>
      {comments.length === 0 && (
        <Typography>Kohteesta ei ole vielä merkintöjä. Haluatko jakaa kokemuksia?</Typography>
      )}
      <Pagination count={totalPages} page={commentsPage} onChange={handleCommentsPageChange} />
      
      {loading && 
        <Box py={10} sx={{width: '100%' }}>
         <LinearProgress />
        </Box>
      }

      {!loading && comments.map(comment => 
          <CommentBox comment={comment} key={comment.publicId} updateCommentLike={updateCommentLike} displayDialog={displayDialog} />
      )}

      <Box mt={1} />
      <Box mt={3} p={3} sx={{
          borderRadius: 2,
          boxShadow: 2,
          backgroundColor: '#fafafa',
      }}>
        <form onSubmit={(e) => {
          e.preventDefault();
          formik.validateForm().then(() => {
            if (formik.isValid) {
              formik.handleSubmit(e);
            }
          });
        }}>

        <Box mt={2} />
          <Typography>Jaa muille käyttäjille hyödyllistä tietoa kohteesta kirjoittamalla kokemuksistasi.</Typography>
          <Box mt={2} />

          {!auth.user && 
            <>
              <Alert severity='info'>Sinun tulee kirjautua sisään jakaaksesi tietoja.</Alert>
              <Box mt={2} />
            </>
          }

          {auth.user && !auth.user.nickName && 
            <>
              <Alert severity='info'>Jakaaksesi tietoja, sinun pitää ensin asettaa nimimerkki käyttäjätilin asetuksissa.</Alert>
              <Box mt={2} />
            </>
          }          

          <TextField
            id='subject'
            label='Otsikko'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
            disabled={!auth.user}
            sx={{width: '100%', background: 'white'}}
          />
          <Box mt={1} />
          {formik.touched['subject'] && formik.errors['subject'] && <Box><Typography variant='formError'>{formik.errors['subject']}</Typography></Box>}

          <Box mt={2} />
          <TextField
            id='text'
            label='Teksti'
            multiline
            minRows={5}
            maxRows={12}
            disabled={!auth.user}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.text}
            sx={{width: '100%', background: 'white'}}
          />
          <Box mt={1} />
          {formik.touched['text'] && formik.errors['text'] && <Box><Typography variant='formError'>{formik.errors['text']}</Typography></Box>}

          <Box mt={3} />
          <LoadingButton disabled={!auth.user || !auth.user.nickName ? true : false} type='submit' loading={submitting} variant='contained'>
            {submitting ? 'Pieni hetki...' : 'Lisää kommentti'}
            </LoadingButton>
          </form>
        </Box>
    </>
  )
};

export default withSnackbar(CrowdPoiComments);