import React, {useState, useEffect, useRef} from 'react';
import { Box, Grid, Button, Typography, useMediaQuery, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Alert } from '@mui/material';
import { useFormik } from 'formik';
import verttiApi from '../../../../api/verttiApi';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../../hooks/useAuth';
import * as Yup from 'yup';
import FormField from './FormField';

const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

const ContactSchema = Yup.object().shape({
  serviceProviderName: Yup.string()
    .min(4, 'Liian lyhyt nimi')
    .max(60, 'Liian pitkä nimi'),
  serviceProviderHarbourMaster: Yup.string()
    .min(6, 'Liian lyhyt yhteyshenkilön nimi')
    .max(60, 'Liian pitkä yhteyshenkilön nimi'),
  serviceProviderEmail: Yup.string()
    .email('Sähköpostiosoite vaikuttaa virheelliseltä'),
  serviceProviderPhone: Yup.string()
    .matches(phoneRegExp, 'Puhelinnumero vaikuttaa virheelliseltä, lisää myös maatunnus alkuun (esim. +358)')
    .min(6, 'Liian lyhyt puhelinnumero')
    .max(15, 'Liian pitkä puhelinnumero'),
  serviceProviderWww: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
  serviceProviderWebcam: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
  serviceProviderInstagram: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
  serviceProviderFacebook: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
  serviceProviderX: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
  serviceProviderTikTok: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
  serviceProviderYoutube: Yup.string()
    .url('Osoite vaikuttaa virheelliseltä. Varmista myös, että osoite alkaa https://'),
});

const HarborContact = (props) => {
  const { harbor, loadHarbor } = props;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const contactFormik = useFormik({
    initialValues: {
      serviceProviderName: harbor?.serviceProvider?.name ?? '',
      serviceProviderHarbourMaster: harbor?.serviceProvider?.harbourMaster ?? '',
      serviceProviderEmail: harbor?.serviceProvider?.email ?? '',
      serviceProviderPhone: harbor?.serviceProvider?.phone ?? '',
      serviceProviderWww: harbor?.serviceProvider?.www ?? '',
      serviceProviderWebcam: harbor?.serviceProvider?.webcam ?? '',
      serviceProviderSocialInstagram: harbor?.serviceProvider?.socialInstagram ?? '',
      serviceProviderSocialFacebook: harbor?.serviceProvider?.socialFacebook ?? '',
      serviceProviderSocialX: harbor?.serviceProvider?.socialX ?? '',
      serviceProviderSocialTiktok: harbor?.serviceProvider?.socialTiktok ?? '',
      serviceProviderSocialYoutube: harbor?.serviceProvider?.socialYoutube ?? '',
    },
    validationSchema: ContactSchema,
    enableReinitialize: true,
    onSubmit: values => {    
      updateHarbor(values);
    }
  });
  
  const updateHarbor = (values) => {
    //console.log(values);

    const body = {
      name: values.serviceProviderName,
      email: values.serviceProviderEmail,
      harbourMaster: values.serviceProviderHarbourMaster,
      phone: values.serviceProviderPhone,
      www: values.serviceProviderWww || null,
      webcam: values.serviceProviderWebcam || null,
      socialInstagram: values.serviceProviderSocialInstagram || null,
      socialFacebook: values.serviceProviderSocialFacebook || null,
      socialX: values.serviceProviderSocialX || null,
      socialTiktok: values.serviceProviderSocialTiktok || null,
      socialYoutube: values.serviceProviderSocialYoutube || null,
    };

    console.log('foo', values.serviceProviderSocialInstagram);
    console.log(body);

    verttiApi.patch(`manager/harbor/${harbor.publicId}/contacts`, body)
      .then((res) => {
        loadHarbor();
        enqueueSnackbar('Sataman yhteystiedot tallennettu.', { variant: 'success' });
        // setLoading(false);
      })
      .catch((err) => {
        console.error('Error updating harbor', err);
        enqueueSnackbar('Tietojen päivitys epäonnistui.', { variant: 'error' });
        // setLoading(false);
      }) 

  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => {
        contactFormik.validateForm().then(() => {
          if (!contactFormik.isValid) {
            enqueueSnackbar('Tarkista lomakkeen tiedot', {
              variant: 'warning',
            })      
          }
          contactFormik.handleSubmit(e);
        });
      }}>
      {contactFormik.dirty && <Alert severity='info'>Sinulla on tallentamattomia muutoksia.</Alert>}
      <Box pt={1}>
        <Button disabled={!contactFormik.dirty} type='submit' variant='contained'>Tallenna muutokset</Button>
      </Box>
      <Box pt={3} >
        <FormField fieldName='serviceProviderName' label='Palveluntarjoaja' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderHarbourMaster' label='Yhteyshenkilö' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderPhone' label='Puhelinnumero' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderEmail' label='Sähköposti' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderWww' label='Kotisivu' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderWebcam' label='Web-kameran osoite' formik={contactFormik}/>
      </Box>
      <Box pt={3}>
        <Typography variant='h6'>Sosiaalinen media</Typography>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderSocialInstagram' label='Instagram-osoite' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderSocialFacebook' label='Facebook-osoite' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderSocialX' label='X-palvelun osoite' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderSocialTiktok' label='Tiktok-osoite' formik={contactFormik}/>
      </Box>
      <Box pt={1} >
        <FormField fieldName='serviceProviderSocialYoutube' label='Youtube-osoite' formik={contactFormik}/>
      </Box>
      <Box pt={2}>
        <Button disabled={!contactFormik.dirty} type='submit' variant='contained'>Tallenna muutokset</Button>
      </Box>

    </form>
    </React.Fragment>
  );
}

export default withSnackbar(HarborContact);