import along from '@turf/along';
import { lineString } from '@turf/helpers';

const passageMiddlePoint = (passage, distance) => {
  var coordinates = [];

  passage.forEach(f => {
    f.geometry.coordinates.forEach(c => {
      coordinates.push([c[0], c[1]]);
    })
  });

  const halfDistance = distance / 2;
  const line = lineString(coordinates);
  const midPoint = along(line, halfDistance, {units: 'meters'})

  return midPoint.geometry.coordinates;
}

export default passageMiddlePoint;