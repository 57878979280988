import { createSlice } from '@reduxjs/toolkit';

export const harborsSlice = createSlice({
  name: 'harbors',
  initialState: { 
    value: {
      allHarbors: [],
      displayedHarbors: [],
      searchResultHarbor: {},
    }
  },
  reducers: {
    setHarbors: (state, action) => {
      state.value = action.payload;
    },
    setSearchResult: (state, action) => {
      state.value = {...state.value, searchResultHarbor: action.payload};
    },
  }
});

export const { setHarbors, setSearchResult } = harborsSlice.actions;
export default harborsSlice.reducer;