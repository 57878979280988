import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import verttiApi from '../../api/verttiApi';
import { withSnackbar, useSnackbar } from 'notistack';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import moment from 'moment';
import 'moment/locale/fi';

const HarborItem = ({harbor}) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={6}>
      <Box 
        p={2} 
        sx={{
          background: 'radial-gradient(circle at top left, #ffffff 0%, #f0f0f0 100%)',
          border: '2px solid #eeeeee', // Adjust border size as needed
          borderRadius: '8px', // For rounded corners
          boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Using a custom shadow for slight elevation
          // Hover effect: Increase shadow/elevation
          '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 4px 8px rgba(0,0,0,0.2)', // Adjust for more prominent elevation on hover
          },        
        }}
      onClick={() => navigate(`/manager/harbor/${harbor.publicId}`, {replace: true})}
      >
        <Typography variant='h6'>{harbor.name}</Typography>
        <Typography variant='caption'>Viimeksi päivitetty {moment(harbor.updatedAt).fromNow()}</Typography>
      </Box>
    </Grid>
  );
}

const Dashboard = (props) => {
  const auth = useAuth();
  const [harbors, setHarbors] = useState([]);

  useEffect(() => {
    verttiApi.get('manager/delegatedharbors')
    .then((res) => {
      setHarbors(res.data);
    })
    .catch((err) => {

    })
  }, []);

  return (
    <>
      <Typography variant='managerHeading'>Satamat</Typography>
      
      <Typography>Valitse alta satama, jonka tietoja haluat päivittää.</Typography>

      <Box mt={3} />
      <Grid container spacing={2}>
      {harbors.map(h => <HarborItem harbor={h} key={h.publicId} />)}
      </Grid>

    </>
  );
}

export default withSnackbar(Dashboard);