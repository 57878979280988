import React, {useState, useEffect} from 'react';
import { Box, Grid, Button, Typography, Modal, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, Stack, Avatar, Alert, TextField, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import septicImage from '../../assets/img/septi.png';
import verttiApi from '../../api/verttiApi';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SendIcon from '@mui/icons-material/Send';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useAuth } from '../../hooks/useAuth';
import { withSnackbar, useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/fi';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const StatusReport = ({ report }) => {
  const colorStart = report.status === 1 ? '#E8F9D2' : '#FBA7A7';
  const colorEnd = '#f0f0f0';

  return (
    <Box mb={1} p={1} gap={1} sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        border: '1px solid #cccccc', 
        borderRadius: '50vh', 
        background: `radial-gradient(circle at top left, ${colorStart} 0%, ${colorEnd} 100%)`, 
    }}>
      {report.status === 1 ?
        <Avatar sx={{ bgcolor: '#64A114' }}><SentimentSatisfiedAltIcon /></Avatar>
      :
        <Avatar sx={{ bgcolor: '#B00909' }}><SentimentVeryDissatisfiedIcon /></Avatar>
      }

      <Typography variant='body2'>
        Raportti {report.status === 1 ? 'onnistuneesta' : 'epäonnistuneesta'} tyhjennyksestä <strong>{moment(new Date(report.updatedUnix * 1000)).fromNow()}</strong> sitten.
      </Typography>
    </Box>
  );
}

const StatusReporter = ({ stationId, question, setReportSent, loadStation }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [draftReport, setDraftReport] = useState(null);
  const [questionAnswer, setQuestionAnswer] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleAnswerChange = (event) => {
    setQuestionAnswer(event.target.value);
  }

  const sendReport = () => {
    setSubmitting(true);

    verttiApi.post(`septic/${stationId}/report`, {
      status: draftReport,
      questionAnswer,
    })
    .then((res) => {
      enqueueSnackbar('Kiitos raportista!', { variant: 'success' });
      setReportSent(true);
      loadStation();
      setSubmitting(false);
    })
    .catch((err) => {
      enqueueSnackbar('Oho, raportin lähetys epäonnistui.', { variant: 'warning' });
      console.error(err);
      setSubmitting(false);
    });
  } 

  return (
    <Box 
        p={3} 
        sx={{
          background: 'radial-gradient(circle at top left, #ffffff 0%, #f0f0f0 100%)',
          border: '1px solid #eeeeee', // Adjust border size as needed
          borderRadius: '8px', // For rounded corners
          boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Using a custom shadow for slight elevation
          '&:hover': {
            boxShadow: '0px 4px 4px rgba(0,0,0,0.15)', // Adjust for more prominent elevation on hover
          },        
        }}
      >

      <Typography variant='h6'>Lähetä raportti aseman toiminnasta</Typography>
      <Typography variant='body2'>Vierailitko juuri tällä septityhjennysasemalla? Raportoi onnistuneesta tai epäonnistuneesta tyhjennyksestä. Raporteilla autat veneily-yhteisöä ja asemien ylläpitäjiä.</Typography>
      <Box mt={2} gap={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button disabled={draftReport !== null} onClick={() => setDraftReport(true)} variant='contained' color='success' startIcon={<ThumbUpIcon />}>Toimii</Button>
        <Button disabled={draftReport !== null} onClick={() => setDraftReport(false)} variant='contained' color='error' startIcon={<ThumbDownIcon />}>Epäkunnossa</Button>
      </Box>
      {draftReport !== null &&
        <Box mt={2}>
          <Typography variant='body2'>Vahvista vielä, että olet todennut imutyhjennyksen <strong>{draftReport ? 'onnistuneen' : 'epäonnistuneen'}</strong> tällä asemalla aivan lähiaikana.</Typography>
          {question && draftReport === false && (
            <Box my={1}>
              <Typography gutterBottom variant='body2'><strong>{question}</strong></Typography>
              <TextField fullWidth placeholder='Vastaus ylläolevaan kysymykseen' value={questionAnswer} onChange={handleAnswerChange} sx={{bgcolor: 'white'}}/>
            </Box>
          )}
          <Box mt={2} gap={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={submitting} variant='contained' onClick={() => sendReport()} startIcon={<SendIcon />}>Lähetä raportti</Button>
            <Button onClick={() => setDraftReport(null)}>Peru</Button>
          </Box>
        </Box>
      } 
    </Box>
  );
}

const SepticModal = (props) => {
  const { septicStationInfo, septicModalOpen, setSepticModalOpen } = props;
  const handleClose = () => setSepticModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [reportSent, setReportSent] = useState(false);
  const [stationInfo, setStationInfo] = useState({});
  const [displayAllReports, setDisplayAllReports] = useState(false);

  const toggleDisplayAllReports = () => {
    setDisplayAllReports(!displayAllReports);
  }

  const loadStation = () => {
    const { septicId } = septicStationInfo?.properties;

    verttiApi.get(`septic/${septicId}`)
    .then((res) => {
      setStationInfo(res.data);
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setLoading(false);
    })
  }

  useEffect(() => {
    loadStation();
  }, []);

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        PaperProps={{ sx: { width: "600px", bgcolor: '#FBF6F2' } }}
        open={septicModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          <img src={septicImage} alt="" height="20rem"/> {' '}{stationInfo?.stationData?.stationName}
        </BootstrapDialogTitle>

        <DialogContent dividers>

        <Typography gutterBottom variant='body1'>
          {stationInfo?.stationData?.stationDescription}
        </Typography>

        {loading && <LinearProgress />}

        {!loading && stationInfo?.usageData?.length < 1 && <Alert severity='info'>Viimeaikaisia käyttöraportteja ei löydy tälle asemalle.</Alert>}
        
        {!loading &&  stationInfo?.usageData?.slice(0, displayAllReports ? 10 : 3).map(report => <StatusReport key={report.updatedUnix} report={report} />)}

        {!loading &&  stationInfo?.usageData?.length > 3 && 
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
           <Button size='small' onClick={() => toggleDisplayAllReports()}>{displayAllReports === false ? 'Näytä lisää raportteja' : 'Näytä vähemmän'}</Button>
          </Box>
        }

        <Box mt={4} />

        {!loading && auth.user && !reportSent && <StatusReporter loadStation={loadStation} setReportSent={setReportSent} question={stationInfo?.stationData?.serviceBasicQuestion?.fi} stationId={stationInfo?.stationData?.septicId} />}
        {reportSent && <Alert severity="success">Kiitos raportista! Raportit auttavat veneilijöitä ja asemien ylläpitäjiä pitämään vesistömme puhtaana.</Alert>}
        {!auth.user && <Alert severity='info'>Sisäänkirjaudu raportoidaksesi käyntisi tyhjennysasemalla.</Alert>}

        {/* <Button onClick={() => openInNewTab(septicStationInfo.properties.url)}>
          Aseman tiedot Septit.netissä
        </Button> */}
        <Box mt={3}>
          <Typography color='#444444' variant='body2'>
            Septityhjennysasemmien tietoja ylläpitää Septit.net. Vertissä tehdyt raportit toimitetaan palveluun ilman käyttäjää yksilöiviä tietoja.
          </Typography>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default withSnackbar(SepticModal);