import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TextField, FormGroup, FormControlLabel, Checkbox, Button, Typography, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useAuth } from '../hooks/useAuth';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import verttiApi from '../api/verttiApi';
import * as yup from "yup";
import { useLocation } from "react-router-dom";

import '../App.css';

import bgImage from "../assets/img/loginbg.jpg";

function PasswordResetPage(props) {
  const auth = useAuth();
  let navigate = useNavigate();
  //let { token } = useParams();
  // const { window } = props;
  // const container = window !== undefined ? () => window().document.body : undefined;
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState('');
  const query = new URLSearchParams(useLocation().search);
  let token = query.get("t");

  const { formatMessage } = props.intl;

  // Check if token is present
  useEffect(() => {
    // if password reset token is present, let's check it
    console.log('Token', token)
    if (token) {
      setPhase('CHECKING_TOKEN');
      verttiApi.post('verifypasswordresetrequest', { token })
      .then((result) => {
        console.log('Token ok');
        setPhase('RESET_PASSWORD');
      })
      .catch((err) => {
        console.error('Token not ok');
        setPhase('TOKEN_NOT_OK');
      })
    }
    else {
      setPhase('REQUEST_PASSWORD_CHANGE');
    }
  }, [])

  const requestSchema = yup.object({
    email: yup
      .string()
      .email(formatMessage({ id: "passwordReset.error.missingEmail"}))
      .required(formatMessage({ id: "passwordReset.error.missingEmail"})),
  });

  const resetSchema = yup.object({
    password: yup
      .string()
      .required(formatMessage({ id: "register.error.invalidPassword"}))
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   formatMessage({ id: "register.error.invalidPassword"})
      // ),
  });

  const requestFormik = useFormik({
    validationSchema: requestSchema,
    initialValues: {
      email: '',
    },
    onSubmit: values => {    
      requestPasswordReset(values.email);
    }
  });

  const resetFormik = useFormik({
    validationSchema: resetSchema,
    initialValues: {
      password: '',
    },
    onSubmit: values => {    
      resetPassword(values.password);
    }
  });

  const requestPasswordReset = async (email) => {
    setErrorMessage('');
    setLoading(true);

    verttiApi.post('requestpasswordreset', { email })
      .then((result) => {
        console.log('Password reset requested', result.data.user);
        setPhase('RESET_REQUESTED');
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error requesting password change', err.response.data);
        setErrorMessage(formatMessage({ id: "passwordReset.error"}));
        setLoading(false);
      }) 
  };

  const resetPassword = async (password) => {
    setErrorMessage('');
    setLoading(true);

    verttiApi.post('updatepassword', { password, token })
      .then((result) => {
        console.log('Password updated', result.data.user);
        setPhase('PASSWORD_RESET_COMPLETE');
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error resetting password', err.response.data);
        setErrorMessage(formatMessage({ id: "passwordReset.error"}));
        setLoading(false);
      }) 
  };


  if (phase === 'CHECKING_TOKEN') return (
    <>
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={3} alignItems="center" justifyContent="center">
        <Box p={5} sx={{
              bgcolor: '#ffffff',
              minWidth: {xs: 250, sm: 400},
              maxWidth: {xs: 250, sm: 400},
              boxShadow: 3,
            }}>
          <CircularProgress />
        </Box>
      </Grid>   
    </Grid> 
    <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")",  }}    
    />
  </>
  );

  if (phase === 'TOKEN_NOT_OK') return (
    <>
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={3} alignItems="center" justifyContent="center">
        <Box p={5} sx={{
              bgcolor: '#ffffff',
              minWidth: {xs: 250, sm: 400},
              maxWidth: {xs: 250, sm: 400},
              boxShadow: 3,
            }}>
            <Typography variant="h4" component="h2">
              <FormattedMessage id="passwordReset.error.tokennok.heading"/>
            </Typography>
            <Box pt={3}>
              <Typography variant="body">
                <FormattedMessage id="passwordReset.error.tokennok.ingress"/>
              </Typography>
            </Box>
            <Box pt={3}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => window.location.href = '/passwordreset'}>
                  <FormattedMessage id="login.passwordForgotten"/>
                </Typography>
            </Box>
        </Box>
      </Grid>   
    </Grid> 
    <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")",  }}    
    />
  </>
  );

  if (phase === 'RESET_REQUESTED') return (
    <>
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={3} alignItems="center" justifyContent="center">
        <Box p={5} sx={{
              bgcolor: '#ffffff',
              minWidth: {xs: 250, sm: 400},
              maxWidth: {xs: 250, sm: 400},
              boxShadow: 3,
            }}>
            <Typography variant="h4" component="h2">
              <FormattedMessage id="passwordReset.resetRequested.heading"/>
            </Typography>
            <Box pt={3}>
              <Typography variant="body">
                <FormattedMessage id="passwordReset.resetRequested.ingress"/>
              </Typography>
            </Box>
          
        </Box>
      </Grid>   
    </Grid> 
    <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")",  }}    
    />
  </>
  );


  if (phase === 'PASSWORD_RESET_COMPLETE') return (
    <>
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={3} alignItems="center" justifyContent="center">
        <Box p={5} sx={{
              bgcolor: '#ffffff',
              minWidth: {xs: 250, sm: 400},
              maxWidth: {xs: 250, sm: 400},
              boxShadow: 3,
            }}>
            <Typography variant="h4" component="h2">
              <FormattedMessage id="passwordReset.successfull.heading"/>
            </Typography>
            <Box pt={3}>
              <Typography variant="body">
                <FormattedMessage id="passwordReset.successfull.ingress"/>
              </Typography>
            </Box>
            <Box pt={3}>
              <Button 
                  variant="contained" 
                  size="large"
                  onClick={() => navigate("../login", { replace: true })}
                >
                  <FormattedMessage id="passwordReset.successfull.button"/>
                </Button>
            </Box>
        </Box>
      </Grid>   
    </Grid> 
    <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")",  }}    
    />
  </>
  );

  if (phase === 'REQUEST_PASSWORD_CHANGE') return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >

        <Grid item xs={3}>
          
          <Box p={5} sx={{
            bgcolor: '#ffffff',
            minWidth: {xs: 250, sm: 400},
            maxWidth: {xs: 250, sm: 400},
            boxShadow: 3,
          }}>
            <Typography variant="h4" component="h2">
              <FormattedMessage id="passwordReset.heading"/>
            </Typography>
            <Box pt={3}>
              <Typography variant="body">
                <FormattedMessage id="passwordReset.request.ingress"/>
              </Typography>
            </Box>

            <form onSubmit={requestFormik.handleSubmit}>
              <Box pt={3} >
                <TextField
                  id='email'
                  label={formatMessage({ id: "general.emailAddress"})}
                  variant='outlined'
                  onChange={requestFormik.handleChange}
                  onBlur={requestFormik.handleBlur}
                  value={requestFormik.values.email}
                  sx={{width: '100%'}}/>
                  {requestFormik.touched.email && requestFormik.errors.email && (
                    <span>{requestFormik.errors.email}</span>
                  )}
              </Box>
              {errorMessage && (
                <Box pt={3}>
                  <p>{errorMessage}</p>
                </Box>
              )}
              <Box pt={3}>
                <Button 
                  variant="contained" 
                  size="large"
                  disabled={loading}
                  type="submit"
                >
                  <FormattedMessage id="passwordReset.request.submit"/>
                </Button>
              </Box>
              <Box pt={3}>
                <Typography variant="body2" style={{cursor:'pointer'}} onClick={() => navigate("../login", { replace: true })}>
                  <FormattedMessage id="passwordReset.backToLogin"/>
                </Typography>
              </Box>
            </form>
          </Box>
          
        </Grid>   
        
      </Grid> 
      <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")",  }}    
      />
    </>
  );

  if (phase === 'RESET_PASSWORD')  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >

        <Grid item xs={3}>
          
          <Box p={5} sx={{
            bgcolor: '#ffffff',
            minWidth: {xs: 250, sm: 400},
            maxWidth: {xs: 250, sm: 400},
            boxShadow: 3,
          }}>
            <Typography variant="h4" component="h2">
              <FormattedMessage id="passwordReset.heading"/>
            </Typography>
            <Box pt={3}>
              <Typography variant="body">
                <FormattedMessage id="passwordReset.reset.ingress"/>
              </Typography>
            </Box>

            <form onSubmit={resetFormik.handleSubmit}>
              <Box pt={3}>
                <TextField 
                  id='password' 
                  label={formatMessage({ id: "general.password"})}
                  variant='outlined'
                  onChange={resetFormik.handleChange}
                  onBlur={resetFormik.handleBlur}
                  value={resetFormik.values.password}
                  type='password'
                  sx={{width: '100%'}}/>
                  {resetFormik.touched.password && resetFormik.errors.password && (
                    <span>{resetFormik.errors.password}</span>
                  )}

              </Box>
              {errorMessage && (
                <Box pt={3}>
                  <p>{errorMessage}</p>
                </Box>
              )}
              <Box pt={3}>
                <Button 
                  variant="contained" 
                  size="large"
                  disabled={loading}
                  type="submit"
                >
                  <FormattedMessage id="passwordReset.reset.submit"/>
                </Button>
              </Box>
            </form>
          </Box>
          
        </Grid>   
        
      </Grid> 
      <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")",  }}    
      />
    </>
  );

  return <></>
}

export default injectIntl(PasswordResetPage);