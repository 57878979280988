import Analytics from 'analytics'
import simpleAnalyticsPlugin from '@analytics/simple-analytics'
import originalSrcPlugin from 'analytics-plugin-original-source'


const IS_DEV_ENV = process.env.REACT_APP_ENVIRONMENT === 'dev'
const IS_PROD_ENV = process.env.REACT_APP_ENVIRONMENT === 'production'

// dev only plugins
const devOnlyPlugins = [
    {
      name: 'logger',
      page: ({ payload }) => {
        console.log('page', payload)
      },
      track: ({ payload }) => {
        console.log('track', payload)
      },
      identify: ({ payload }) => {
        console.log('identify', payload)
      }
    },
]

const prodOnlyPlugins = [
    simpleAnalyticsPlugin(
        {
            hostname: 'app.vertti.io',
            customDomain: 'sa.vertti.io'
        }
    )
]

// both prod & dev plugins
const loadInAllEnvPlugins = [
    originalSrcPlugin(),
  ]

const plugins = [
    ...loadInAllEnvPlugins,
    ...(IS_DEV_ENV) ? devOnlyPlugins : [],
    ...(IS_PROD_ENV) ? prodOnlyPlugins : [],
  ]

if (IS_DEV_ENV) {
    console.log('Analytics plugins', plugins)
  }
  
const analytics = Analytics({
    app: 'vertti-app',
    plugins: plugins,
})

export default analytics;