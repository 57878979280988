import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { withSnackbar, useSnackbar } from 'notistack';
import { Typography, Box, LinearProgress, Container, Button } from '@mui/material';
import verttiApi from '../../api/verttiApi';
import { useAuth } from '../../hooks/useAuth';
import HarborMapEditor from './components/editHarbor/HarborMapEditor';
import HarborInfo from './components/editHarbor/HarborInfo';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HarborServices from './components/editHarbor/HarborServices';
import HarborContact from './components/editHarbor/HarborContact';
import PhotoManager from './components/editHarbor/PhotoManager';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ display: value !== index ? 'none' : 'block' }} // Hide inactive panels
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditHarbor = (props) => {
  const auth = useAuth();
  const [harbor, setHarbor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayMapEditor, setDisplayMapEditor] = useState(false);

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const { selectedHarborId } = props;

  useEffect(() => {
    loadHarbor();
  }, []);  

  const loadHarbor = () => {
    verttiApi.get(`/harbor/${selectedHarborId}`)
      .then((res) => {
        setHarbor(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      })
  }


  if (loading) return (
    <>
      <LinearProgress />
    </>
  );

  return (
    <React.Fragment>

    <Container sx={{bgcolor: 'white', borderRadius: '10px', padding: '10px'}}>
      <Typography variant='h3'>{harbor.name}</Typography>

      <Box>      
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="välilehdet"
        >
          <Tab label="Perustiedot" {...a11yProps(0)} />
          <Tab label="Palvelut" {...a11yProps(1)} />
          <Tab label="Yhteystiedot" {...a11yProps(2)} />
          <Tab label="Kuvat" {...a11yProps(3)} />
          <Tab label="Satamakartta" {...a11yProps(4)} />
        </Tabs>
      </Box>

      <TabPanel value={tab} index={0}>
        <HarborInfo harbor={harbor} loadHarbor={loadHarbor} /> 
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <HarborServices harbor={harbor} loadHarbor={loadHarbor} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <HarborContact harbor={harbor} loadHarbor={loadHarbor} />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <PhotoManager harbor={harbor} loadHarbor={loadHarbor} />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        {!displayMapEditor && <Button variant='contained' onClick={() => setDisplayMapEditor(true)}>Avaa karttaeditori</Button>}
        {displayMapEditor && <HarborMapEditor harborId={selectedHarborId} initialLng={harbor.lngLat[0]} initialLat={harbor.lngLat[1]} />}
      </TabPanel>

    </Container>
    </React.Fragment>
  );

}

export default withSnackbar(EditHarbor);