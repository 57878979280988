const routeTotalDistance = (waypoints) => {
  if (!waypoints || !Array.isArray(waypoints)) return 0;
  
  var distance = 0;

  for (var wp of waypoints) {
    if (!wp.passage || !Array.isArray(wp.passage)) continue;
  
    for (var p of wp.passage) {
      distance += p.properties.distance;
    }
  }

  return distance;
}

export default routeTotalDistance;