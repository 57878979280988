import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user';
import newRoutePlanReducer from '../features/routePlan/newRoutePlanSlice';
import harborsReducer from '../features/harbors';
import weatherStationsReducer from '../features/weatherStationsSlice';
import aisTargetsReducer from '../features/aisTargetsSlice';
import crowdPoisReducer from '../features/crowdPois';

export const store = configureStore({
  reducer: {
    user: userReducer,
    newRoutePlan: newRoutePlanReducer,
    harbors: harborsReducer,
    weatherStations: weatherStationsReducer,
    crowdPois: crowdPoisReducer,
    aisTargets: aisTargetsReducer,
  }
})