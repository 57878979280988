import { createSlice } from '@reduxjs/toolkit';
import { septicLayer } from '../components/map/mapLayers';

export const userSlice = createSlice({
  name: 'user',
  initialState: { 
    value: {
      id: '',
      name: '',
      email: '',
      role: '',
      threeDimensionalMapEnabled: false,
    },
    layerSettings: {
      'weather': true,
      'harbor': true,
      'crowdPoi': true,
      'septic': true,
      'ais': true,
      'ice': false,
      'heatMap': false,
    },
    addCrowdPoiActive: false,
    mapFlyTo: [],
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    setThreeDimensionalMapEnabled: (state, action) => {
      state.value = {...state.value, threeDimensionalMapEnabled: action.payload};
    },
    toggleLayer: (state, action) => {
      state.layerSettings[action.payload] = !state.layerSettings[action.payload];
    },
    setMapFlyTo: (state, action) => {
      state.mapFlyTo = action.payload;
    },
    setAddCrowdPoiActive: (state, action) => {
      state.addCrowdPoiActive = action.payload;
    }
  }
});

export const { setThreeDimensionalMapEnabled, toggleLayer, setMapFlyTo, setAddCrowdPoiActive } = userSlice.actions;
export default userSlice.reducer;