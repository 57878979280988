import turfDistance from '@turf/distance';
import { point as turfPoint } from '@turf/helpers';

const manualLeg = ({ sourceLat, sourceLng, destinationLat, destinationLng }) => {
  const distance = 1000 * turfDistance(turfPoint([sourceLng, sourceLat]), turfPoint([destinationLng, destinationLat]));
  const geoJson = [
      {
        type: 'Feature',
        properties: {
          distance,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [sourceLng, sourceLat],
            [destinationLng, destinationLat]
          ]
        }
      }
    ];

  return {geoJson, distance};
}

export default manualLeg;