import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Autocomplete, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchResult } from '../../features/harbors';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import verttiApi from '../../api/verttiApi';
import debounce from 'lodash.debounce';
import { setMapFlyTo } from '../../features/user';

const GenericSearch = (props) => {
  const dispatch = useDispatch();
  const reduxHarbors = useSelector(state => state.harbors.value);
  const crowdPois = useSelector(state => state.crowdPois.value);
  const [searchMode, setSearchMode] = useState('harbors');
  const [searchOptions, setSearchOptions] = useState([]);
  const [value, setValue] = useState('');
  const [searchOptionsMessage, setSearchOptionsMessage] = useState(null);
  const inputRef = useRef(null);
  const { handleDialogClose } = props;

  // Init search
  useEffect(() => {
    setSearchOptions(Array.isArray(reduxHarbors.allHarbors) ? reduxHarbors.allHarbors.map((harbor) => {return { id: harbor._id, label: `${harbor.name} ${harbor.location}` }}) : []);
  }, []);

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#cccccc', 0.45),
    '&:hover': {
      backgroundColor: alpha('#cccccc', 0.35),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      fontSize: '20px',
      padding: theme.spacing(2, 2, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
      // width: '100%',
      // [theme.breakpoints.up('xs')]: {
      //   width: '2ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  }));

  const handleChange = (event, newValue) => {
    if (newValue === null) {
      return;
    }
    setValue('');
    
    setSearchMode(newValue);
    if (newValue === 'harbors') {
      setSearchOptions(Array.isArray(reduxHarbors.allHarbors) ? reduxHarbors.allHarbors.map((harbor) => {return { id: harbor._id, label: `${harbor.name} ${harbor.location}` }}) : []);
    }
    else if (newValue === 'crowdPois') {
      setSearchOptions(Array.isArray(crowdPois.allCrowdPois) ? crowdPois.allCrowdPois.map((cp) => {return { id: cp.publicId, label: `${cp.name}` }}) : []);
    }
    else {
      setSearchOptions([]);
    }

    setSearchOptionsMessage(null);
  };

  // const debouncedQuery = useMemo(
  //   (args) => debounce((args) => verttiApi('/searchPlaces', {params: {query: args.query}}).then(result => {
  //     console.log('debounced result')
  //     var options = [];
  //     for (const [i, el] of result.data.entries()) {
  //       options.push({
  //         id: i,
  //         label: `${el.name} (${el.municipality})`,
  //         lngLat: el.lngLat
  //       });
  //     }
  //     setSearchOptions(options);      
  //   }), 500)
  // , []);

  const debouncedQuery = useCallback(debounce((query) => {
    verttiApi('/searchPlaces', { params: { query: query } })
      .then(result => {
        var options = result.data.map((el, i) => ({
          id: i,
          label: `${el.name} (${el.municipality})`,
          lngLat: el.lngLat
        }));
        setSearchOptions(options);
      })
      .catch(error => {
        console.error("Error fetching search results:", error);
        setSearchOptionsMessage("Error fetching results.");
      });
  }, 500), []);

  useEffect(() => {
    return () => {
      debouncedQuery.cancel(); // Cancel the debounced call if the component unmounts
    };
  }, [debouncedQuery]);

  const handleInputChange = (event, newInputValue) => {
    if (searchMode === 'harbors' || searchMode === 'crowdPois') return;
    if (newInputValue.length > 2) {
      debouncedQuery(newInputValue);
    }
  };

  // const handleInputChange = (e, query) => {
  //   if (searchMode === 'harbors' || searchMode === 'crowdPois') return;

  //   if (query.length > 2) {
  //     console.log('init debounce')
  //     debouncedQuery({query});
  //   }

  // }

//  const searchOptions = Array.isArray(reduxHarbors.allHarbors) ? reduxHarbors.allHarbors.map((harbor) => {return { id: harbor._id, label: `${harbor.name} ${harbor.location}` }}) : [];

  return (
    <>
      <Box mb={2}>
        <ToggleButtonGroup size="small" exclusive value={searchMode} onChange={handleChange}>
          <ToggleButton value="harbors">Satamat</ToggleButton>
          <ToggleButton value="crowdPois">Veneilykohteet</ToggleButton>
          <ToggleButton value="places">Karttakohteet</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Autocomplete
        key='searchac'
        freeSolo
        id="search-autocomplete"
        handleHomeEndKeys
        clearOnBlur
        clearOnEscape
        selectOnFocus
        value={value}
        onInputChange={handleInputChange}
        options={searchOptions}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
        }
        onChange={(event, selection) => {
          if (!selection) return;
          setValue(selection.label);
          if (searchMode === 'harbors') {
            if (!selection || !selection.id) return false;
            const [resultHarbor] = reduxHarbors.allHarbors.filter((harbor) => harbor._id === selection.id);
            dispatch(setSearchResult({...resultHarbor}));
            handleDialogClose();
          } 
          if (searchMode === 'crowdPois') {
            if (!selection || !selection.id) return false;
            const [resultCrowdPoi] = crowdPois.allCrowdPois.filter((crowdPoi) => crowdPoi.publicId === selection.id);
            dispatch(setSearchResult({...resultCrowdPoi}));
            handleDialogClose();
          } 
          else {
            if (selection.lngLat) { // || selection.lngLat.length === 2) {
              dispatch(setMapFlyTo(selection.lngLat));
              handleDialogClose();
            }
          }
        }}
        renderInput={(params) => {
          const {InputLabelProps,InputProps,...rest} = params;
          return (
            <Search key='searchInputField'>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                key='styledInputBase'
                placeholder={
                  searchMode === 'harbors' ? "Hae satamaa" : 
                  searchMode === 'crowdPois' ? "Hae veneilykohdetta" : 
                  "Hae kartalta" // This is for 'places' or any other case
                }
                inputProps={{ 'aria-label': 'search' }}
                {...params.InputProps} {...rest}
              />
            </Search>
          )
        }}
      />
    </>
  );
};

export default GenericSearch;