import verttiApi from '../../../api/verttiApi';

const reachArea = async ({ sourceLat, sourceLng, vesselDraft, distance }) => {
  sourceLat = parseFloat(sourceLat);
  sourceLng = parseFloat(sourceLng);

  if (
    (isNaN(sourceLat) || sourceLat > 90 || sourceLat < -90) ||
    (isNaN(sourceLng) || sourceLng > 180 || sourceLng < -180)
  ) {
    return {
      errorCode: 'illegal parameters'
    };
  }

  

  const result = await verttiApi.post('findreachablearea', { sourceLat, sourceLng, vesselDraft, distance });

  return(result.data);
}

export default reachArea;