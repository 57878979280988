const propertyWindVelocity = 'ws_10min';
const propertyWindDirection = 'wd_10min';

const generateWeatherStationsGeoJSON = (stations) => {
  var weatherStationsGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  if (!stations || stations.length < 1) return weatherStationsGeoJSON;

  for (var station of stations) {
    var [windVelocityObj] = station.latestObservations.filter(e => e.property === propertyWindVelocity);
    var windVelocity = windVelocityObj ? windVelocityObj.value : null;
    windVelocity = windVelocity === 'NaN' ? null : Number(windVelocity);

    var [windDirectionObj] = station.latestObservations.filter(e => e.property === propertyWindDirection);
    var windDirection = windDirectionObj ? windDirectionObj.value : null;
    windDirection = windDirection === 'NaN' ? null : Number(windDirection);

    // icons point to 270 and barbs should point to the opposite direction of 
    var iconRotation = windDirection !== null ? windDirection + 180 : 0;

    iconRotation = iconRotation > 360 ? iconRotation - 360 : iconRotation;

    var windLabel = windVelocity ? `${windVelocity.toLocaleString('fi-FI')}` : '-';

    var icon;
    if (windDirection === null) icon = 'otherRing';
    else icon = 'windRing';

    // else {
    //   const wcKn = windVelocity * 1.943844;
    //   if (wcKn >= 0 && wcKn < 2.5) icon = 'windBarbCalm';
    //   else if (wcKn >= 2.5 && wcKn < 7.5) icon = 'windBarb5';
    //   else if (wcKn >= 7.5 && wcKn < 12.5) icon = 'windBarb10';
    //   else if (wcKn >= 12.5 && wcKn < 17.5) icon = 'windBarb15';
    //   else if (wcKn >= 17.5 && wcKn < 22.5) icon = 'windBarb20';
    //   else if (wcKn >= 22.5 && wcKn < 27.5) icon = 'windBarb25';
    //   else if (wcKn >= 27.5 && wcKn < 32.5) icon = 'windBarb30';
    //   else if (wcKn >= 32.5 && wcKn < 37.5) icon = 'windBarb35';
    //   else if (wcKn >= 37.5 && wcKn < 42.5) icon = 'windBarb40';
    //   else if (wcKn >= 42.5 && wcKn < 47.5) icon = 'windBarb45';
    //   else if (wcKn >= 47.5 && wcKn < 52.5) icon = 'windBarb50';
    //   else if (wcKn >= 52.5 && wcKn < 57.5) icon = 'windBarb55';
    //   else if (wcKn >= 57.5 && wcKn < 62.5) icon = 'windBarb60';
    //   else if (wcKn >= 62.5 && wcKn < 67.5) icon = 'windBarb65';
    //   else if (wcKn >= 67.5 && wcKn < 72.5) icon = 'windBarb70';
    //   else if (wcKn >= 72.5 && wcKn < 77.5) icon = 'windBarb75';
    //   else if (wcKn >= 77.5 && wcKn < 82.5) icon = 'windBarb80';
    //   else if (wcKn >= 82.5 && wcKn < 87.5) icon = 'windBarb85';
    //   else if (wcKn >= 87.5 && wcKn < 92.5) icon = 'windBarb90';
    //   else if (wcKn >= 92.5 && wcKn < 97.5) icon = 'windBarb95';
    //   else if (wcKn >= 98.5) icon = 'windBarb100';
    // }

    // Add waypoint as Point feature
    weatherStationsGeoJSON.features.push({
      type: "Feature",
      properties: {
        id: station.publicId,
        stationName: station.name,
        windVelocity,
        windLabel,
        windDirection,
        iconRotation,
        icon,
      },
      geometry: {
        coordinates: [station.lngLat[0], station.lngLat[1]],
        type: "Point",
      }
    });

  }

  return (weatherStationsGeoJSON);
}

export default generateWeatherStationsGeoJSON;