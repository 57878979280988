import { SvgIcon } from "@mui/material";

import { ReactComponent as Icon } from '../../assets/img/poifeatureicons/barbecue.svg';
const BarbecueIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 800 800">
            <Icon />
        </SvgIcon>
    );
}

export default BarbecueIcon;