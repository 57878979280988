import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { withSnackbar, useSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography, Grid, Paper } from '@mui/material';
import CrowdPoiPhotoManager from './components/CrowdPoiPhotoManager';



const CrowdPoiManager = (props) => {

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant='managerHeading'>Veneilykohteet</Typography>
            <CrowdPoiPhotoManager />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default withSnackbar(CrowdPoiManager);