import { createSlice } from '@reduxjs/toolkit';

export const crowdPoisSlice = createSlice({
  name: 'crowdPois',
  initialState: { 
    value: {
      allCrowdPois: [],
      displayedCrodPois: [],
      searchResultCrowdPoi: {},
    }
  },
  reducers: {
    setCrowdPois: (state, action) => {
      state.value = action.payload;
    },
    setACrowdPoi: (state, action) => {
      // Replace one crowdPoi with an updated one
      var newCrowdPois = state.value.allCrowdPois.filter(p => p.publicId !== action.payload.publicId);
      newCrowdPois.push(action.payload);
      state.value = {...state.value, allCrowdPois: newCrowdPois};
    },
    setCrowdPoiSearchResult: (state, action) => {
      state.value = {...state.value, searchResultCrowdPoi: action.payload};
    },
  }
});

export const { setCrowdPois, setCrowdPoiSearchResult, setACrowdPoi } = crowdPoisSlice.actions;
export default crowdPoisSlice.reducer;