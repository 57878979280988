import axios from 'axios';

let apiUrl = process.env.REACT_APP_VERTTI_API_URL;

const instance = axios.create({
   baseURL: apiUrl,
});
  
instance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
// console.log(accessToken);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    
    if (error.response.status === 401 && originalRequest.url === `${apiUrl}/refresh`) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refreshToken');
      const user = JSON.parse(localStorage.getItem('user'));

      return axios.post(`${apiUrl}/refresh`, {
        userId: user._id,
        refreshToken,
      })
      .then(res => {
        if (res.status === 201) {
          localStorage.setItem('accessToken', res.data.accessToken);
          originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`;
          //axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
          return axios(originalRequest);
        }
        else { // log out if refresh request returns anything else than 201
          console.log('Token refresh failed, logging out', res.status);
          localStorage.clear();
          window.location.href = '/login';
        }
      })
      .catch(err => {
        console.log('Token refresh failed, logging out', err);
        localStorage.clear();
        window.location.href = '/login';
      })

    }
    return Promise.reject(error);
  }
);

export default instance;