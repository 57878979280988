import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { withSnackbar, useSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from 'react-intl';
import verttiApi from '../../api/verttiApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import Paper from '@mui/material/Paper';

import { CircularProgress } from '@mui/material';

const columns = [
  {
    width: 150,
    label: 'Sähköposti',
    dataKey: 'email',
  }
];

const UserManager = (props) => {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getUserList = () => {
    setLoading(true);
    verttiApi.get('manager/users')
    .then((res) => {
      setUserList(res.data);
    })
    .catch((err) => {
      console.error('Error loading users', err);
      enqueueSnackbar('Käyttäjätietojen haku epäonnistui.', { variant: 'error' });
      setLoading(false);
    }) 
  }

  useEffect(() => {
    getUserList();
  }, []);
  
  useEffect(() => {
    setLoading(false);
  }, [userList]);

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };
  
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  
  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? 'right' : 'left'}
          >
            {row[column.dataKey]}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }


  if (loading) return <CircularProgress />

  return (
    <>
      <TableVirtuoso
        data={userList}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </>
  )
}

export default injectIntl(withSnackbar(UserManager));