import * as React from 'react';
import { Box, Stack, Grid, Button, Typography, Modal, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, Card, CardHeader, Avatar, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import WindPowerIcon from '@mui/icons-material/WindPower';
import ExploreIcon from '@mui/icons-material/Explore';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import moment from 'moment';
import 'moment/locale/fi'

import fmiologo from '../../assets/img/externallogos/fmiodata.png';

import analytics from '../../analytics';

const localizeWeatherObservation = (data, attribute) => {
  const [observation] = data.filter(e => e.property === attribute);
  if (!observation) return {measuredAt: '-', value: '-'};
  
  return ({
    measuredAt: moment(observation.measuredAt).fromNow(),
    value: observation.value && observation.value != 'NaN' ? Number(observation.value).toLocaleString('fi-FI') : '-'
  });
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const WeatherModal = (props) => {
  const { weatherStation, weatherModalOpen, setWeatherModalOpen } = props;
  const handleClose = () => setWeatherModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!weatherStation) return null;

  const windVelocity = localizeWeatherObservation(weatherStation.latestObservations, 'ws_10min');
  const windGust = localizeWeatherObservation(weatherStation.latestObservations, 'wg_10min');
  const windDirection = localizeWeatherObservation(weatherStation.latestObservations, 'wd_10min');
  const airTemperature = localizeWeatherObservation(weatherStation.latestObservations, 't2m');

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        PaperProps={{ sx: { width: "600px", bgcolor: '#FBF6F2'} }}
        open={weatherModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          {weatherStation.name}
        </BootstrapDialogTitle>
        <DialogContent>
          <Stack direction='row' spacing={2} justifyContent="center">
            <Card>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#17C8EB' }} aria-label="Tuulen nopeus">
                    <WindPowerIcon />
                  </Avatar>
                }
                title='Tuulen nopeus'
                subheader={windVelocity.measuredAt}
              />
              <CardContent sx={{textAlign: 'center'}}>
                <Typography variant='weatherValue'>{windVelocity.value}</Typography>
                {' '}
                <Typography variant='weatherUnit'>m/s</Typography>
              </CardContent>
            </Card>

            <Card>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#17C8EB' }} aria-label="Tuulen puuska">
                    <WindPowerIcon />
                  </Avatar>
                }
                title='Tuulen puuska'
                subheader={windGust.measuredAt}
              />
              <CardContent sx={{textAlign: 'center'}}>
                <Typography variant='weatherValue'>{windGust.value}</Typography>
                {' '}
                <Typography variant='weatherUnit'>m/s</Typography>
              </CardContent>
            </Card>
          </Stack>

          <Stack direction='row' spacing={2} mt={2} justifyContent="center">
            <Card>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#17C8EB' }} aria-label="Tuulen suunta">
                    <ExploreIcon />
                  </Avatar>
                }
                title='Tuulen suunta'
                subheader={windDirection.measuredAt}
              />
              <CardContent sx={{textAlign: 'center'}}>
                <Typography variant='weatherValue'>{windDirection.value}°</Typography>

              </CardContent>
            </Card>

            <Card>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#17C8EB' }} aria-label="Ilman lämpötila">
                    <ThermostatIcon />
                  </Avatar>
                }
                title='Ilman lämpötila'
                subheader={airTemperature.measuredAt}
              />
              <CardContent sx={{textAlign: 'center'}}>
                <Typography variant='weatherValue'>{airTemperature.value}</Typography>
                {' '}
                <Typography variant='weatherUnit'>°C</Typography>
              </CardContent>
            </Card>

          </Stack>
          
          <Box justifyContent="center" mt={4}>
            <img src={fmiologo} alt="Suomen ilmatieteen laitoksen avoin data" height='20px'/>
          </Box>
        </DialogContent>
      </BootstrapDialog>
  );
}

export default injectIntl(WeatherModal);


/*

          {displayWeatherPopup && weatherPopupPosition.lng && weatherPopupPosition.lat && (
          <Popup longitude={weatherPopupPosition.lng} latitude={weatherPopupPosition.lat}
            anchor="top"
            closeButton={true}
            onClose={() => setDisplayWeatherPopup(false)}>
              <Typography variant='weatherHeading'>{selectedWeatherStation.name}</Typography>
              <Stack direction='row'>
                <Box m={3}>
                  <Typography variant='weatherLabel'>Tuulen nopeus</Typography>
                  <Typography variant='weatherValue'>{localizeWeatherObservation(selectedWeatherStation.latestObservations, 'ws_10min')} m/s</Typography>
                </Box>
                <Box m={3}>
                  <Typography>Puuska</Typography>
                  <Typography>{localizeWeatherObservation(selectedWeatherStation.latestObservations, 'wg_10min')} m/s</Typography>
                </Box>
              </Stack>
          </Popup>)}


*/