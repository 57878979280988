const tourSteps = [
  {
    target: '.route-edit-mode',
    content: 'Tästä valitset reititystilan. Uusi reittipiste lisätään painamalla karttaa pitkään.',
  },
  {
    target: '.add-crowd-poi',
    content: 'Tästä voit lisätä uuden veneilykohteen kartalle.',
  },
  {
    target: '.display-reach-area',
    content: 'Voit näyttää väyliä pitkin saavutettavan alueen ja asettaa tavoitepituuden.',
  },
  {
    target: '.search-menu',
    content: 'Tätä kautta voit etsiä satamia, veneilykohteita tai saaria.',
  },
  {
    target: '.layers-menu',
    content: 'Täällä pääset valitsemaan kartalla näytettävät kerrokset.',
  },
  {
    target: '.main-menu',
    content: 'Täältä löydät monia toimintoja, kuten reittisuunnitelma-arkiston.',
  },
];

export default tourSteps;