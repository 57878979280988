import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { round } from '@turf/helpers';
import VerttiMap from '../components/map/VerttiMap2';
import { useAuth } from '../hooks/useAuth';
import debounce from 'lodash.debounce';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import MapDialog from '../components/map/MapDialog';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import RouteIcon from '@mui/icons-material/Route';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import RoutePlanManagerModal from '../components/routePlan/RoutePlanManagerModal';
import LicenseModal from '../components/map/LicenseModal';
import TutorialModal from '../components/map/TutorialModal';
import VesselConfigurator from '../components/map/VesselConfigurator';

import { useDispatch } from 'react-redux';
import { saveRoutePlan, newRoutePlan, groupWaypoints, breakWaypointGroup } from '../features/routePlan/newRoutePlanSlice';
import { setThreeDimensionalMapEnabled, toggleLayer } from '../features/user';

import VerttiLogo from '../assets/img/vertti-logo-white.svg';
import VerttiBrandMark from '../assets/img/vertti-brandmark-white.svg';
// import VerttiLogoXs from '../assets/img/vertti-logo.svg';

// import HarborSearch from '../components/harbor/HarborSearch';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded';
import JoinFullRoundedIcon from '@mui/icons-material/JoinFullRounded';
import JoinInnerRoundedIcon from '@mui/icons-material/JoinInnerRounded';
import Tooltip from '@mui/material/Tooltip';
// import { v4 as uuidv4 } from 'uuid';
// import { ConstructionOutlined, ThreeDRotation } from '@mui/icons-material';
// import { array } from 'yup';

import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudIcon from '@mui/icons-material/Cloud';
import LayersIcon from '@mui/icons-material/Layers';
import { Button, Stack } from '@mui/material';
import SearchDialog from '../components/map/SearchDialog';
// import DisclaimerModal from '../components/map/DisclaimerModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import SantaDisclaimerModal from '../components/map/SantaDisclaimerModal';
import UserEditor from '../components/map/UserEditor';
import DownloadGPXDialog from '../components/map/DownloadGPXDialog';

// import { original } from '@reduxjs/toolkit';
// import Draggable, {DraggableCore} from 'react-draggable';

const drawerWidth = 300;
const appBarHeight = 60;

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

function MapPage(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //const route = useSelector(state => state.routePlan.value);
  const vesselDraft = useSelector(state => state.newRoutePlan.vesselDraft);
  const vesselSpeed = useSelector(state => state.newRoutePlan.vesselSpeed);
  const user = useSelector(state => state.user.value);
  const layerSettings = useSelector(state => state.user.layerSettings);
  const waypoints = useSelector(state => state.newRoutePlan.waypoints);
  const routeDistance = useSelector(state => state.newRoutePlan.distance);
  const savingStatus = useSelector(state => state.newRoutePlan.savingStatus);

  const auth = useAuth();

  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const handleSearchDialogClose = () => {
    setSearchDialogOpen(false);
  };

  const [dialogBody, setDialogBody] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogButtonLabel, setDialogButtonLabel] = useState(null);
  const [dialogLoginCta, setDialogLoginCta] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [GPXdialogOpen, setGPXDialogOpen] = useState(false);
  const handleGPXDialogClose = () => {
    setGPXDialogOpen(false);
  };
  const [wizardOpen, setWizardOpen] = useState(false);
  const handleWizardClose = () => {
    setDialogOpen(false);
  };
  
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState(undefined);
  const [routePlanManagementModalOpen, setRoutePlanManagementModalOpen] = useState(false);
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const [vesselConfiguratorOpen, setVesselConfiguratorOpen] = useState(false);
  const [tutorialModalOpen, setTutorialModalOpen] = useState(false);
  const [selectedLegs, setSelectedLegs] = useState([]);
  const [selectedLegIndeces, setSelectedLegIndeces] = useState([]);

  const [ editUserDialogOpen, setEditUserDialogOpen ] = useState(false);
  const handleEditUserDialogClose = () => {
    setEditUserDialogOpen(false);
  }

  // Monitor saving status
  useEffect(() => {
    if (savingStatus === 'changed') debouncedUpdateServerRoutePlan();
  }, [savingStatus]);


  const debouncedUpdateServerRoutePlan = useMemo(
    (args) => debounce((args) => updateServerRoutePlan(), 2000)
  , []);


  const updateServerRoutePlan = () => {
    dispatch(saveRoutePlan({}));
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [layerMenuAnchorEl, setLayerMenuAnchorEl] = React.useState(null);

  const triggerNewRoutePlan = () => {
    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }

    localStorage.removeItem('activeRoutePlanId');
    dispatch(newRoutePlan());
    setSelectedLegs([]);
    setSelectedLegIndeces([]);
  }


  const handleVesselConfiguratorClose = () => {
    setVesselConfiguratorOpen(false);
  };


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLayerMenu = (event) => {
    setLayerMenuAnchorEl(event.currentTarget);
  };

  const handleLayerMenuClose = () => {
    setLayerMenuAnchorEl(null);
  };

  const toggleStateLayer = (layerName) => {
    dispatch(toggleLayer(layerName));
  }

  const displayCoordinate = (value, latlng) => {
    const number = Math.abs(value); // Change to positive
    const integerPart = Math.floor(number);
    const decimalPart =  number - Math.floor(number);
    const minutes = round(decimalPart * 60, 2);
    var postfix;
    // FIXME: handle western and southern coordinates
    if (latlng === 'lat') postfix = 'N';
    else postfix = 'E';
    return `${integerPart}° ${minutes.toLocaleString('fi-FI')}' ${postfix}`;
  };

  const toggleLegSelection = (segmentId, index) => {
    // Create a copy of state array
    var newSelectedLegs = selectedLegs.slice();
    var newSelectedLegIndeces = selectedLegIndeces.slice();

    // check that selections are adjacent (based on route elements index [there are points too])
    if (selectedLegs.length > 0) {
      var ok = false;
      
      // Leg is previously selected
      if (selectedLegIndeces.includes(index)) {
        // let's check that we're not removing from the middle
        if (!(selectedLegIndeces.includes(index+2) && selectedLegIndeces.includes(index-2))) {
          ok = true;
        }
      }
      // Otherwise let's check that either side has been selected previously
      else if (selectedLegIndeces.includes(index+2) || selectedLegIndeces.includes(index-2)) {
        ok = true;
      }
      
      if (!ok) return;
    }

    if (!selectedLegs.includes(segmentId)) {
      newSelectedLegs.push(segmentId);
      newSelectedLegIndeces.push(index);
    }
    else {
      newSelectedLegs = selectedLegs.filter((item) => item !== segmentId)
      newSelectedLegIndeces = selectedLegIndeces.filter((item) => item !== index)
    }

    setSelectedLegs(newSelectedLegs);
    setSelectedLegIndeces(newSelectedLegIndeces);
  }

  const displayDialog = dialogType => {
    const { formatMessage } = props.intl;
    var title, body, buttonLabel;
    var loginCta = false;

    switch (dialogType) {
      case 'login_required':
        title = formatMessage({ id: 'map.dialog.loginRequired.title' });
        body = formatMessage({ id: 'map.dialog.loginRequired.description' });
        loginCta = true;
        buttonLabel = formatMessage({ id: 'map.dialog.loginRequired.button' });
        break;      
      default: 
        title = formatMessage({ id: 'map.dialog.unknownError.title' });
        body = formatMessage({ id: 'map.dialog.unknownError.description' });
        buttonLabel = formatMessage({ id: 'map.dialog.unknownError.button' });
        break;
    }

    if (title) {
      setDialogTitle(title);
      setDialogBody(body);
      setDialogButtonLabel(buttonLabel);
      setDialogLoginCta(loginCta);
      setDialogOpen(true);
    }
  }


  // Ungroups a selected leg group
  const unGroup = () => {
    if (!auth.user) { displayDialog('login_required'); return false;};

    if (selectedLegs.length < 1) {
      enqueueSnackbar('Valitse ryhmitetty legi.', { variant: 'warning' });
      return false;
    }

    if (selectedLegs.length > 1) {
      enqueueSnackbar('Ryhmityksen voi purkaa vain yhdeltä legiltä kerrallaan.', { variant: 'warning' });
      return false;
    }

    // Find out the leggroup id
    const groupId = waypoints.filter(e => e.id === selectedLegs[0])[0].legGroup;

    if (!groupId) {
      enqueueSnackbar('Valitse ryhmitetty legi purettavaksi.', { variant: 'warning' });
      return false;
    }

    dispatch(breakWaypointGroup({ legGroupId: groupId }));

    setSelectedLegs([]);
    setSelectedLegIndeces([]);
  }


  // Combines selected legs or segments into a group
  const groupLegs = () => {
    if (!auth.user) { displayDialog('login_required'); return false;};

    if (selectedLegs.length < 2) {
      enqueueSnackbar('Valitse vähintään kaksi vierekkäistä yhdistettävää legiä.', { variant: 'warning' });
      return false;
    }

    dispatch(groupWaypoints({ waypointIds: selectedLegs }));

    setSelectedLegIndeces([]);
    setSelectedLegs([]);
  }

  const displayTTG = (distance) => {
    if (!distance) return 0;

    var hours = (distance / 1852 / vesselSpeed);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = round(minutes);
  
    return rhours + "h " + rminutes + "min";
  }

  const getRouteElement2 = (item, index) => {
    if (item.routeFeature === 'edge') return (
      <>
      <Grid item xs={12}>
      <div
        style={{display: 'flex', height: '28px', flexDirection: 'row', alignItems: 'center'}}
      >
        <div style={{flex: 1, height: '1px', backgroundColor: 'lightGray'}} />
        
        <div>
          <span style={{textAlign: 'center'}}>
            <Chip 
              icon={item.groupLeg ? <MultipleStopRoundedIcon /> : <ArrowRightAltIcon />}
              label={`${round(item.distance/1852, 2).toLocaleString('fi-FI')} nm (${displayTTG(item.distance)})`}
              size="small"
              xs={{cursor:'pointer'}}
              color={selectedLegs.includes(item.id) ? 'success' : 'default'}
              onClick={() => toggleLegSelection(item.id, index)}
            />
          </span>
        </div>

        <div style={{flex: 1, height: '1px', backgroundColor: 'lightGray'}} />
      </div>
      </Grid>
      </>
    );

    if (item.routeFeature === 'point') return (
      <>
        <Grid item xs={2} style={{cursor:'pointer'}} onClick={() => setMapCenter([item.lng, item.lat])}>
          <Avatar sx={{ width: 24, height: 24, bgcolor: '#284B63' }}>
            <Typography variant="routeIndex">{item.pointIndex}</Typography>
          </Avatar>
        </Grid>
        <Grid align='left' item xs={10} style={{cursor:'pointer'}} onClick={() => setMapCenter([item.lng, item.lat])}>
        {item.description && (
          <Typography variant="routeItemName">
            {item.description}
          </Typography>
        )}
        {1 === 0 && auth.user && auth.user.role && auth.user.role === 'admin' && (
          <Typography variant="routeItemName">
              {item.lng} {item.lat}
          </Typography>
        )}
        {!item.description && (
          <Typography variant="routeItemPosition">
            {<>{displayCoordinate(item.lat, 'lat')} &nbsp;{displayCoordinate(item.lng, 'lng')}</>}
          </Typography>
        )}
        </Grid>
      </>
    );
  }

  const isLastGroupPoint = (index) => {
    if (waypoints[index].legGroup && 
        (index + 1 === waypoints.length || waypoints[index].legGroup !== waypoints[index+1].legGroup)
    ) {
      return true;
    } 

    return false;
  };

  const drawerContent = () => {
    if (!waypoints) return false;
    const routeElements = [];

    if (!waypoints || waypoints.length < 1) return;

    var wpIndex = 1;

    var legGroupEdgeDistance = 0;

    for (var index = 0; index < waypoints.length; index++) {

//      console.log(index, wpIndex, waypoints[index].legGroup, isLastGroupPoint(index), waypoints[index].distance / 1852, legGroupEdgeDistance/1852)

      if (waypoints[index].legGroup) {
        legGroupEdgeDistance = legGroupEdgeDistance + waypoints[index].distance;
      }
      else {
        if (waypoints[index].passage) {
          routeElements.push({ 
            routeFeature: 'edge',
            id: waypoints[index].id,
            distance: waypoints[index].distance,
          });
        }
      }

      if (isLastGroupPoint(index)) {
        routeElements.push({ 
          routeFeature: 'edge',
          groupLeg: true,
          id: waypoints[index].id,
          distance: legGroupEdgeDistance
        });
        legGroupEdgeDistance = 0;
      }

      // Add point only if it's not part of leg group, except if it's the last waypoint in leg group
      if (!waypoints[index].legGroup || isLastGroupPoint(index)) {
        routeElements.push({
          routeFeature: 'point',
          id: waypoints[index].id,
          routingMode: waypoints[index].routingMode,
          pointIndex: wpIndex,
          description: waypoints[index].name,
          lng: waypoints[index].lngLat[0],
          lat: waypoints[index].lngLat[1],
        });
        wpIndex ++;
      }
    }
    return routeElements;
  };


  const RoutePlanView = () => {

    if (!auth.user) {
      return <>
        <Box margin={2} pt={10}>
          <Stack>
            <Typography variant='body2' gutterBottom>
              Tervetuloa tutustumaan Vertin beta-versioon! Vertin tietoihin voi tutustua ilman sisäänkirjautumista. Reititystä ja reittisuunnitelmia pääset käyttämään kirjautumalla sisään Verttiin.
            </Typography>
            <Button onClick={() => navigate('/login')}>
              Kirjaudu sisään
            </Button>
            <Button onClick={() => navigate('/register')}>
              Luo Vertti-tili
            </Button>
          </Stack>
        </Box>
      </>
    }


    const content = drawerContent();

    if (!content) return <>
      <Box margin={1} pt={2}>
        <Tooltip title="Aluksen tiedot">
          <Chip xs={{cursor:'pointer'}} size="large" onClick={() => { if (!auth.user) { displayDialog('login_required'); return false;}; setVesselConfiguratorOpen(true)} } label={`${vesselDraft.toLocaleString('fi-FI')} m / ${vesselSpeed.toLocaleString('fi-FI')} kn`} variant="outlined" />
        </Tooltip>
      </Box>

      <Box margin={1} pt={10}>
        <Typography variant='body1'>
          Reittisuunnitelma on tyhjä. Lisää ensimmäinen reittipiste kartalle.
        </Typography>
      </Box>
    </>;

    return (
      <>
      <Box margin={1} pt={2}>
        <Tooltip title="Aluksen tiedot">
          <Chip xs={{cursor:'pointer'}} size="large" onClick={() => { if (!auth.user) { displayDialog('login_required'); return false;}; setVesselConfiguratorOpen(true)} } label={`${vesselDraft.toLocaleString('fi-FI')} m / ${vesselSpeed.toLocaleString('fi-FI')} kn`} variant="outlined" />
        </Tooltip>
      </Box>
      <Box margin={1} pt={2}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          {content.map((item, index) => (
          <React.Fragment key={`rixs${index}`}>
            {getRouteElement2(item, index)}
          </React.Fragment>
          ))}
        </Grid>
      </Box>
      </>
    );
  }


  const RoutePlanFooter = () => {
    return (
      <>
        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, left: 0, width: drawerWidth }}>
          <Toolbar>
            <Stack direction='column'>
              <Box><Typography variant="body2">{round(routeDistance/1852, 2)} nm</Typography></Box>
              {routeDistance > 0 ? <Box><Typography variant="body2">{displayTTG(routeDistance)}</Typography></Box> : null}
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Tooltip title="Aluksen tiedot">
              <Chip xs={{cursor:'pointer'}} size="small" onClick={() => { if (!auth.user) { displayDialog('login_required'); return false;}; setVesselConfiguratorOpen(true)} } label={`${vesselDraft.toLocaleString('fi-FI')} m / ${vesselSpeed.toLocaleString('fi-FI')} kn`} variant="outlined" />
            </Tooltip> */}

            <Tooltip title="Lataa suunnitelma GPX-muodossa">
              <IconButton onClick={() => setGPXDialogOpen(true)} color="inherit" aria-label="Lataa suunnitelma GPX-muodossa">
                <FileDownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ryhmitä valitut legit">
              <IconButton onClick={groupLegs} color="inherit" aria-label="Ryhmitä valitut legit">
                <JoinFullRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Pura legien ryhmitys">
              <IconButton onClick={unGroup} color="inherit" aria-label="Pura ryhmitys">
                <JoinInnerRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {savingStatus === 'saving' && (<CloudUploadIcon />)}
            {savingStatus === 'ok' && (<CloudDoneIcon />)}
            {savingStatus === 'changed' && (<CloudIcon />)}
            {savingStatus === 'error' && (<CloudIcon color='error'/>)}

            {/* <IconButton color="inherit" aria-label="open drawer">
              <MenuIcon />
            </IconButton>
            <StyledFab color="secondary" aria-label="add">
              <AddIcon />
            </StyledFab>
            
            <IconButton color="inherit">
              <SearchIcon />
            </IconButton>
            <IconButton color="inherit">
              <MoreIcon />
            </IconButton> */}
          </Toolbar>
        </AppBar>
{/* 
        <Box sx={{position: 'fixed', top: 0, backgroundColor: 'red', zIndex: 100,  border: '1px solid red'}}>
          <Typography mt={3} pb={2} variant="body2" align="center">Reittisuunnitelma</Typography>
          <Box mb={3} sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-middle' }}>
            <Chip color='primary' icon={<EditRoundedIcon fontSize='small'/>} label={`Syväys ${route.vesselDraft} m`} onClick={() => setVesselConfiguratorOpen(true)}/>
          </Box>
        </Box> */}
      </>
    );
  }


  return (
    <div className="App" style={{height: '100vh'}}>
      <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <AppBar 
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}  
        >
          <Toolbar>
            <IconButton
              size="medium"
              edge="start"
              color="default"
              aria-label="route plan"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, color: 'white', display: { md: 'none' } }}
            >
              <RouteIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }} ml={2} mr={2}><img src= {VerttiBrandMark} alt="Vertti" height={25} /></Box>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}><img src={VerttiLogo} alt="Vertti" height={15} /></Box>
              </Box>
            </Box>
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}>
              <img src={VerttiLogoXs} alt="Vertti" height={20} />
            </Box> */}
            {/* <Box sx={{ flexGrow: 1 }} mr={2}>
              <HarborSearch />
            </Box> */}

            {/* <Box justifyContent='right'>
              <IconButton
                size="medium"
                edge="start"
                color="default"
                aria-label="haku"
                onClick={() => setReachDialogOpen(true)}
              >
                <ExploreIcon />
              </IconButton>
            </Box> */}
            <Box justifyContent='right' ml={1}>
              <IconButton
                size="medium"
                edge="start"
                color="default"
                aria-label="haku"
                onClick={() => setSearchDialogOpen(true)}
                className='search-menu'
              >
                <SearchIcon sx={{color: 'white'}} />
              </IconButton>
            </Box>
            <Box justifyContent='right' ml={1}>
              <IconButton
                size="medium"
                edge="start"
                color="default"
                aria-label="karttatasot"
                onClick={handleLayerMenu}
                sx={{color: 'white'}}
                className='layers-menu'
              >
                <LayersIcon />
              </IconButton>
              {auth && (
                <>
                    <IconButton
                      size="medium"
                      aria-label="options"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="default"
                      className='main-menu'
                    >
                      <MenuIcon sx={{color: 'white'}} />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => {handleClose(); triggerNewRoutePlan()}}><FormattedMessage id="appbar.newRoute"/></MenuItem>
                      <MenuItem onClick={() => {handleClose(); if (!auth.user) { displayDialog('login_required'); return false;}; setRoutePlanManagementModalOpen(true)}}><FormattedMessage id="appbar.routePlanManager"/></MenuItem>
                      <MenuItem onClick={() => {handleClose(); if (!auth.user) { displayDialog('login_required'); return false;}; setVesselConfiguratorOpen(true)}}><FormattedMessage id="appbar.vesselConfigurator"/></MenuItem>
                      {/* <MenuItem onClick={() => {handleClose(); setTutorialModalOpen(true)}}><FormattedMessage id="appbar.instructions"/></MenuItem> */}
                      <MenuItem onClick={(e) => {handleClose(); openInNewTab('https://vertti.io/ohjeet')}}><FormattedMessage id="appbar.instructions"/></MenuItem>
                      <MenuItem onClick={() => {handleClose(); setLicenseModalOpen(true)}}><FormattedMessage id="appbar.information"/></MenuItem>
                      {/* <MenuItem onClick={handleClose}><FormattedMessage id="appbar.userprofile"/></MenuItem> */}
                      {auth.user && <MenuItem onClick={() => {handleClose(); setEditUserDialogOpen(true)}}>Käyttäjätili</MenuItem>}
                      {auth.user && auth.isAuthorizedToAnyHarbor() && <MenuItem onClick={() => navigate('/manager', {replace: true})}>Satamatietojen hallinta</MenuItem>}
                      {auth.user && <MenuItem onClick={() => auth.signout()}><FormattedMessage id="appbar.logout"/></MenuItem>}
                      {!auth.user && <MenuItem onClick={() => navigate('/login')}><FormattedMessage id="appbar.login"/></MenuItem>}
                      {/* <Divider />
                      <MenuItem>
                        <ListItemIcon>
                          <ThreeDRotation fontSize='small' />
                        </ListItemIcon>
                        <ListItemText 
                          onClick={() => dispatch(setThreeDimensionalMapEnabled(!user.threeDimensionalMapEnabled))}
                        >
                          2D/3D-kartta
                        </ListItemText>
                      </MenuItem> */}
                    </Menu>
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="route"
        >

          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { bgcolor: '#FBF6F2', boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <Box paddingBottom={10}>
              <RoutePlanView />
              <RoutePlanFooter />
            </Box>
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': { bgcolor: '#FBF6F2', boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {/* <Toolbar /> */}
            <Box paddingBottom={10}>
              <RoutePlanView />
              <RoutePlanFooter />
            </Box>
          </Drawer>

        </Box>
        <Box
          component="main"
          sx={{ p: 0, width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)`} }}
          style={{height: `calc(100vh - ${appBarHeight}px)`}}
        >
          <Toolbar />
          <VerttiMap drawerWidth mapCenter={mapCenter} />
        </Box>
      </Box>
      {(routePlanManagementModalOpen) ? <RoutePlanManagerModal routePlanManagementModalOpen={routePlanManagementModalOpen} setRoutePlanManagementModalOpen={setRoutePlanManagementModalOpen} /> : null}
      {(licenseModalOpen) ? <LicenseModal licenseModalOpen={licenseModalOpen} setLicenseModalOpen={setLicenseModalOpen} /> : null}
      <TutorialModal tutorialModalOpen={tutorialModalOpen} setTutorialModalOpen={setTutorialModalOpen} />
      {(vesselConfiguratorOpen) ? <VesselConfigurator dialogOpen={vesselConfiguratorOpen} handleDialogClose={handleVesselConfiguratorClose} /> : null}

      {/* Layer menu */}
      <Menu
          id="menu-appbar"
          anchorEl={layerMenuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(layerMenuAnchorEl)}
          onClose={handleLayerMenuClose}
        >
          
          <MenuItem onClick={() => {toggleStateLayer('weather')}}>
            <ListItemIcon>
            {layerSettings.weather ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              Säähavainnot
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {toggleStateLayer('harbor')}}>
            <ListItemIcon>
            {layerSettings.harbor ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              Satamat
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {toggleStateLayer('crowdPoi')}}>
            <ListItemIcon>
            {layerSettings.crowdPoi ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              Veneilykohteet
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {toggleStateLayer('septic')}}>
            <ListItemIcon>
              {layerSettings.septic ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              Septityhjennysasemat
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {toggleStateLayer('ais')}}>
            <ListItemIcon>
              {layerSettings.ais ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              AIS-kohteet
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {toggleStateLayer('ice')}}>
            <ListItemIcon>
              {layerSettings.ice ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              Jäätilanne
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {toggleStateLayer('heatMap')}}>
            <ListItemIcon>
              {layerSettings.heatMap ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              AIS-heatmap
            </ListItemText>
          </MenuItem>

          <MapDialog
            title={dialogTitle}
            body={dialogBody}
            buttonLabel={dialogButtonLabel}
            dialogOpen={dialogOpen}
            dialogLoginCta={dialogLoginCta}
            handleDialogClose={handleDialogClose}
          />

          <DownloadGPXDialog
            dialogOpen={GPXdialogOpen}
            handleDialogClose={handleGPXDialogClose}
          />

          <SearchDialog
            dialogOpen={searchDialogOpen}
            handleDialogClose={handleSearchDialogClose}
          />

          {/* <Wizard
            wizardOpen={wizardOpen}
            handleWizardClose={handleWizardClose}
          /> */}

        </Menu>
        {editUserDialogOpen && <UserEditor handleDialogClose={handleEditUserDialogClose} dialogOpen={editUserDialogOpen} />}
    </div>
  );
}

export default injectIntl(withSnackbar(MapPage));