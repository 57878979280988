import React, {useState, useEffect} from 'react';
import { Box, TextField, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import verttiApi from '../../api/verttiApi';

const HarborEntitlementsManager = ({ harborId }) => {
  const [emailQuery, setEmailQuery] = useState('');
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setEmailQuery(event.target.value);
  }

  useEffect(() => {
    listUsers();
  }, [])
  

  const listUsers = () => {
    setLoading(true);
    verttiApi.get(`/manager/listentitledusertoharbor/${harborId}`)
      .then((res) => {
        setUserList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      })
  }

  // const findUser = () => {
  //   setLoading(true);
  //   verttiApi.post('/manager/finduser', { email: emailQuery })
  //     .then((res) => {
  //       console.log(res.data)
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       alert(err);
  //       setLoading(false);
  //     })
  // }

  const entitleUserByEmail = () => {
    setLoading(true);
    verttiApi.post('/manager/entitleusertoharbor', { email: emailQuery, harborId })
      .then((res) => {
        listUsers();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        setLoading(false);
      })
  }

  const revokeUser = (email) => {
    setLoading(true);
    verttiApi.post('/manager/revokeuserentitlementtoharbor', { email: email, harborId })
      .then((res) => {
        listUsers();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        setLoading(false);
      })
  }

  return (
    <Box mt={5}>
      <TextField fullWidth label='Lisää oikeus sataman hallintaan' placeholder='Anna sähköpostiosoite' value={emailQuery} onChange={handleInputChange}/>
      <Box mt={1} />
      <LoadingButton loading={loading} variant='contained' fullWidth onClick={() => entitleUserByEmail()}>Anna oikeus</LoadingButton>
      <Stack>
        {userList.map(u => 
          <Box key={u.email}>
            <LoadingButton loading={loading} onClick={() => revokeUser(u.email)}>Poista</LoadingButton>{u.email}
          </Box>)}
      </Stack>
    </Box>
  )
}

export default HarborEntitlementsManager;