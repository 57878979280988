import React from 'react';
import { useNavigate } from "react-router-dom";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import HarborSearch from '../harbor/HarborSearch';
import GenericSearch from './GenericSearch';

const SearchDialog = ({ dialogOpen, handleDialogClose }) => {
  // const useStyles = makeStyles({
  //   dialog: {
  //     position: 'absolute',
  //     left: 10,
  //     top: 50
  //   }
  // });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));  

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullScreen={fullScreen}
      fullWidth={true}
      // PaperProps={{ sx: { position: "fixed", top: 10 } }}
      PaperProps={{
        style: {
          position: "fixed", 
          top: 10,
          width: fullScreen ? '100%' : '40vw', // Full width on small devices
          maxHeight: '30vh', // Optional: Adjust the height if needed
        },
      }}
    >
    <DialogTitle id="alert-dialog-title">
      Haku
      <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <GenericSearch handleDialogClose={handleDialogClose}/>
    </DialogContent>
    <DialogActions>
    </DialogActions>
  </Dialog>
  )
};

export default SearchDialog;