import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Slider,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Box,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Stack
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAddCrowdPoiActive } from '../../features/user';
import verttiApi from '../../api/verttiApi';

import CloseIcon from '@mui/icons-material/Close';

import BonfireIcon from '../icons/BonfireIcon';
import FuelIcon from '../icons/FuelIcon';
import BarbecueIcon from '../icons/BarbecueIcon';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BeachIcon from '../icons/BeachIcon';
import CafeIcon from '../icons/CafeIcon';
import CampingIcon from '../icons/CampingIcon';
import FishingIcon from '../icons/FishingIcon';
import NatureTrailIcon from '../icons/NatureTrailIcon';
import PlaygroundIcon from '../icons/PlaygroundIcon';
import WasteDisposalIcon from '../icons/WasteDisposalIcon';
import ToiletIcon from '../icons/ToiletIcon';
import RestaurantIcon from '../icons/RestaurantIcon';
import DogparkIcon from '../icons/DogparkIcon';
import BirdTowerIcon from '../icons/BirdTowerIcon';
import AccomodationIcon from '../icons/AccomodationIcon';
import DrinkingWaterIcon from '../icons/DrinkingWaterIcon';
import SaunaIcon from '../icons/SaunaIcon';
import AttractionIcon from '../icons/AttractionIcon';
import { LoadingButton } from '@mui/lab';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';

const poiFeatures = [
  {key: 'barbecuePlace', icon: <BarbecueIcon fontSize='large'/>, label: 'Grillaus'},
  {key: 'campfirePlace', icon: <BonfireIcon fontSize='large'/>, label: 'Nuotiopaikka'},
  {key: 'cafe', icon: <CafeIcon fontSize='large'/>, label: 'Kahvila'},
  {key: 'restaurant', icon: <RestaurantIcon fontSize='large'/>, label: 'Ravintola'},
  {key: 'sauna', icon: <SaunaIcon fontSize='large'/>, label: 'Sauna'},
  {key: 'beach', icon: <BeachIcon fontSize='large'/>, label: 'Uimaranta'},
  {key: 'birdTower', icon: <BirdTowerIcon fontSize='large'/>, label: 'Lintutorni'},
  {key: 'natureTrail', icon: <NatureTrailIcon fontSize='large'/>, label: 'Luontopolku'},
  {key: 'fishing', icon: <FishingIcon fontSize='large'/>, label: 'Kalastus'},
  {key: 'dogPark', icon: <DogparkIcon fontSize='large'/>, label: 'Koirapuisto'},
  {key: 'playground', icon: <PlaygroundIcon fontSize='large'/>, label: 'Leikkipaikka'},
  {key: 'drinkingWater', icon: <DrinkingWaterIcon fontSize='large'/>, label: 'Juomavesi'},
  {key: 'toilet', icon: <ToiletIcon fontSize='large'/>, label: 'Käymälä'},
  {key: 'wasteDisposal', icon: <WasteDisposalIcon fontSize='large'/>, label: 'Jätehuolto'},
  {key: 'campsite', icon: <CampingIcon fontSize='large'/>, label: 'Telttailu'},
  {key: 'accommodation', icon: <AccomodationIcon fontSize='large'/>, label: 'Majoitus'},
  {key: 'attraction', icon: <AttractionIcon fontSize='large'/>, label: 'Nähtävyys'},
  {key: 'fuelStation', icon: <FuelIcon fontSize='large' />, label: 'Polttoaine'},
];


const FeatureSelectItem = ({ featureKey, label, icon, selected, toggleFeature }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Box 
        p={1}
        onClick={() => toggleFeature(featureKey)}
        sx={{
          height: '50px',
          borderRadius: 3,
          boxShadow: 2,
          backgroundColor: '#fafafa',
          '&:hover': {
            boxShadow: 3,
            cursor: 'pointer'
          },
      }}>
        <Stack direction='row' spacing={2} display='flex' height='100%' alignItems='center' >
          {icon}
          <Typography fontSize={{xs: '1.2rem', sm: '1.2rem'}}>{label}</Typography>
          <Box style={{marginLeft: 'auto'}} justifyContent="flex-end">
            {selected ? <CheckBoxIcon fontSize='medium' color='primary' /> : <CheckBoxOutlineBlankIcon fontSize='medium' color='primary' />}
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

const FeatureList = ({ selectedFeatures, toggleFeature }) => {
  return (
    <>
      <Box mt={3} />
      <Typography variant='h5'>Valitse kohteen ominaisuudet</Typography>
      <Box py={3} px={1}>
        <Grid container spacing={3}>
          {poiFeatures.map(f => 
            <FeatureSelectItem 
                key={f.key}
                featureKey={f.key}
                label={f.label}
                icon={f.icon} 
                toggleFeature={toggleFeature}
                selected={selectedFeatures.filter(e => e === f.key).length > 0 ? true : false}
            />
          )}
        </Grid>
      </Box>
    </>
  )
}

const AddCrowdPoiDialog = ({ dialogOpen, handleDialogClose, lngLat, handleCrowdPoiAdded }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [saving, setSaving] = useState(false);
  const [poiName, setPoiName] = useState('');

  const toggleFeature = (key) => {
    if (selectedFeatures.filter(e => e === key).length > 0) {
      setSelectedFeatures((current) =>
      current.filter(e => e !== key)
    );
    }
    else {
      setSelectedFeatures(current => [...current, key]);
    }
  }

  const handleNameChange = (event) => {
    setPoiName(event.target.value);
  };


  const handleSubmit = () => {
    if (!poiName || poiName === '') {
      enqueueSnackbar('Tarkasta kohteen nimi', { variant: 'error' });
      return;
    }

    if (poiName.length < 3) {
      enqueueSnackbar('Nimi on liian lyhyt', { variant: 'error' });
      return;
    }

    setSaving(true);

    verttiApi.post('crowdpoi', {
      name: poiName,
      lng: lngLat[0],
      lat: lngLat[1],
      features: selectedFeatures,
     })
    .then (result => {
      enqueueSnackbar('Kiitos, uusi kohde lisätty!', { variant: 'success' });
      setSaving(false);
      dispatch(setAddCrowdPoiActive(false));
      handleCrowdPoiAdded({ crowdPoiPublicId: result.data.publicId });
    })
    .catch(err => {
      console.error(err);
      enqueueSnackbar('Kohteen tallennus epäonnistui', { variant: 'error' });
      setSaving(false);
    });

    return;
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{ sx: { position: "fixed", top: 10 } }}
    >
    <DialogTitle id="alert-dialog-title">
      Uusi veneilykohde
      <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography variant='h5' gutterBottom>Nimeä kohde</Typography>
      <Box mt={1} />
      <TextField 
        variant='filled' 
        label='Kohteen nimi'
        fullWidth
        value={poiName}
        onChange={handleNameChange}
        sx={{
          pointerEvents: 'auto',
          backgroundColor: '#ffffff'
        }}
      />
      <FeatureList selectedFeatures={selectedFeatures} toggleFeature={toggleFeature}/>
      <Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleDialogClose()}>
        Peru
      </Button>
      <LoadingButton onClick={() => handleSubmit()} loading={saving}>
        Lisää kohde
      </LoadingButton>
    </DialogActions>
  </Dialog>
  )
};

export default withSnackbar(AddCrowdPoiDialog);