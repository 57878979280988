const isLastGroupPoint = (index, waypoints) => {
  if (waypoints[index].legGroup && 
      (index + 1 === waypoints.length || waypoints[index].legGroup !== waypoints[index+1].legGroup)
  ) {
    return true;
  } 

  return false;
};

const generateRouteGeoJSON = (waypoints) => {
  var newRouteGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  if (!waypoints || !Array.isArray(waypoints)) {
    return newRouteGeoJSON;
  }

  var index = 0;
  var wpIndex = 1;

  for (var wp of waypoints) {
    // Add waypoint as Point feature

    if (!wp.legGroup || isLastGroupPoint(index, waypoints)) {
      newRouteGeoJSON.features.push({
        type: 'Feature',
        properties: {
          id: wp.id,
          pointIndex: wpIndex,
          poiId: wp.poiId,
        },
        geometry: {
          type: 'Point',
          coordinates: [wp.lngLat[0], wp.lngLat[1]],
        }
      });
      wpIndex++;
    }
    // Add passage leading to waypoint as LineStrings
    if (wp.passage && Array.isArray(wp.passage)) {
      for (var feature of wp.passage) {
        newRouteGeoJSON.features.push(feature);
      }
    }

    index++;
  }

  return (newRouteGeoJSON);
}

export default generateRouteGeoJSON;