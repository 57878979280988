const routeLineLayer = {
  id: 'routeLines',
  type: 'line',
  layout: {
    'line-cap': 'round',
  },
  paint: {
    // 'line-color': "#ff0000",
    "line-color": [
      "match", ["get", "routingMode"],
      'auto', "#FF3366",
      "#284B63",
    ],
    'line-width': ['case',
      ['boolean', ['feature-state', 'hover'], false],
        5, 
        3
    ],
    'line-opacity': 1,
  },
  filter: ['in', '$type', 'LineString']
};

const routePointLayer = {
  id: 'routePoints',
  type: 'circle',
  paint: {
    'circle-radius': ['case',
      ['boolean', ['feature-state', 'hover'], false],
        12, 
        10
    ],
    'circle-color': ['case',
      ['boolean', ['feature-state', 'hover'], false],
        '#666666', 
        '#000000'
    ],
  },
  filter: ['in', '$type', 'Point']
};

const routePointLabelLayer = {
  'id': 'routePointLabels',
  'type': 'symbol',
  'source': 'routePoints',
  paint: {
    "text-color": "#ffffff"
  },
  'layout': {
    'text-field': ['get', 'pointIndex'],
    'text-size': 10,
    'text-justify': 'auto',
  }
};

const tempPointLayer = {
  id: 'tempRoutePoints',
  type: 'circle',
  paint: {
    'circle-radius': 7,
    'circle-color': '#666666', 
    },
  filter: ['in', '$type', 'Point']
};

const tempLineLayer = {
  id: 'tempLines',
  type: 'line',
  paint: {
    'line-color': 'gray',
    'line-width': 1,
    'line-dasharray': [2, 1]
    },
  filter: ['in', '$type', 'LineString']
};

const reachAreaLayer = {
  id: 'reachArea',
  type: 'fill',
  paint: {
    'fill-color': '#0F3',
    'fill-opacity': 0.15
  }
};

const crowdPoiLayer = {
  id: 'crowdPois',
  type: 'symbol',
  'minzoom': 8,
  'layout': {
    'text-field': ["step", ["zoom"], "", 10, ["get", "name"]],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-allow-overlap': true,
    'icon-allow-overlap': true,
    'text-radial-offset': 1.2,
    'text-justify': 'auto',
    'text-size': 13,
    'icon-image': 'poi',
    'icon-size': 0.10
    },
    "paint": {
      "text-color": "#202",
      "text-halo-color": "#fff",
      "text-halo-width": 1
    },  
  // paint: {
  //   'circle-radius': 8,
  //   'circle-color': '#ffffff',
  // },
  filter: ['in', '$type', 'Point']
};

const harborLayer = {
  id: 'harborMarkers',
  type: 'symbol',
  'minzoom': 8,
  'layout': {
    'text-field': ["step", ["zoom"], "", 10, ["get", "name"]],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-allow-overlap': true,
    'icon-allow-overlap': true,
    'text-radial-offset': 1.2,
    'text-justify': 'auto',
    'text-size': 13,
    'icon-image': [
      'match',
      ['get', 'category'],
      'guest_harbor', 'guest-harbor',
      'service_harbor', 'service-harbor',
      'excursion_harbor', 'poi',
      'natural_harbor', 'poi',
      'home_harbor', 'home-harbor',
      'harbor-gray'
    ],
    'icon-size': 0.12
    },
    "paint": {
      "text-color": "#202",
      "text-halo-color": "#fff",
      "text-halo-width": 1
    },  
  // paint: {
  //   'circle-radius': 8,
  //   'circle-color': '#ffffff',
  // },
  filter: ['in', '$type', 'Point']
};

const septicLayer = {
  id: 'septicMarkers',
  type: 'symbol',
  'minzoom': 8,
  'layout': {
    'icon-image': 'septic',
    'icon-size': 0.10
    },
};

const weatherStationsLayer =  {
  id: 'weatherStations',
  type: 'symbol',
  'layout': {
    'text-field': ['get', 'windLabel'],
    // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    //'text-radial-offset': 2.1,
    //'text-justify': 'auto',
    'text-size': 12,
    'text-font': ['Noto Sans Bold'],
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': false,
    'icon-size': 0.05,
    'icon-rotation-alignment': 'map',
    'icon-rotate': ['get', 'iconRotation'],
  },
  "paint": {
    "text-color": "#000",
    "text-halo-color": "#fff",
    "text-halo-width": 0,
  },
}

const aisTargetsLayer = {
  id: 'aisTargets',
  type: 'symbol',
  'minzoom': 6,
  'filter': ['==', '$type', 'Point'],
  'layout': {
    "icon-image": [
      "match",
      ["get", "simpleType"],
        "passenger", [
          "case",
          ["<", ["get", "sog"], 0.2], "passengerStationaryVessel",
          "passengerVessel"
        ],
        "cargo", [
          "case",
          ["<", ["get", "sog"], 0.2], "cargoStationaryVessel",
          "cargoVessel"
        ],
        "pleasure", [
          "case",
          ["<", ["get", "sog"], 0.2], "pleasureStationaryVessel",
          "pleasureVessel"
        ],
        "tug", [
          "case",
          ["<", ["get", "sog"], 0.2], "tugStationaryVessel",
          "tugVessel"
        ],
        "tanker", [
          "case",
          ["<", ["get", "sog"], 0.2], "tankerStationaryVessel",
          "tankerVessel"
        ],
        "fishing", [
          "case",
          ["<", ["get", "sog"], 0.2], "fishingStationaryVessel",
          "fishingVessel"
        ],
        "highspeed", [
          "case",
          ["<", ["get", "sog"], 0.2], "highspeedStationaryVessel",
          "highspeedVessel"
        ],
        "navaid", "navaidVessel",
        [
          "case",
          ["<", ["get", "sog"], 0.2], "unspecifiedStationaryVessel",
          "unspecifiedVessel"
        ],
      ],
    

    'icon-rotate': ['get', 'cog'],
    'icon-rotation-alignment': 'map',
    'icon-size': 0.10,
    'text-field': ["step", ["zoom"], "", 9, ["concat", ["get", "name"], "\n", ["get", "sog"], " kn"]],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-allow-overlap': false,
    'icon-allow-overlap': true,
    'text-radial-offset': 1.2,
    'text-justify': 'auto',
    'text-size': 10,
  }
};

const aisVectorsLayer = {
  id: 'aisVectors',
  type: 'line',
  'minzoom': 6,
  'filter': ['==', '$type', 'LineString'],
  paint: {
    'line-color': '#9999FF',
    'line-width': 1,
  }
};

const santaTargetLayer = {
  id: 'santaTarget',
  type: 'symbol',
  'minzoom': 10,
  'filter': ['==', '$type', 'Point'],
  'layout': {
    'icon-anchor': 'right',
    'icon-image': ['get', 'image'],
    'icon-rotate': ['get', 'displayBearing'],
    'icon-rotation-alignment': 'map',
    'icon-size': 0.1,
    'text-field': ["concat", "JOULUPUKKI", "\n", ["get", "sog"], " kn"],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-allow-overlap': true,
    'icon-allow-overlap': true,
    'text-radial-offset': 3.0,
    'text-justify': 'auto',
    'text-size': 11,
  }
};

const santaVectorLayer = {
  id: 'santaVector',
  type: 'line',
  'minzoom': 10,
  'filter': ['==', '$type', 'LineString'],
  paint: {
    'line-color': '#FF6666',
    'line-width': 3,
    'line-dasharray': [2, 2],
    'line-opacity': 0.5,
  }
};

const hillShadingLayer = {
    id: "hillshading",
    source: "terrain",
    type: "hillshade"
};

const iceLayer = {
  "id": "ice",
  "type": "fill",
  "source": "ice",
  "source-layer": "ice",
  "paint": {
    "fill-color": [
      "match",
      ["get", "type"],
      1, "#95C8FF", 
      2, "#8CFFA0",
      3, "#FFFF00",
      4, "#FF7D09",
      5, "#FF0000",
      6, "#910000",
      7, "#FF64FF",
      8, "#969696",
      9, "#FFAFFF",
      "rgba(0,0,0,0)"
    ],
    "fill-opacity": 0.5
  }
};

const harborOrtoLayer = {
  'id': 'weatherRadar',
  'type': 'raster',
  'source': 'weatherRadar',
  'paint': {}
};

const heatMapLayer = {
  "id": "heatmap",
  "type": "heatmap",
  "source": "heatmap",
  "source-layer": "heatmap",  // replace with your actual source layer name
  "paint": {
    "heatmap-weight": [
      "interpolate",
      ["linear"],
      ["get", "count"],
      0, 0,
      10, 0.2,
      100, 1
    ],
    "heatmap-intensity": [
      "interpolate",
      ["linear"],
      ["zoom"],
      0, 1,
      14, 3,
      20, 5  // Increased intensity at higher zoom levels
    ],
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0, "rgba(255,255,255,0)",        // White with transparency
      0.1, "rgba(237,104,60,0.9)",      // Light red
      0.4, "rgba(255,153,153,0.8)",      // Medium-light red
      0.6, "rgba(243,144,63,0.8)",      // Medium red
      0.8, "rgba(253,199,12,0.8)",        // Medium-dark red
      1, "rgba(255,243,59,0.7)"             // Dark red
    ],
    "heatmap-radius": [
      "interpolate",
      ["linear"],
      ["zoom"],
      0, 1,
      14, 10,  // Increased radius at higher zoom levels
      20, 40,   // Further increased radius at even higher zoom levels
      30, 100
    ],
    "heatmap-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],
      7, 0.7,
      14, 1
    ]
  }
};

const harborMapMooringPolygonLayer = {
  "id": "harborMapMooringPolygons",
  "type": "fill",
  "paint": {
    "fill-color": [
      "match",
      ["get", "mooringareaType"],
      "overnight", "#50CE0D",
      "booking", "#CED52F",
      "shortTerm", "#CED52F",
      "prohibited", "#B11B1B",
      "#ccc"
    ],
    "fill-opacity": 0.6
  },
  "filter": ["all", ["in", "$type", "Polygon"], ["==", "polygonType", 'mooringarea']],
};

const harborMapPolygonLayer = {
  "id": "harborMapPolygons",
  "type": "fill",
  "paint": {
    "fill-color": [
      "match",
      ["get", "structureType"],
      "dock", "#6F3A10",
      "breakwater", "#505050",
      "otherStructure", "#A99A76",
      "#ccc"
    ],
    "fill-opacity": 0.9
  },
  "filter": ["all", ["in", "$type", "Polygon"], ["==", "polygonType", 'construction']],
};

const harborMapMooringLayer = [
  // {
  //   "id": "harborMapMoorings",
  //   "type": "line",
  //   layout: {
  //     'line-cap': 'round',
  //   },
  //   "paint": {
  //     "line-color": [
  //       "match",
  //       ["get", "lineType"],
  //       'mooringOvernight', '#50CE0D',
  //       'mooringShortterm', '#CED52F',
  //       'mooringProhibited', '#B11B1B',
  //       "#cccccc"
  //     ],
  //     'line-width': [
  //       'interpolate',
  //       ['linear'],
  //       ['zoom'],
  //       8, 1,
  //       22, 6
  //   ]
  //   },
  //   "filter": ["all", ["in", "$type", "LineString"], ["!=", "lineType", 'mooringWithbooking'], ["!=", "lineType", "recommendedRoute"]],
  // },
  // {
  //   "id": "harborMapMooringsDashed",
  //   "type": "line",
  //   layout: {
  //     'line-cap': 'round',
  //   },
  //   "paint": {
  //     "line-color": '#69A748',
  //     "line-dasharray": [2, 2],
  //     'line-width': [
  //       'interpolate',
  //       ['linear'],
  //       ['zoom'],
  //       8, 1,
  //       22, 6
  //     ]
  //   },
  //   "filter": ["all", ["in", "$type", "LineString"], ["==", "lineType", 'mooringWithbooking']],
  // },
  {
    "id": "recommendedRoute",
    "type": "line",
    layout: {
      'line-cap': 'round',
    },
    "paint": {
      "line-color": '#111111',
      "line-dasharray": [2, 3],
      "line-width": 1,
    },
    "filter": ["all", ["in", "$type", "LineString"], ["==", "lineType", 'recommendedRoute']],
  },
  {
    id: 'harborMapIcons',
    type: 'symbol',
    'minzoom': 13,
    'filter': ['==', '$type', 'Point'],
    'layout': {
      'icon-image': [
        'match',
        ['get', 'informationPointType'],
          "buoyMooring", 'buoy',
          "fingerMooring", 'finger',
          "polesMooring", 'poles',
          "anchorMooring", 'anchor',
          "mooringlineMooring", 'mooringline',
          "longsidesMooring", 'longsides',
          ''
      ],
      "icon-size": [
        "interpolate",
        ["exponential", 1.5], // Adjust the base as needed for your scaling
        ["zoom"],
        // Specify zoom levels and the corresponding icon sizes
        13, 0.05, // At zoom level 0, icon size will be 0.6 times its original size
        20, 0.7, // At zoom level 0, icon size will be 0.6 times its original size
        30, 1.5  // At zoom level 20, icon size will be 2.4 times its original size
      ],
      'icon-allow-overlap': true,
    }
  },
  // {
  //   "id": "harborMapMooringsTexts",
  //   "type": "symbol",
  //   "minzoom": 12,
  //   "layout": {
  //     "text-field": "Poijut",
  //     "text-size": 15,
  //     "symbol-placement": "line",
  //     "text-keep-upright": true,
  //     "text-offset": [0, 0],
  //   },
  //   "paint": {
  //     "text-color": "#000",
  //     "text-halo-width": 1, // Width of the outline
  //     "text-halo-color": "white", // Color of the outline
  //     "text-opacity": 0.8 // Opacity of the text
  //   },
  //   "filter": ["in", "$type", "LineString"] // Apply the text only to LineString features
  // }
];

const temperatureLayer = {
  id: 'temperature-layer',
  type: 'heatmap',
  source: 'weather',
  'source-layer': 'weather',
  paint: {
    'heatmap-weight': [
      'interpolate',
      ['linear'],
      ['get', 'temperature'],
      -30, 0,
      30, 1
    ],
    'heatmap-intensity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0, 1,
      15, 3
    ],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0, 'rgba(33,102,172,0)',
      0.2, 'rgb(103,169,207)',
      0.4, 'rgb(209,229,240)',
      0.6, 'rgb(253,219,199)',
      0.8, 'rgb(239,138,98)',
      1, 'rgb(178,24,43)'
    ],
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0, 150,
      15, 150
    ],
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7, 1,
      15, 0
    ],
  }
};

const windLayerxx = {
  'id': 'wind-layer',
  'type': 'circle',
  'source': 'weather',
  'source-layer': 'weather',
  'layout': {
    'text-field': ['get', 'wind_speed'],
  },
  'paint': {
      'circle-radius': 10,
      'circle-color': '#ff0000'
  }
};

const windLayer = {
  id: 'wind-layer',
  type: 'symbol',
  source: 'weather',
  'source-layer': 'weather',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'wind_speed'], null], 'windBarbNan',
      ['<=', ['get', 'wind_speed'], 2.5], 'windBarbCalm',
      ['<=', ['get', 'wind_speed'], 7.5], 'windBarb5',
      ['<=', ['get', 'wind_speed'], 12.5], 'windBarb10',
      ['<=', ['get', 'wind_speed'], 17.5], 'windBarb15',
      ['<=', ['get', 'wind_speed'], 22.5], 'windBarb20',
      ['<=', ['get', 'wind_speed'], 27.5], 'windBarb25',
      ['<=', ['get', 'wind_speed'], 32.5], 'windBarb30',
      ['<=', ['get', 'wind_speed'], 37.5], 'windBarb35',
      ['<=', ['get', 'wind_speed'], 42.5], 'windBarb40',
      ['<=', ['get', 'wind_speed'], 47.5], 'windBarb45',
      ['<=', ['get', 'wind_speed'], 52.5], 'windBarb50',
      ['<=', ['get', 'wind_speed'], 57.5], 'windBarb55',
      ['<=', ['get', 'wind_speed'], 62.5], 'windBarb60',
      ['<=', ['get', 'wind_speed'], 67.5], 'windBarb65',
      ['<=', ['get', 'wind_speed'], 72.5], 'windBarb70',
      ['<=', ['get', 'wind_speed'], 77.5], 'windBarb75',
      ['<=', ['get', 'wind_speed'], 82.5], 'windBarb80',
      ['<=', ['get', 'wind_speed'], 87.5], 'windBarb85',
      ['<=', ['get', 'wind_speed'], 92.5], 'windBarb90',
      ['<=', ['get', 'wind_speed'], 97.5], 'windBarb95',
      'windBarb100' // Default icon for wind speed greater than 97.5
    ],
    'icon-rotate': ['get', 'wind_direction'],
    'icon-rotation-alignment': 'map',
    'icon-size': 0.07,  // Adjust this size if needed
    'icon-anchor': 'top-left',
    'icon-padding': 0,  // Adjust padding if needed
  },
  paint: {
    'icon-opacity': [
      'interpolate',
      ['linear'],
      ['get', 'wind_speed'],
      0, 0.8,
      15, 0.8
    ]
  }
};


const windBackgroundLayer = {
  id: 'wind-background-layer',
  type: 'circle',
  source: 'weather',
  'source-layer': 'weather',
  paint: {
    'circle-radius': [
      'interpolate',
      ['exponential', 1],
      ['zoom'],
      0, 1,
      6, 2,
      8, 19,
      15, 500,
    ],
    'circle-color': [
      'interpolate',
      ['linear'],
      ['get', 'wind_speed'],
      0, 'rgba(0, 0, 255, 0.1)',      // Calm: blue
      10, 'rgba(0, 255, 0, 0.1)',     // 10 knots: green
      20, 'rgba(255, 255, 0, 0.1)',   // 20 knots: yellow
      30, 'rgba(255, 165, 0, 0.1)',   // 30 knots: orange
      40, 'rgba(255, 69, 0, 0.1)',    // 40 knots: dark orange
      50, 'rgba(255, 0, 0, 0.1)',     // 50 knots: red
      60, 'rgba(139, 0, 0, 0.1)'      // 60 knots and above: dark red
    ],
    'circle-opacity': 0.2 // Adjust opacity as needed
  }
};

const windLayerx = {
  id: 'wind-layer',
  type: 'symbol',
  source: 'weather',
  'source-layer': 'weather',
  layout: {
    'icon-image': 'windBarb5',
    'icon-rotate': ['get', 'wind_direction'],
    'icon-size': 0.07,
    'icon-anchor': 'top-left',
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': [
      'interpolate',
      ['linear'],
      ['get', 'wind_speed'],
      0, 1,
      15, 1
    ]
  }
};

export { windBackgroundLayer, windLayer, temperatureLayer, harborOrtoLayer, heatMapLayer, harborMapMooringPolygonLayer, harborMapMooringLayer, harborMapPolygonLayer, iceLayer, santaVectorLayer, santaTargetLayer, aisVectorsLayer, aisTargetsLayer, reachAreaLayer, routeLineLayer, routePointLayer, tempPointLayer, tempLineLayer, harborLayer, routePointLabelLayer, hillShadingLayer, septicLayer, weatherStationsLayer, crowdPoiLayer };