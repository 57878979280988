import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { withSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import { useAuth } from '../../hooks/useAuth';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AnchorIcon from '@mui/icons-material/Anchor';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import { mainListItems, secondaryListItems } from './managerMenuItems';


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const ManagerDrawer = ({open, toggleDrawer, setSelectedSection}) => {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <Drawer variant="permanent" open={open}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav">
      <ListItemButton onClick={() => navigate('/manager/dashboard', {replace: true})}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Työpöytä" />
      </ListItemButton>
      {/* onClick={() => setSelectedSection('harbors')} */}
      {auth.isAdmin() && (
        <React.Fragment>
        <ListItemButton onClick={() => navigate('/manager/harbors', {replace: true})}>
          <ListItemIcon>
            <AnchorIcon />
          </ListItemIcon>
          <ListItemText primary="Satamat" />
        </ListItemButton>
        
        {/* <ListItemButton onClick={() => setSelectedSection('crowdPois')}>
          <ListItemIcon>
            <AnchorIcon />
          </ListItemIcon>
          <ListItemText primary="Veneilykohteet" />
        </ListItemButton> */}
        
        {/* <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Käyttäjät" />
        </ListItemButton> */}
        </React.Fragment>
      )}
      
      <Divider sx={{ my: 1 }} />
      
      <ListItemButton onClick={() => navigate('/', {replace: true})}>
        <ListItemIcon>
          <ArrowBackIcon />
        </ListItemIcon>
        <ListItemText primary="Palaa Verttiin" />
      </ListItemButton>

      <ListItemButton onClick={() => {auth.signout(); navigate('/', {replace: true})}}>
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText primary="Kirjaudu ulos" />
      </ListItemButton>

    </List>
    </Drawer>
  )
}

export default injectIntl(withSnackbar(ManagerDrawer));