import React, { useState, useEffect } from 'react';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  Typography,
  Slider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setVesselDraft } from '../../features/routePlan/newRoutePlanSlice';
import { setVesselSpeed } from '../../features/routePlan/newRoutePlanSlice';


const VesselConfigurator = ({ dialogOpen, handleDialogClose }) => {
  const dispatch = useDispatch();
  const vesselDraft = useSelector(state => state.newRoutePlan.vesselDraft);
  const vesselSpeed = useSelector(state => state.newRoutePlan.vesselSpeed);
  // const [chosenVesselDraft, setChosenVesselDraft] = useState(0);
  // const reduxRoute = useSelector(state => state.routePlan.value);

  // useEffect(() => {
  //   if (reduxRoute.vesselDraft) setChosenVesselDraft(reduxRoute.vesselDraft);
  //   else if (localStorage.getItem('vesselDraft')) setChosenVesselDraft(localStorage.getItem('vesselDraft'));
  //   else setChosenVesselDraft(0);
  // }, []);


  const draftValueText = (value) => {
    return `${value} m`;
  }

  const speedValueText = (value) => {
    return `${value} kn`;
  }


  const handleDraftSliderChange = (event, newValue) => {
    dispatch(setVesselDraft(newValue));
    // localStorage.setItem('vesselDraft', newValue);
  };

  const handleSpeedSliderChange = (event, newValue) => {
    dispatch(setVesselSpeed(newValue));
    // localStorage.setItem('vesselSpeed', newValue);
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="Vessel configurator"
      aria-describedby="Configure your vessel"
      keepMounted
      >
      <DialogTitle id="alert-dialog-title">
        Aluksen asetukset
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography pb={2} variant="body1">Syväys</Typography>
          <Slider
            aria-label="Vessel draft"
            getAriaValueText={draftValueText}
            valueLabelDisplay="auto"
            value={vesselDraft}
            onChange={handleDraftSliderChange}
            step={0.1}
            min={0.0}
            max={5.0}
          />
          <Typography pb={2} pt={2} variant="h4" align="center">{vesselDraft} m</Typography>
          <Typography pb={2} pt={2} variant="body1" align="center">Asetettu syväys vaikuttaa seuraaviin reitityksiin. Reittisuunnitelman aiempia reitityksiä ei toistaiseksi päivitetä.</Typography>
          <Typography pb={2} variant="body1">Keskinopeus</Typography>
          <Slider
            aria-label="Vessel average speed"
            getAriaValueText={speedValueText}
            valueLabelDisplay="auto"
            value={vesselSpeed}
            onChange={handleSpeedSliderChange}
            step={0.5}
            min={1.0}
            max={30.0}
          />
          <Typography pb={2} pt={2} variant="h4" align="center">{vesselSpeed} kn</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VesselConfigurator;