import React from 'react';
import { useNavigate } from "react-router-dom";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle } from '@mui/material';
  
const MapDialog = ({ title, body, buttonLabel, dialogOpen, handleDialogClose, dialogLoginCta }) => {
  let navigate = useNavigate(); 
  return (
      <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted
      sx={{zIndex: 9999}}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogLoginCta && (
          <>
            <Button onClick={() => navigate('/login')} autoFocus>
              Kirjaudu
            </Button>
            <Button onClick={() => navigate('/register')} autoFocus>
              Luo tili
            </Button>
          </>
        )}
        <Button onClick={handleDialogClose} autoFocus>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default MapDialog;