import React, {useState, useEffect, useRef} from 'react';
import { Box, Grid, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import verttiApi from '../../../../api/verttiApi';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../../hooks/useAuth';


const harborServices = {
  'drinkingWater': {label: 'Juomavesi', labelId: 'service.harbor.drinkingWater', formikId: 'harborServiceDrinkingWater', formikVar: 'formik.values.harborServiceDrinkingWater'},
  'electricity': {label: 'Maasähkö', labelId: 'service.harbor.electricity', formikId: 'harborServiceElectricity', formikVar: 'formik.values.harborServiceElectricity'},
  'shower': {label: 'Suihku', labelId: 'service.harbor.shower', formikId: 'harborServiceShower', formikVar: 'formik.values.harborServiceShower'},
  'sauna': {label: 'Sauna', labelId: 'service.harbor.sauna', formikId: 'harborServiceSauna', formikVar: 'formik.values.harborServiceSauna'},
  'toilet': {label: 'Vessa', labelId: 'service.harbor.toilet', formikId: 'harborServiceToilet', formikVar: 'formik.values.harborServiceToilet'},
  'laundryRoom': {label: 'Pesutupa', labelId: 'service.harbor.laundryRoom', formikId: 'harborServiceLaundryRoom', formikVar: 'formik.values.harborServiceLaundryRoom'},
  'dishwashingPoint': {label: 'Tiskauspaikka', labelId: 'service.harbor.dishwashingPoint', formikId: 'harborServiceDishwashingPoint', formikVar: 'formik.values.harborServiceDishwashingPoint'},
  'barbecueArea': {label: 'Grillauspaikka', labelId: 'service.harbor.barbecueArea', formikId: 'harborServiceBarbecueArea', formikVar: 'formik.values.harborServiceBarbecueArea'},
};

const boatUpkeepServices = {
  'diesel': { label: 'Polttoaine, diesel', labelId: 'service.boatUpkeep.diesel', formikId: 'upkeepServiceDiesel', formikVar: 'formik.values.upkeepServiceDiesel' },
  'gasoline': { label: 'Polttoaine, bensiini', labelId: 'service.boatUpkeep.gasoline', formikId: 'upkeepServiceGasoline', formikVar: 'formik.values.upkeepServiceGasoline' },
  'fuelAvailability24h': { label: 'Polttoaine, automaatti', labelId: 'service.boatUpkeep.fuelAvailability24h', formikId: 'upkeepServiceFuelAvailability24h', formikVar: 'formik.values.upkeepServiceFuelAvailability24h' },
  'LPG': { label: 'Nestekaasu', labelId: 'service.boatUpkeep.LPG', formikId: 'upkeepServiceLPG', formikVar: 'formik.values.upkeepServiceLPG' },
  'septicDisposal': { label: 'Imutyhjennys', labelId: 'service.boatUpkeep.septicDisposal', formikId: 'upkeepServiceSepticDisposal', formikVar: 'formik.values.upkeepServiceSepticDisposal' },
  'chemicalToiletDisposal': { label: 'Kemiallisen WC:n tyhjennys', labelId: 'service.boatUpkeep.chemicalToiletDisposal', formikId: 'upkeepServiceChemicalToiletDisposal', formikVar: 'formik.values.upkeepServiceChemicalToiletDisposal' },
  'garbageReception': { label: 'Roskien vastaanotto', labelId: 'service.boatUpkeep.garbageReception', formikId: 'upkeepServiceGarbageReception', formikVar: 'formik.values.upkeepServiceGarbageReception' },
  'recyclingPoint': { label: 'Kierrätyspiste', labelId: 'service.boatUpkeep.recyclingPoint', formikId: 'upkeepServiceRecyclingPoint', formikVar: 'formik.values.upkeepServiceRecyclingPoint' },
  'mastCrane': { label: 'Mastonosturi', labelId: 'service.boatUpkeep.mastCrane', formikId: 'upkeepServiceMastCrane', formikVar: 'formik.values.upkeepServiceMastCrane' },
  'boatRepairs': { label: 'Venekorjaamo', labelId: 'service.boatUpkeep.boatRepairs', formikId: 'upkeepServiceBoatRepairs', formikVar: 'formik.values.upkeepServiceBoatRepairs' },
  'engineRepairs': { label: 'Moottorikorjaamo', labelId: 'service.boatUpkeep.engineRepairs', formikId: 'upkeepServiceEngineRepairs', formikVar: 'formik.values.upkeepServiceEngineRepairs' },
  'ramp': { label: 'Veneluiska', labelId: 'service.boatUpkeep.ramp', formikId: 'upkeepServiceRamp', formikVar: 'formik.values.upkeepServiceRamp' },
  'boatingSupplies': { label: 'Venetarvikkeita', labelId: 'service.boatUpkeep.boatingSupplies', formikId: 'upkeepServiceBoatingSupplies', formikVar: 'formik.values.upkeepServiceBoatingSupplies' },
};

const amenityServices ={
  'restaurant': { label: 'Ravintola', labelId: 'service.amenities.restaurant', formikId: 'amenityServicesRestaurant', formikVar: 'formik.values.amenityServicesRestaurant' },
  'cafe': { label: 'Kahvila', labelId: 'service.amenities.cafe', formikId: 'amenityServicesCafe', formikVar: 'formik.values.amenityServicesCafe' },
  'groceryStore': { label: 'Elintarvikkeita', labelId: 'service.amenities.groceryStore', formikId: 'amenityServicesGroceryStore', formikVar: 'formik.values.amenityServicesGroceryStore' },
  'pharmacy': { label: 'Apteekki', labelId: 'service.amenities.pharmacy', formikId: 'amenityServicesPharmacy', formikVar: 'formik.values.amenityServicesPharmacy' },
  'liquorStore': { label: 'Alko', labelId: 'service.amenities.liquorStore', formikId: 'amenityServicesLiquorStore', formikVar: 'formik.values.amenityServicesLiquorStore' },
  'ATM': { label: 'Pankkiautomaatti', labelId: 'service.amenities.ATM', formikId: 'amenityServicesATM', formikVar: 'formik.values.amenityServicesATM' },
  'bank': { label: 'Pankki', labelId: 'service.amenities.bank', formikId: 'amenityServicesBank', formikVar: 'formik.values.amenityServicesBank' },
  'postalOffice': { label: 'Posti', labelId: 'service.amenities.postalOffice', formikId: 'amenityServicesPostalOffice', formikVar: 'formik.values.amenityServicesPostalOffice' },
  'playground': { label: 'Leikkipaikka', labelId: 'service.amenities.playground', formikId: 'amenityServicesPlayground', formikVar: 'formik.values.amenityServicesPlayground' },
  'healthServices': { label: 'Terveydenhuoltopalveluita', labelId: 'service.amenities.healthServices', formikId: 'amenityServicesHealthServices', formikVar: 'formik.values.amenityServicesHealthServices' },
  'vet': { label: 'Eläinlääkäri', labelId: 'service.amenities.vet', formikId: 'amenityServicesVet', formikVar: 'formik.values.amenityServicesVet' },
}

const ManagerServiceItem = (props) => {
  const { label, formik, formikId, formikVal, labelId, updateService } = props;

  if (!label) return null;
  return (
    <Grid item sm={12} md={6} container sx={{ alignItems: "center" }} justifyContent="left">
      <FormControlLabel
        control={<Checkbox checked={formikVal} />}
        label={label}
        name={formikId}
        onChange={ e => {
          formik.setFieldValue(formikId, e.target.checked);
          updateService(labelId.split('.')[1], labelId.split('.')[2], e.target.checked);
        }}
      />
    </Grid>
  );
}

const ManagerServiceItemList = ({ itemConfig, formik, updateService }) => {
  var body = [];

  for (let [key, value] of Object.entries(itemConfig)) {
    const formikId = itemConfig[key].formikId;
    body.push(<ManagerServiceItem key={key} updateService={updateService} label={itemConfig[key].label} formik={formik} labelId={itemConfig[key].labelId} formikVal={formik.values[formikId]} formikId={formikId}/>);
  }

  return body;
}

const HarborServices = (props) => {
  const { harbor } = props;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const harborFormik = useFormik({
    initialValues: {
      serviceProviderName: harbor?.serviceProvider?.name ?? '',
      serviceProviderHarbourMaster: harbor?.serviceProvider?.harbourMaster ?? '',
      serviceProviderEmail: harbor?.serviceProvider?.email ?? '',
      serviceProviderPhone: harbor?.serviceProvider?.phone ?? '',
      serviceProviderWww: harbor?.serviceProvider?.www ?? '',
  
      harborServiceDrinkingWater: harbor?.services?.harbor?.drinkingWater === 1,
      harborServiceElectricity: harbor?.services?.harbor?.electricity === 1,
      harborServiceShower: harbor?.services?.harbor?.shower === 1,
      harborServiceSauna: harbor?.services?.harbor?.sauna === 1,
      harborServiceToilet: harbor?.services?.harbor?.toilet === 1,
      harborServiceLaundryRoom: harbor?.services?.harbor?.laundryRoom === 1,
      harborServiceDishwashingPoint: harbor?.services?.harbor?.dishwashingPoint === 1,
      harborServiceBarbecueArea: harbor?.services?.harbor?.barbecueArea === 1,
  
      upkeepServiceDiesel: harbor?.services?.boatUpkeep?.diesel === 1,
      upkeepServiceGasoline: harbor?.services?.boatUpkeep?.gasoline === 1,
      upkeepServiceFuelAvailability24h: harbor?.services?.boatUpkeep?.fuelAvailability24h === 1,
      upkeepServiceLPG: harbor?.services?.boatUpkeep?.LPG === 1,
      upkeepServiceSepticDisposal: harbor?.services?.boatUpkeep?.septicDisposal === 1,
      upkeepServiceChemicalToiletDisposal: harbor?.services?.boatUpkeep?.chemicalToiletDisposal === 1,
      upkeepServiceGarbageReception: harbor?.services?.boatUpkeep?.garbageReception === 1,
      upkeepServiceRecyclingPoint: harbor?.services?.boatUpkeep?.recyclingPoint === 1,
      upkeepServiceMastCrane: harbor?.services?.boatUpkeep?.mastCrane === 1,
      upkeepServiceBoatRepairs: harbor?.services?.boatUpkeep?.boatRepairs === 1,
      upkeepServiceEngineRepairs: harbor?.services?.boatUpkeep?.engineRepairs === 1,
      upkeepServiceRamp: harbor?.services?.boatUpkeep?.ramp === 1,
      upkeepServiceBoatingSupplies: harbor?.services?.boatUpkeep?.boatingSupplies === 1,
      
      amenityServicesRestaurant: harbor?.services?.amenities?.restaurant === 1,
      amenityServicesCafe: harbor?.services?.amenities?.cafe === 1,
      amenityServicesGroceryStore: harbor?.services?.amenities?.groceryStore === 1,
      amenityServicesPharmacy: harbor?.services?.amenities?.pharmacy === 1,
      amenityServicesLiquorStore: harbor?.services?.amenities?.liquorStore === 1,
      amenityServicesATM: harbor?.services?.amenities?.ATM === 1,
      amenityServicesBank: harbor?.services?.amenities?.bank === 1,
      amenityServicesPostalOffice: harbor?.services?.amenities?.postalOffice === 1,
      amenityServicesPlayground: harbor?.services?.amenities?.playground === 1,
      amenityServicesHealthServices: harbor?.services?.amenities?.healthServices === 1,
      amenityServicesVet: harbor?.services?.amenities?.vet === 1,      
    },
    enableReinitialize: true,
    onChange: () => {
      console.log('change')
    },
    onSubmit: values => {    
    }
  });
  
  const updateService = (category, name, value) => {

    const body = {
      serviceCategory: category,
      serviceName: name,
      value,
    }

    verttiApi.patch(`/manager/harbor/${harbor.publicId}/service`, body)
      .then((res) => {
        enqueueSnackbar('Palvelun saatavuus tallennettu', { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar('Palvelun saatavuuden tallennus epäonnistui', { variant: 'error' });
      })
    // verttiApi.patch(`harbor/${harbor.publicId}`, body)
    //   .then((res) => {
    //     fetchHarbors({ fetchAll: true });
    //     enqueueSnackbar('Sataman tiedot tallennettu.', { variant: 'success' });
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.error('Error updating harbor', err);
    //     enqueueSnackbar('Tietojen päivitys epäonnistui.', { variant: 'error' });
    //     setLoading(false);
    //   }) 

  }

  return (
    <React.Fragment>
    <form onSubmit={harborFormik.handleSubmit}>        
      <Box mt={3}>
        <Typography variant="subtitle2" display="block">
          Sataman palvelut
        </Typography>
      </Box>

      <Grid container spacing={1} justifyContent="flex-start">
        <ManagerServiceItemList updateService={updateService} itemConfig={harborServices} intl={props.intl} formik={harborFormik}/>
      </Grid>

      <Box mt={3}>
        <Typography variant="subtitle2" display="block" >
          Veneen ylläpitopalvelut
        </Typography>

      <Grid container spacing={1} justifyContent="flex-start">
        <ManagerServiceItemList updateService={updateService} itemConfig={boatUpkeepServices} intl={props.intl} formik={harborFormik}/>
      </Grid>

      </Box>

      <Box mt={3}>
        <Typography variant="subtitle2" display="block">
          Miehistön palvelut
        </Typography>

        <Grid container spacing={1} justifyContent="flex-start">
          <ManagerServiceItemList updateService={updateService} itemConfig={amenityServices} intl={props.intl} formik={harborFormik}/>
        </Grid>
      </Box>
    </form>
    </React.Fragment>
  );
}

export default withSnackbar(HarborServices);