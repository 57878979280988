import verttiApi from '../../../api/verttiApi';

const autoRoute = async ({ sourceLat, sourceLng, destinationLat, destinationLng, vesselDraft }) => {
  sourceLat = parseFloat(sourceLat);
  destinationLat = parseFloat(destinationLat);
  sourceLng = parseFloat(sourceLng);
  destinationLng = parseFloat(destinationLng);

  if (
    (isNaN(sourceLat) || sourceLat > 90 || sourceLat < -90) ||
    (isNaN(destinationLat) || destinationLat > 90 || destinationLat < -90) ||
    (isNaN(sourceLng) || sourceLng > 180 || sourceLng < -180) ||
    (isNaN(destinationLng) || destinationLng > 180 || destinationLng < -180)
  ) {
    console.error('illegal parameters', sourceLat, destinationLat, sourceLat, sourceLng);
    return false;
  }

  const result = await verttiApi.post('findroute', { sourceLat, sourceLng, destinationLat, destinationLng, vesselDraft });

  var distance = 0;

  for (var i of result.data.features) {
    distance += Number(i.properties.distance);
  }

  return { geoJson: result.data.features, distance };
}

export default autoRoute;