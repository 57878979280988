import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import verttiApi from '../api/verttiApi';

export const loadAisTargets = createAsyncThunk('loadAisTargets', async ({lngMin, latMin, lngMax, latMax}, thunkAPI) => {
  try {
    var result = await verttiApi.get(`ais/${lngMin}/${latMin}/${lngMax}/${latMax}`); // it's already in geojson
    return result.data;

  } catch (err) {
    console.error('Load ais targets failed', err);
    throw new Error(err);
  }
});

export const aisTargetsSlice = createSlice({
  name: 'aisTargets',
  initialState: { 
    value: {
      aisTargets: [],
      status: 'idle',
    }
  },
  reducers: {
    setAisTargets: (state, action) => {
      state.aisTargets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAisTargets.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(loadAisTargets.rejected, (state, action) => {
      state.status = 'idle';    
    });
    builder.addCase(loadAisTargets.fulfilled, (state, action) => {
      state.aisTargets = action.payload;
      state.status = 'idle';
    });
  }
});

// export const { setAisTargets } = aisTargetSlice.actions;
export default aisTargetsSlice.reducer;